import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import {Button} from 'react-bootstrap';

import './CreateButton.scss';

class CreateButton extends Component {
    // When the Create button is clicked this function sends it to the create URL/Page
    nextPath = () => {
        this.props.history.push(this.props.createPath);
    }

    render() { 
        return ( 
            <Button variant="primary" className="create-btn text-capitalize font-weight-bold" onClick={this.nextPath}>Create {this.props.buttonText} <i className="fas fa-plus-circle ml-1"></i></Button>
         );
    }
}
 
export default withRouter(CreateButton);