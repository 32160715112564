import React, {Component} from 'react';

import {Dropdown, Button} from 'react-bootstrap';
import moment from 'moment';

import DatePicker from '../DateRangePicker/DateRangePicker';

import '../Dropdown/FilterStyleDropdown.scss';
import './Filters.scss';

class Filters extends Component {
    state = { 
        originalFilters: [],
        selectedFilters: []
    }

    // Sets what value was chosen in the filter dropdown and then goes through each Editable Card and filters out/hides which Editable Cards do not meet the filtered criteria
    selectedItem = (eventKey, event, event2, category, custFilter) => {
        let selectedText;
        let selectedFilters = this.state.selectedFilters;
        let newObj = {};

        if (event2 === undefined) {
            if (event.target.getAttribute("matchingclass") === "brand" && event.target.textContent.indexOf(':') !== -1) {
                let splitText = event.target.textContent.split(':');

                selectedText = splitText[0];
            } else {
                selectedText = event.target.textContent;
            }

            event.target.parentElement.parentElement.getElementsByClassName('selected-text')[0].textContent = selectedText;
        } else {
            if (event2 !== null) {
                selectedText = `${event}-${event2}`;
            } else {
                selectedText = event;
            }

            document.getElementsByClassName(`${category.replace(" ", "-")}-toggle`)[0].getElementsByClassName('selected-text')[0].textContent = selectedText;
        }

        // Pushes which items are filetered into the state variable to let this function know which filters are selected
        if (Object.keys(this.state.selectedFilters).length > 0) {
            for (let i = 0; i < selectedFilters.length; i++) {
                if (event2 !== undefined) {
                    if (selectedFilters[i].category === category.replace("-", " ")) {
                        if (custFilter === "date picker") {
                            if (event2 !== null) {
                                selectedFilters[i].selected = `${event}-${event2}`;
                            } else {
                                selectedFilters[i].selected = event
                            }
                        }
                    } else if (selectedFilters.length - i === 1 && selectedFilters.find(e => category.replace("-", " ") === e.category) === undefined) {
                        if (custFilter === "date picker") {
                            newObj.category = category.replace("-", " ");
                            if (event2 !== null) {
                                newObj.selected = `${event}-${event2}`;
                            } else {
                                newObj.selected = event
                            }
                            newObj.matchingClass = category.replace(" ", "-");
                            newObj.dateVal = "true"
                        }

                        selectedFilters.push(newObj)
                    }
                } else {
                    if (selectedFilters[i].category === event.target.getAttribute("category")) {
                        selectedFilters[i].selected = event.target.textContent
                    } else if (selectedFilters.length - i === 1 && selectedFilters.find(e => event.target.getAttribute("category") === e.category) === undefined) {
                        newObj.category = event.target.getAttribute("category");
                        newObj.selected = event.target.textContent;
                        newObj.matchingClass = event.target.getAttribute("matchingclass");
    
                        selectedFilters.push(newObj)
                    }
                }
                
            }
        } else {
            if (event2 !== undefined) {
                if (custFilter === "date picker") {
                    newObj.category = category.replace("-", " ");
                    if (event2 !== null) {
                        newObj.selected = `${event}-${event2}`;
                    } else {
                        newObj.selected = event
                    }
                    newObj.matchingClass = category.replace(" ", "-");
                    newObj.dateVal = "true"
                }
            } else {
                newObj.category = event.target.getAttribute("category");
                newObj.selected = event.target.textContent;
                newObj.matchingClass = event.target.getAttribute("matchingclass");
            }

            selectedFilters.push(newObj)
        }

        this.setState((prevState) => {
            return {
                ...prevState,
                selectedFilters: selectedFilters
            }
        })

        // Goes through each Editable Card and checks the filter options to see if the card matches the given criteria. If it does not, set the "meetsCriteria" variable to false which will add the "d-none" class to the card to hide it
        Array.from(document.querySelectorAll('.editable-card')).find(el => {
            let meetsCriteria;

            for (let i = 0; i < selectedFilters.length; i++) {
                if (meetsCriteria !== false && selectedFilters[i].selected !== "All") {
                    if (el.getElementsByClassName(selectedFilters[i].matchingClass)[0].classList.contains('list-group-item')) {
                        if (selectedFilters[i].dateVal === "true") {
                            let matchingText = el.getElementsByClassName(selectedFilters[i].matchingClass)[0].getElementsByClassName('item-name')[0].textContent;
                            let splitMatchingText = matchingText.split(" ");
                            let onlyDateText = `${splitMatchingText[0]} ${splitMatchingText[1]} ${splitMatchingText[2]}`;
                            
                            if (event2 !== undefined) {
                                if (moment(onlyDateText).isBetween(event, event2) === false && moment(onlyDateText).isSame(event) === false && moment(onlyDateText).isSame(event2) === false) {
                                    meetsCriteria = false;
                                }
                            } else {
                                let selectedDate = selectedFilters[i].selected.split('-');

                                if (moment(onlyDateText).isBetween(selectedDate[0], selectedDate[1]) === false && moment(onlyDateText).isSame(selectedDate[0]) === false && moment(onlyDateText).isSame(selectedDate[1]) === false) {
                                    meetsCriteria = false;
                                }
                            }
                            
                        } else {
                            if (el.getElementsByClassName(selectedFilters[i].matchingClass)[0].getElementsByClassName('item-name')[0].textContent.toLowerCase() !== selectedFilters[i].selected.toLowerCase()) {
                                meetsCriteria = false;
                            }
                        }
                    } else if (el.getElementsByClassName(selectedFilters[i].matchingClass)[0].classList.contains('active-status')) {
                        if (el.getElementsByClassName(selectedFilters[i].matchingClass)[0].getElementsByClassName('active-text')[0].textContent !== selectedFilters[i].selected) {
                            meetsCriteria = false;
                        }
                    }
                }
            }

            if (meetsCriteria === false) {
                return el.classList.add('d-none')
            } else {
                return el.classList.remove('d-none')
            }
        })
    }

    // This function resets the filter state variabel and filter UI to their original states
    resetFilters = () => {
        const filterOptions = document.querySelectorAll('.filters .dropdown-toggle');

        // Sets the filter dropdown value to its original state
        for(let i = 0; i < filterOptions.length; i++) {
            for (let ii = 0; ii < this.state.originalFilters.length; ii++) {
                if (i === ii) {
                    filterOptions[i].getElementsByClassName('selected-text')[0].textContent = this.state.originalFilters[i]
                }
            }
        }

        // Removes the "d-none" class from all Editable Cards that contain it
        Array.from(document.querySelectorAll('.editable-card')).find(el => {
            if (el.classList.contains('d-none')) {
                return el.classList.remove('d-none')
            }
        })

        this.setState((prevState) => {
            return {
                ...prevState,
                categoriesFiltered: [],
                selectedFilters: []
            }
        })        
    }

    // Defines how many filter dropdowns there are and what options go in each filter
    filters = this.props.filters.map((key, index) => {
        let filterState = this.state.originalFilters;

        // Pushes/sets the orginalFilters state variable to include the original dropdown text that is used when the user resets the filters
        if (filterState.indexOf(key.type) === -1) {
            filterState.push(key.type);
            this.setState((prevState) => {
                return {
                    ...prevState,
                    originalFilters: filterState
                }
            })
        }

        return (
            <Dropdown key={key.type} className="mr-3 filter-style-dropdown">
                <Dropdown.Toggle className={`bg-white border text-reset text-uppercase position-relative font-weight-bold rounded ${key.type.replace(" ", "-")}-toggle`}>
                <span className="mr-4 selected-text">{key.type}</span> <i className="fas fa-chevron-down position-absolute text-reset"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu className={`border-0 ${key.datePicker === "true" ? "date-picker-dropdown" : ""}`}>
                    {Object.keys(key.options).map((keyTwo, indexTwo) => {
                        if (key.options[keyTwo] === "date-picker") {
                            return (
                                <DatePicker dateCategory={key.type} selectHandler={this.selectedItem} />
                            )
                        } else {
                            return (
                                <Dropdown.Item key={keyTwo} onSelect={this.selectedItem} matchingclass={key.class} category={key.type}>{key.options[keyTwo]}</Dropdown.Item>
                            )
                        }
                    })}
                </Dropdown.Menu>
            </Dropdown>
        )
    })

    render() { 
        return ( 
            <div className="d-flex flex-row filters">
                {this.props.filterName !== undefined ? <p className="text-uppercase text-muted font-weight-bold mr-3 filter-by-text position-relative">Filter {this.props.filterName} By</p> : ''}
                {this.filters}
                <Button variant="primary" className="font-weight-bold reset-filters" onClick={this.resetFilters}>Reset Filters</Button>
            </div>
        );
    }
}
 
export default Filters;