import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import DisclosureReducer from './Store/Reducers/DisclosureReducer';
import ContentManagerReducer from './Store/Reducers/ContentManagerReducer';
import LoginReducer from './Store/Reducers/LoginReducer';
import BillingReducer from './Store/Reducers/BillingReducer';
import AdIDManagerReducer from './Store/Reducers/AdIDManagerReducer';

// Redux Store
const rootReducer = combineReducers({
    disclosure: DisclosureReducer,
    contentManager: ContentManagerReducer,
    login: LoginReducer,
    billing: BillingReducer,
    adidManager: AdIDManagerReducer
});

// Store
const store = createStore(
    rootReducer,
    compose(applyMiddleware(thunk))
);

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
