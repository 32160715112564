import React from 'react';

import {Button, ButtonGroup} from 'react-bootstrap';

import cancelImage from '../../../Images/img-cancel-white.svg';

import './FilterButtons.scss';

const FilterButtons = (props) => {
    // Function to update the Filters Array to update the active/inactive state of the filter selected and then pass in the updated filters to the filter handler
    const setActiveState = (selectedItem) => {
        let newSelected = props.selectedFilter;

        if (!props.selectedFilter.length || !newSelected.includes(selectedItem)) {
            newSelected = newSelected.concat(selectedItem)
        } else if (newSelected.includes(selectedItem)) {
            newSelected = newSelected.filter(item => item !== selectedItem)
        }
        
        props.filterButtonHandler(newSelected);
    }
    
    // Determine the buttons to show in the button group
    let buttons = props.btnOptions.map((key, index) => {
    return <Button key={`${key}.${index}`} size="sm" variant={props.selectedFilter.length && props.selectedFilter.includes(key.replace('hide ', '')) ? 'primary' : 'outline-primary'} className={`mr-2 rounded text-uppercase font-weight-bold px-3 py-2 flex-row align-items-center ${key.indexOf('hide') !== -1 ? 'd-none' : 'd-flex'}`} onClick={() => setActiveState(key)}>{key.indexOf('hide') !== -1 ? key.replace('hide ', '') : key}{props.selectedFilter.length && props.selectedFilter.includes(key.replace('hide ', '')) ? <img className="ml-2 align-middle" src={cancelImage} alt="cancel" /> : ''}</Button>
    })
    
    return ( 
        <div className="d-flex flex-row filter-buttons-wrapper">
            {props.filterText !== undefined ? <p className="text-uppercase font-weight-bold mr-3 filter-text position-relative text-small">{props.filterText}</p> : ''}
            <ButtonGroup className="filter-buttons">
                {buttons}
            </ButtonGroup>
        </div>
    );
}
 
export default FilterButtons;