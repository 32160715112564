import React from 'react';

import {Dropdown} from 'react-bootstrap';
import AdidManagerDropdownFilter from '../../../Containers/AdIDManager/Components/EditableDropdownFilter';

import './EditableDropdown.scss';

const EditableDropdown = (props) => {
    // This function handles showing what the user selected in the dropdown and then sending the updating JSON to the update handler for the Editable Card
    const selectHandler = (eventKey, event) => {
        let newID, newName;
        let curID = event.target.closest('.editable-dropdown').getElementsByClassName('selected-id')[0];
        let curName = event.target.closest('.editable-dropdown').getElementsByClassName('selected-text')[0];

        // Remove error border if it exists when user selects an item from the dropdown
        if (event.target.closest('.editable-dropdown').getElementsByClassName('dropdown-toggle')[0].classList.contains('error-border')) {
            event.target.closest('.editable-dropdown').getElementsByClassName('dropdown-toggle')[0].classList.remove('error-border')
        }

        // If the item the user clicked on containst he class "dropdown-item" then don't traverse up the DOM. Otherwise, traverse up the DOM to get the correct text content
        if (event.target.classList.contains('dropdown-item')) {
            newID = event.target.getElementsByClassName('option-id')[0].textContent;
            newName = event.target.getElementsByClassName('option-text')[0].textContent;
        } else {
            newID = event.target.closest('.dropdown-item').getElementsByClassName('option-id')[0].textContent;
            newName = event.target.closest('.dropdown-item').getElementsByClassName('option-text')[0].textContent;
        }

        // Sets the current ID and current Name to the new values the user selected in the dropdown
        curID.innerHTML = newID;
        curName.innerHTML = newName;

        if (props.customHandler === undefined) {
            Object.keys(props.bodyJson).map((key, index) => {
                let newObjKey = props.objKey.replace(/\s/g, '');
                if (key.toLowerCase().indexOf(newObjKey) !== -1) {
                    if (key.toLowerCase().indexOf('id') !== -1) {
                        props.bodyJson[key] = newID
                    } else if (key.toLowerCase().indexOf('name') !== -1) {
                        props.bodyJson[key] = newName
                    } else {
                        props.bodyJson[key] = newName
                    }
                    return props.bodyJson[key];
                } else if (newObjKey === "binstatus" && key === "active") {
                    return props.bodyJson[key] = (newID === "true");
                } else {
                    return false;
                }
            });
    
            // Set the new ID and Name the user selected for the setJson property
            Object.keys(props.setJson).map((keyTwo, indexTwo) => {
                if (keyTwo === props.objKey) {
                    for (let i = 0; i < props.setJson[keyTwo].length; i++) {
                        if (props.setJson[keyTwo][i].id !== undefined) {
                            props.setJson[keyTwo][i].id = newID
                        } else if (props.setJson[keyTwo][i].name !== undefined) {
                            props.setJson[keyTwo][i].name = newName
                        }
                    }
                    return props.setJson;
                } else {
                    return false;
                }
            });
            
            props.bodyJson.cascadeType = props.setJson.cascadeType[0].id;
            // Send the updated code to the updateHandler in the Editable Card component
            props.updateHandler("body", props.objKey, props.bodyJson, props.setJson);
        } else {
            props.customHandler(props.objKey, newName)
        }
    }

    const itemCategory = props.noSelectText === undefined && props.noSelectText !== true ? props.objKey.split(/(?=[A-Z])/) : props.objKey.split(/(?=[A-Z])/).join(" ");

    // Outputs each dropdown item based on the dropdownOptions property and provides the necessary values in the dropdown
    let dropdownOptions = props.dropdownOptions.map((key, index) => {
        if (key.type === props.objKey) {
            if (Array.isArray(key)) {
                return key.map((keyTwo, indexTwo) => {
                    if (!Array.isArray(keyTwo)) {
                        if (props.selectedFilter !== undefined && props.selectedFilter.length && props.page.indexOf("adid manager") !== -1) {
                            return <AdidManagerDropdownFilter key={`${keyTwo}-${indexTwo}`} normalKey={key[indexTwo]} keyTwo={keyTwo} indexTwo={indexTwo} selectedFilter={props.selectedFilter} selectHandler={selectHandler} />
                        } else {
                            return <Dropdown.Item key={`${keyTwo}-${indexTwo}`} onSelect={selectHandler}><span className="option-text">{keyTwo}</span><span className="d-none option-id">{keyTwo}</span></Dropdown.Item>
                        }
                    }
                })
            } else {
                return Object.keys(key).map((keyTwo, indexTwo) => {
                    if (keyTwo !== "type") {
                        return <Dropdown.Item key={`${keyTwo}-${indexTwo}`} onSelect={selectHandler}><span className="option-text">{key[keyTwo]}</span><span className="d-none option-id">{keyTwo}</span></Dropdown.Item>
                    } else {
                        return false;
                    }
                })
            }
            
        } else {
            return false;
        }
    })
    
    return ( 
        <Dropdown className={`editable-dropdown ${props.index > 0 ? "mt-3" : ""} ${props.marginBottom ? "mb-3" : ""}`}>
            <Dropdown.Toggle variant="white" className="w-100 text-left position-relative font-weight-light border d-flex" required={props.required} disabled={props.disableEditing !== undefined && props.disableEditing ? props.disableEditing : ""}>
                <span className="font-weight-bold text-small position-absolute text-uppercase select-label">{props.noSelectText === undefined && props.noSelectText !== true ? `Select ${itemCategory[0]}` : itemCategory}</span><i className="fas fa-chevron-down position-absolute text-reset"></i><span className="selected-text text-truncate d-inline-block pr-3">{props.default}</span><span className="d-none selected-id">{props.objValue[0]?.id}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100 pt-0 border">
                {dropdownOptions}
            </Dropdown.Menu>
        </Dropdown>
     );
}
 
export default EditableDropdown;