import React from 'react';
import {connect} from 'react-redux';

import * as actionTypes from '../../Store/Actions/Login';

import {Row, Col, Card, Button, Spinner} from 'react-bootstrap';
import {GoogleLogin} from 'react-google-login';

import './Login.scss';

const Login = (props) => {
    // Makes sure the response from Google is not an error and has an access Token. If these are true then execute code to log the user in and define their first and last name
    const responseGoogle = (response) => {
        if (response.error === undefined && (response.accessToken !== undefined || response.accessToken !== null)) {
            props.onSetUserInfo(response.profileObj.givenName, response.profileObj.familyName)
            refreshTokenSetUp(response)
        }
    }

    // This function refreshes the accessToken provided in the Google API response
    const refreshTokenSetUp = (response) => {
        let refreshTiming = (response.tokenObj.expires_in || 3600 - 5 * 60) * 100;

        const refreshToken = async() => {
            const newAuthRes = await response.reloadAuthResponse();
            refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 100;

            setTimeout(refreshToken, refreshTiming)
        }

        setTimeout(refreshToken, refreshTiming)
    }

    let login;

    if (props.loggedIn === false) {
        // If the user is not logged in, show the Google Login UI
        login = (
            <Col className="text-center pt-5 mt-4">
                <Spinner size="lg" animation="border" variant="primary" className="m-auto" />
                <Card className="border-0 d-none">
                    <Card.Header className="text-center bg-white border-0 mb-3">
                        <h5 className="font-weight-light mb-1">Welcome to</h5>
                        <h3 className="font-weight-bold">Mission Control</h3>
                    </Card.Header>
                    <Card.Body className="text-center">
                        <GoogleLogin
                            clientId="491609097370-nrf6gvgga6kpnfcdcdvp9en5h2q38mrp.apps.googleusercontent.com"
                            buttonText="Login"
                            render={renderProps => (
                                <Button onClick={renderProps.onClick} disabled={false} variant="primary" className="text-capitalize font-weight-bold position-relative" block><i className="fab fa-google position-absolute"></i> <span className="font-weight-normal">Sign In With Google</span></Button>
                            )}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={true}
                        />
                    </Card.Body>
                </Card>
            </Col>
        )
    } else if(props.loggedIn === 'show button') {
        // If the user logs out, show the Google Login UI
        login = (
            <Col xs={6}>
                <Card className="border-0">
                    <Card.Header className="text-center bg-white border-0 mb-3">
                        <h5 className="font-weight-light mb-1">Welcome to</h5>
                        <h3 className="font-weight-bold">Mission Control</h3>
                    </Card.Header>
                    <Card.Body className="text-center">
                        <GoogleLogin
                            clientId="491609097370-nrf6gvgga6kpnfcdcdvp9en5h2q38mrp.apps.googleusercontent.com"
                            buttonText="Login"
                            render={renderProps => (
                                <Button onClick={renderProps.onClick} disabled={false} variant="primary" className="text-capitalize font-weight-bold position-relative" block><i className="fab fa-google position-absolute"></i> <span className="font-weight-normal">Sign In With Google</span></Button>
                            )}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            isSignedIn={true}
                        />
                    </Card.Body>
                </Card>
            </Col>
        )
    }

    return ( 
        <Row className="justify-content-md-center login-component">
            {login}
        </Row>
     );
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.login.loggedIn
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetUserInfo: (firstName, lastName) => dispatch(actionTypes.setUserInfo(firstName, lastName)),
        onUserNeedsToLogin: () => dispatch(actionTypes.userNeedsToLogin())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Login);