import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as actionTypes from '../../Store/Actions/Disclosure';

import {Container, Row, Col, Spinner, Alert} from 'react-bootstrap';
import EditableCard from '../../Components/Cards/EditableCard/EditableCard';
import CreateButton from '../../Components/Buttons/CreateButton/CreateButton';

import './Disclosures.scss';

class Disclosures extends Component {
    constructor(props) {
        super(props);

        this.state = {
            getAllJson: null,
            fullButtonSet: null,
            fullTermSet: null
        };
    }

    componentDidMount() {
        // Trigger the get function that makes the API call to get the json needed for this page
        this.props.onInitGetDisclosureJson(this.props.endpoint);

        if (this.props.page === "disclosure sets") {
            this.props.onInitGetSetJson(this.props.endpoint)
        }
    }

    componentDidUpdate(prevProps) {
        // If the disclosures property has updated set the "getAllJson" state variable to the updated disclosures property variable
        if (this.props.disclosures !== prevProps.disclosures) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    getAllJson: this.props.disclosures
                }
            });
        }

        // If the buttonSet ortermSet properties have updated set the "fullButtonSet" and "fullTermSet" state variables to the updated buttonSet and termSet property variables
        if (this.props.buttonSet !== prevProps.buttonSet || this.props.termSet !== prevProps.termSet) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    fullButtonSet: this.props.buttonSet,
                    fullTermSet: this.props.termSet
                }
            });
        }
    }

    render() { 
        let disclosureContent;

        if (this.state.getAllJson === null && !this.props.error || (this.props.page === "disclosure sets" && (this.state.fullButtonSet === null || this.state.fullTermSet === null))) {
            // If the getAllJson state variable is null, there are no errors, and the fullButtonSet and fullTermSet state variables aren't null if the page is "disclosure sets", show a spinner
            disclosureContent = (
                <Row className="mt-5 pt-5">
                    <Col className="text-center">
                        <Spinner size="lg" animation="border" variant="primary" className="m-auto" />
                    </Col>
                </Row>
            );
        } else if(this.props.error) {
            // If there are errors, then show the error alert message
            disclosureContent = (
                <Row className="mt-5 pt-5 justify-content-center">
                    <Col>
                        <Alert variant="danger" className="text-center">
                            There was an issue getting the content for this page! Please refresh the page to try again!
                        </Alert>
                    </Col>
                </Row>
            )
        } else {
            // If the "getAllJson" state variable has content and is NOT null
            let mainJson = this.state.getAllJson;

            let content = mainJson.map((key,index) => {
                let updatedJson = {
                    headerJson: {},
                    realHeaderJson: {},
                    bodyJson: {},
                    setJson: {}
                }
                let dropdownOptions;

                if (this.props.page === "disclosure sets") {
                    // If the page is "disclosure sets" execute this specific code that is used for the item set component
                    updatedJson.setJson["button"] = [];
                    updatedJson.setJson["term"] = [];
                    dropdownOptions = [{}, {}];

                    dropdownOptions[0] = this.state.fullButtonSet;
                    dropdownOptions[0]["type"] = "button";
                    dropdownOptions[1] = this.state.fullTermSet;
                    dropdownOptions[1]["type"] = "term";

                    for (const item in key) {
                        if (item.indexOf('id') !== -1) {
                            updatedJson.realHeaderJson[item] = key[item]
                            updatedJson.headerJson.id = key[item]
                        } else if (item.toLowerCase().indexOf('disclosuresetname') !== -1) {
                            updatedJson.realHeaderJson[item] = key[item]
                            updatedJson.headerJson.name = key[item]
                        } else if (item.toLowerCase().indexOf('button') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            if (item.toLowerCase().indexOf('id') !== -1) {
                                updatedJson.setJson["button"].push({id: key[item],realButtonId: item})
                            } else if (item.toLowerCase().indexOf('name') !== -1) {
                                updatedJson.setJson["button"].push({name: key[item],realButtonName: item})
                            } 
                        } else if (item.toLowerCase().indexOf('term') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            if (item.toLowerCase().indexOf('id') !== -1) {
                                updatedJson.setJson["term"].push({id: key[item],realTermId: item})
                            } else if (item.toLowerCase().indexOf('name') !== -1) {
                                updatedJson.setJson["term"].push({name: key[item],realTermName: item})
                            }
                        }
                    }
                } else {
                    for (const item in key) {
                        if (item.toLowerCase().indexOf('id') !== -1) {
                            updatedJson.realHeaderJson[item] = key[item]
                            updatedJson.headerJson.id = key[item]
                        } else if (item.toLowerCase().indexOf('name') !== -1) {
                            updatedJson.realHeaderJson[item] = key[item]
                            updatedJson.headerJson.name = key[item]
                        } else {
                            updatedJson.bodyJson[item] = key[item]
                        }
                    }
                }

                return (
                    <Col xs={this.props.page === 'disclosure sets' ? '6' : ''} key={index}>
                        <EditableCard dropdownOptions={dropdownOptions} realHeaderJson={updatedJson.realHeaderJson} {...this.props} headerJson={updatedJson.headerJson} bodyJson={updatedJson.bodyJson} setJson={updatedJson.setJson} cardLabel={this.props.page} category="disclosure" />
                    </Col>
                )
            })
            
            // Sorts the Editable Cards by the headerJson key "name" value 
            content.sort((a, b) => {
                let textA = a.props.children.props.headerJson.name.toLowerCase();
                let textB = b.props.children.props.headerJson.name.toLowerCase();

                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })

            disclosureContent = (
                <div>
                    <Row className="mb-4 pb-3">
                        <Col>
                            <CreateButton {...this.props} content={this.state.getAllJson} buttonText={this.props.btnText} />
                        </Col>
                    </Row>
                    <Row>
                        {this.props.page === "disclosure sets" ? content : <div className="card-columns w-100">
                            {content}
                        </div>}
                    </Row>
                </div>
            )
        }
        
        return ( 
            <Container fluid className="disclosure-content py-4 my-3">
                {disclosureContent}
            </Container>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        disclosures: state.disclosure.disclosures,
        buttonSet: state.disclosure.buttonSet,
        termSet: state.disclosure.termSet,
        error: state.disclosure.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitGetDisclosureJson: (query) => dispatch(actionTypes.initGetDisclosureJson(query)),
        onInitGetSetJson: (query) => dispatch(actionTypes.initGetSetJson(query))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Disclosures);