import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as actionTypes from '../../Store/Actions/Disclosure';

import {Container, Row, Col} from 'react-bootstrap';
import EditableCard from '../../Components/Cards/EditableCard/EditableCard';

class DisclosuresCreate extends Component {
    constructor(props) {
        super(props);
        let disclosureJson;
        if (this.props.page === "disclosure terms") {
            disclosureJson = this.props.termsCreate
        } else if (this.props.page === "buttons") {
            disclosureJson = this.props.buttonsCreate
        } else if (this.props.page === "disclosure sets") {
            disclosureJson = this.props.setsCreate
        }
        this.state = {
            getCreateJson: disclosureJson,
            getAllJson: this.props.disclosures,
            fullButtonSet: this.props.buttonSet,
            fullTermSet: this.props.termSet
        };
    }

    componentDidMount() {
        // Do this check to help with validation for the name if the array of objects for the disclosures store variable is null
        if (this.props.disclosures === null) {
            this.props.onInitGetDisclosureJson(this.props.endpoint)
        }
        // If this page is "disclosure sets" and the buttonSet or termSet variables are null, then make the API call to get the json for these property variables
        if (this.props.page === "disclosure sets" && (this.props.buttonSet === null || this.props.termSet === null)) {
            this.props.onInitGetSetJson(this.props.endpoint)
        }
    }
    
    render() { 
        let updatedJson = {
            headerJson: {},
            realHeaderJson: {},
            bodyJson: {},
            setJson: {},
            requiredFields: []
        }
        
        let dropdownOptions;

        if (this.props.page === "disclosure sets") {
            // If the page is "disclosure sets" execute this specific code that is used for dropdown fields
            updatedJson.setJson["button"] = [];
            updatedJson.setJson["term"] = [];
            dropdownOptions = [{"type": ""}, {}];

            dropdownOptions[0] = this.props.buttonSet;
            dropdownOptions[0]["type"] = "button";
            dropdownOptions[1] = this.props.termSet;
            dropdownOptions[1]["type"] = "term";

            for (const item in this.state.getCreateJson) {
                if (item.toLowerCase().indexOf('disclosuresetid') !== -1) {
                    updatedJson.realHeaderJson[item] = this.state.getCreateJson[item]
                    updatedJson.headerJson.id = this.state.getCreateJson[item]
                } else if (item.toLowerCase().indexOf('disclosuresetname') !== -1) {
                    updatedJson.realHeaderJson[item] = this.state.getCreateJson[item]
                    updatedJson.headerJson.name = this.state.getCreateJson[item]
                    updatedJson.headerJson.required = true;
                } else if (item.toLowerCase().indexOf('button') !== -1) {
                    updatedJson.bodyJson[item] = this.state.getCreateJson[item]
                    if (item.toLowerCase().indexOf('id') !== -1) {
                        updatedJson.setJson["button"].push({id: this.state.getCreateJson[item],realButtonId: item})
                    } else if (item.toLowerCase().indexOf('name') !== -1) {
                        updatedJson.setJson["button"].push({name: this.state.getCreateJson[item],realButtonName: item})
                        updatedJson.setJson["button"].push({required: true})
                    } 
                } else if (item.toLowerCase().indexOf('term') !== -1) {
                    updatedJson.bodyJson[item] = this.state.getCreateJson[item]
                    if (item.toLowerCase().indexOf('id') !== -1) {
                        updatedJson.setJson["term"].push({id: this.state.getCreateJson[item],realTermId: item})
                    } else if (item.toLowerCase().indexOf('name') !== -1) {
                        updatedJson.setJson["term"].push({name: this.state.getCreateJson[item],realTermName: item})
                        updatedJson.setJson["term"].push({required: true})
                    }
                }
            }
        } else {
            for (const item in this.state.getCreateJson) {
                if (item.toLowerCase().indexOf('id') !== -1) {
                    updatedJson.realHeaderJson[item] = this.state.getCreateJson[item]
                    updatedJson.headerJson.id = this.state.getCreateJson[item]
                } else if (item.toLowerCase().indexOf('name') !== -1) {
                    updatedJson.realHeaderJson[item] = this.state.getCreateJson[item]
                    updatedJson.headerJson.name = this.state.getCreateJson[item]
                    updatedJson.headerJson.required = true;
                } 
                // If the item is an object then it has a value and required key/value pair. This uses those values to set the value in the bodyJson and let the field component that uses this value that this field is required to be filled out
                else if (typeof this.state.getCreateJson[item] === "object") {
                    updatedJson.bodyJson[item] = this.state.getCreateJson[item].value
                    updatedJson.requiredFields.push(item)
                } else {
                    updatedJson.bodyJson[item] = this.state.getCreateJson[item]
                }
            }
        }
        
        let disclosureContent = (
            <Col md={6}>
                <EditableCard dropdownOptions={dropdownOptions} create={true} realHeaderJson={updatedJson.realHeaderJson} {...this.props} headerJson={updatedJson.headerJson} bodyJson={updatedJson.bodyJson} setJson={updatedJson.setJson} cardLabel={this.props.page} category="disclosure" requiredFields={updatedJson.requiredFields} />
            </Col>
        )

        return (
            <Container fluid className="disclosure-content py-4 my-3">
                <Row>
                    {disclosureContent}
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        disclosures: state.disclosure.disclosures,
        termsCreate: state.disclosure.disclosureCreateTerms,
        setsCreate: state.disclosure.disclosureCreateSets,
        buttonsCreate: state.disclosure.disclosureCreateButtons,
        buttonSet: state.disclosure.buttonSet,
        termSet: state.disclosure.termSet,
        errorCreate: state.disclosure.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitGetDisclosureJson: (query) => dispatch(actionTypes.initGetDisclosureJson(query)),
        onInitGetSetJson: (query) => dispatch(actionTypes.initGetSetJson(query))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(DisclosuresCreate);