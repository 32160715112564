import React from 'react';

import {Card} from 'react-bootstrap';
import EditableInput from '../../EditableInput/EditableInput';
import CardConnector from '../../../Cards/CardConnector/CardConnector';

const AppendEditableInput = (props) => {
    let type;

    // Checks whether a mobile key/value pair exists in the json or not. If it does exist, then set it to an Editable Input
    let mobileBtnText = Object.keys(props.bodyJson).map((key, index) => {
        let label = key.split(/(?=[A-Z])/).join(" ");
        let keySplit = key.split(/(?=[A-Z])/);
        type = keySplit[0];

        if (key.toLowerCase().indexOf('mobile') !== -1 || key === "") {
            return (
                <EditableInput jsonKey={key} key={`${key}-${index}`} body={true} {...props} label={label} name={props.bodyJson[key]} readOnly={!props.editMode} />        
            )
        } else {
            return false;
        }
    });

    let appendEditableInput = props.mobileBtn ? 
        <Card className={`position-relative mobile-input mt-4 ${props.editMode ? 'mobile-input-edit border-0' : 'multiple-editable-inputs border'}`}>
            <Card.Body className={`${props.editMode ? 'p-0 border-0' : ''}`}>
                <div>
                    {mobileBtnText}
                </div>
                <p className="text-muted position-absolute text-uppercase mobile-label font-weight-bold">Mobile {type} Text</p>
            </Card.Body>
            <CardConnector />
        </Card> : '';

    return ( 
        <>
            {appendEditableInput}
        </>
    );
}
 
export default AppendEditableInput;