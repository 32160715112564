import * as actionTypes from './ActionTypes';
import axios from 'axios';

// Sets the content manager json with the returned json from the API call
export const getContentManagerJson = (json) => {
    return {
        type: actionTypes.GET_CONTENT_MANAGER_JSON,
        contentManagerJson: json
    }
}

// Sets the error state to true if there was an error response from the API call
export const getContentManagerJsonFailed = () => {
    return {
        type: actionTypes.GET_CONTENT_MANAGER_JSON_FAILED
    }
}

// API call to get content manager json
export const initGetContentManagerJson = (query) => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_SERVICE_URL}/${query}`)
        .then(response => {
            dispatch(getContentManagerJson(response.data))
        })
        .catch(error => {
            console.log(error);
            dispatch(getContentManagerJsonFailed());
        });
    }
}

// API call to update/create the specific content manager item
export const initUpdateCreateContentManagerJson = (query, json, method) => {
    return dispatch => {
        axios.post(`${process.env.REACT_APP_SERVICE_URL}/${query}`, json)
        .then(response => {
            if (method === "create") {
                dispatch(savingNewContentManager())
            }
        })
        .catch(error => {
            console.log(error);
            dispatch(getContentManagerJsonFailed());
        });
    }
}

// Sets the state for content manager to true
export const savingNewContentManager = () => {
    return {
        type: actionTypes.SAVING_NEW_CONTENT_MANAGER
    }
}