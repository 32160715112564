import React from 'react';

import {Form} from 'react-bootstrap';

import './CustomTextarea.scss';

const CustomTextarea = (props) => {
    let updateHandler = (event) => {
        let newValue = event.target.value;

        props.customHandler(props.label, newValue)
    }
    return ( 
        <Form.Group className="custom-textarea position-realtive">
            <Form.Label className="font-weight-bold text-uppercase text-small position-absolute">{props.label.split(/(?=[A-Z])/).join(" ")}</Form.Label>
            <Form.Control as="textarea" rows={3} defaultValue={props.textareaValue} className="border" onChange={updateHandler} />
        </Form.Group>
    );
}
 
export default CustomTextarea;