import React from 'react';

import {Form} from 'react-bootstrap';

import './EditableInput.scss';

const EditableInput = (props) => {
    // Sends the changes the user made for the editable input to the Editable Card update handler
    const saveNameHandler = (event) => {
        // Remove error border if the user enters in text and the input has the error border class
        if (event.target.classList.contains('error-border') && event.target.value !== "") {
            event.target.classList.remove('error-border')
        }

        if (props.type === "number") {
            if ( event.target.value.length >  event.target.maxLength) {
                event.target.value = event.target.value.slice(0, event.target.maxLength)
            }
        }

        if (props.customHandler === undefined) {
            if (props.body) {
                props.bodyJson[props.jsonKey] = event.target.value;
                
                props.updateHandler("body", "", props.bodyJson);
            } else {
                // If the inputs body property is NOT set to true then use this condition to udpdate the json
                let jsonKey;
    
                for (const key in props.realHeaderJson) {
                    if (key.indexOf('Name') !== -1 || key.toLowerCase().indexOf('id') === -1) {
                        jsonKey = key;
                    }
                }
    
                props.headerJson.name = event.target.value;
    
                props.updateHandler("header", jsonKey, props.headerJson);
            }
        } else {
            props.customHandler(props.label, event.target.value)
        }
    }

    return ( 
        <Form className="editable-input" id={props.realHeaderKey}>
            <Form.Group controlId="card-name" className={`${props.readOnly ? 'read-only' : ''} ${props.label}`}>
                <Form.Label className="font-weight-bold text-uppercase text-small position-absolute">{props.label.split(/(?=[A-Z])/).join(" ")}</Form.Label>
                <Form.Control placeholder={props.placeholder} maxLength={props.maxLength} required={props.required} type={props.inputType !== undefined ? props.inputType : props.type} onChange={saveNameHandler} defaultValue={props.name} readOnly={props.readOnly} plaintext={props.readOnly} className="font-weight-light border editable-input" disabled={props.disableEditing !== undefined && props.disableEditing && !props.overrideDisable ? props.disableEditing : props.overrideDisable} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />
            </Form.Group>
        </Form>
    );
}
 
export default EditableInput;