import * as actionTypes from '../Actions/ActionTypes';

// Initial state for content manager
const initialState = {
    contentManagerJson: null,
    contentHeadingCreate: {
        "contentHeadingName": "",
        "contentHeading": {
            value: "",
            required: true
        }
    },
    saving: false,
    error: false
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        // Set the contentManagerJson based on the API response
        case actionTypes.GET_CONTENT_MANAGER_JSON:
        case actionTypes.CREATE_CONTENT_MANAGER_JSON:
            return {
                ...state,
                saving: false,
                contentManagerJson: action.contentManagerJson
            }
        // Set the error state to true if the content manager API call returns an error
        case actionTypes.GET_CONTENT_MANAGER_JSON_FAILED:
            return {
                ...state,
                saving: false,
                error: true
            }
        // Set the saving state to true
        case actionTypes.SAVING_NEW_CONTENT_MANAGER:
            return {
                ...state,
                saving: true
            }
        default: return state
    }
}

export default reducer;