import React from 'react';

import {Col} from 'react-bootstrap';
import CreateComponentButtons from '../../../CreateComponents/CreateComponentButtons/CreateComponentButtons';
import FilterButtons from '../../../Filters/FilterButtons/FilterButtons';
import BasicSearch from '../../../Search/BasicSearch/BasicSearch';

const MultiSectionCardHeader = (props) => {
    return ( 
        <Col xs={12} className={`${props.headerIndex === 0 ? 'main-header mt-3' : ''}`}>
            <h5 className="font-weight-bold mb-4 pb-1 text-truncate d-inline-block">{props.headerJson[`section${props.headerIndex + 1}`].title1}{props.headerJson[`section${props.headerIndex + 1}`].title2 !== undefined ? <><span className="mx-4">•</span><span className="font-weight-light">{props.headerJson[`section${props.headerIndex + 1}`].title2}</span></> : ''}</h5>
            <div className="d-flex flex-row align-items-center pb-2">
                {props.headerJson[`section${props.headerIndex + 1}`] !== undefined && props.headerJson[`section${props.headerIndex + 1}`].search !== undefined ? 
                    <BasicSearch {...props} placeholder={props.headerJson[`section${props.headerIndex + 1}`].searchPlaceholder} searchHandler={props.headerJson[`section${props.headerIndex + 1}`].searchHandler} />
                :
                    ''
                }
                {props.headerJson[`section${props.headerIndex + 1}`] !== undefined && props.headerJson[`section${props.headerIndex + 1}`].buttons !== undefined && props.headerJson[`section${props.headerIndex + 1}`].buttons === "save" ? 
                    <span className="cancel-save-buttons position-absolute">
                        <CreateComponentButtons error={props.errorState} saving={""} savingCreateHandler={props.saveHandler} {...props} editHandler={""} btnText={props.headerJson[`section${props.headerIndex + 1}`].buttons} notCreate={props.cardLabel.toLowerCase().indexOf('view') !== -1 ? true : false} disableEditing={props.disableEditing !== undefined && props.disableEditing ? props.disableEditing : ""} fieldErrorHandler={props.fieldErrorHandler} />
                    </span>
                : 
                props.headerJson[`section${props.headerIndex + 1}`] !== undefined && props.headerJson[`section${props.headerIndex + 1}`].buttons !== undefined && props.headerJson[`section${props.headerIndex + 1}`].buttons === "filter buttons" ?
                    <span className={`filter-group ${props.headerJson[`section${props.headerIndex + 1}`].search !== undefined ? '' : 'position-absolute'} flex-shrink-0`}>
                        <FilterButtons btnOptions={props.headerJson[`section${props.headerIndex + 1}`].filterOptions} selectedFilter={props.headerJson[`section${props.headerIndex + 1}`].selectedFilters} filterButtonHandler={props.headerJson[`section${props.headerIndex + 1}`].filterHandler} filterText={props.headerJson[`section${props.headerIndex + 1}`].filterText} />
                    </span>
                : ''}
            </div>
            <h5 className={`text-danger ${props.error ? "" : "d-none"} text-center font-weight-bold pt-4 mb-0`}>{props.errorMessage ? props.errorMessage : ""}</h5>
        </Col>
    );
}
 
export default MultiSectionCardHeader;