import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';

import './VariableDropdown.scss';

class VariableDropdown extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        editorState: PropTypes.object,
    }

    state = {
        open: false
    }

    // Sets whether the open state variable is true or not
    openVariablesDropdown = () => this.setState({open: !this.state.open})

    // Adds the variable to the text editor
    addVariable = (variable) => {
        const { editorState, onChange} = this.props;
        const selectionState = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        // Create new Immutable entity so the letters inserted for the word cannot be edited separately
        const contentStateWithEntity = contentState.createEntity("VARIABLE", "IMMUTABLE", new Date().getTime());
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const modifiedContent = Modifier.insertText(contentState, selectionState, variable, editorState.getCurrentInlineStyle(), entityKey);
        // Insert the variable into the text editor
        onChange(EditorState.push( editorState, modifiedContent, editorState.getLastChangeType() ));
    }

    variableOptions = [
        {key: "trialDuration", value: "[trialDuration]", text: "Trial Duration"},
        {key: "trialAmount", value: "[trialAmount]", text: "Trial Amount"},
        {key: "trialEndDate", value: "[trialEndDate]", text: "Trial End Date"},
        {key: "offerAmount", value: "[offerAmount]", text: "Offer Amount"},
        {key: "paymentFrequency", value: "[paymentFrequency]", text: "Payment Frequency"},
        {key: "brandPhone", value: "[brandPhone]", text: "Brand Phone Number"},
        {key: "brandEmail", value: "[supportEmail]", text: "Support Email"}
    ]

    // Sets the different items in the dropdown list
    listItem = this.variableOptions.map(item => (
        <li 
        onClick={this.addVariable.bind(this, item.value)} 
        key={item.key}
        className="rdw-dropdownoption-default variable-li"
        >{item.text}</li>
    ))

    render() {
        return (
        <div onClick={this.openVariablesDropdown} className="rdw-block-wrapper" aria-label="rdw-block-control">
            <div className="rdw-dropdown-wrapper rdw-block-dropdown" aria-label="rdw-dropdown">
            <div className="rdw-dropdown-selectedtext" title="Variables">
                <span>Site Variables</span> 
                <div className={`rdw-dropdown-caretto${this.state.open? "close": "open"}`}></div>
            </div>
            <ul className={`rdw-dropdown-optionwrapper ${this.state.open? "": "variable-ul"}`}>
                {this.listItem}
            </ul>
            </div>
        </div>
        );
    }
}

export default VariableDropdown;