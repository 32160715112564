import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import * as actionTypes from '../../Store/Actions/AdIDManager';

import {Container, Row, Col, Spinner, Alert} from 'react-bootstrap';
import MultiSectionCard from '../../Components/Cards/MultiSectionCard/MultiSectionCard';
import EditableCard from '../../Components/Cards/EditableCard/EditableCard';

import './AdIDManagerEdit.scss';

const AdIDManagerEdit = (props) => {
    // State to set the edit state json
    const [editState, setEditState] = useState(null);
    // State for UCT Entries
    const [uctEditState, setUctEditState] = useState(null);
    // State to set the filters used if editing an AdID
    const [filters, setFilters] = useState([]);
    // State to set the filters used if editing a uct entry
    const [uctFiltersSelected, setUctFiltersSelected] = useState(["adid"]);
    // State to set the json needed to pass to the update endpoints
    const [saveJson, setSaveJson] = useState(null)

    // AdIDManager Redux Store states stored in variables
    const viewAdidDetails = useSelector(state => state.adidManager.viewAdidDetails);
    const viewAdidUCTDetails = useSelector(state => state.adidManager.viewAdidUCTDetails);
    const viewSuperPartnerDetails = useSelector(state => state.adidManager.viewSuperPartnerDetails);
    const viewPartnerDetails = useSelector(state => state.adidManager.viewPartnerDetails);
    const uctTableHeaders = useSelector(state => state.adidManager.uctTableHeaders);
    const editType = useSelector(state => state.adidManager.editType);
    const error = useSelector(state => state.adidManager.error);
    const errorMessage = useSelector(state => state.adidManager.errorMessage);
    const options = useSelector(state => state.adidManager.dropdownOptions);
    const partnerDropdownOptions = useSelector(state => state.adidManager.partnerDropdownOptions);
    const adidFilters = useSelector(state => state.adidManager.adidFilters);
    const uctFilters = useSelector(state => state.adidManager.uctFilters);
    const verticalType = useSelector(state => state.adidManager.verticalType);
    const loading = useSelector(state => state.adidManager.loading);
    const successAlert = useSelector(state => state.adidManager.successAlert);
    const userFirstName = useSelector(state => state.login.userFirstName);
    const userLastName = useSelector(state => state.login.userLastName);
    
    const dispatch = useDispatch();

    useEffect(() => {
        if (editState === null && editType !== null) {
            if (editType === "adid") {
                setEditState(viewAdidDetails);
            } else if (editType === "superpartner") {
                setEditState(viewSuperPartnerDetails)
            } else if (editType === "partner") {
                setEditState(viewPartnerDetails)
            }
        }
        if (!filters.length) {
            let newFilters = filters;
            

            newFilters = newFilters.concat(verticalType);

            setFilters(newFilters)
        }
        if (uctEditState === null && viewAdidUCTDetails !== null) {
            setUctEditState(viewAdidUCTDetails)
        }
    }, [viewAdidDetails, editState, editType, adidFilters, filters, verticalType, uctEditState, viewAdidUCTDetails, options, viewSuperPartnerDetails, viewPartnerDetails]);

    // Set what filters are enabled for the vertical types in the second section on the page
    let filterButtonHandler = (updatedFilters) => {
        setFilters(updatedFilters)
    }

    let uctFilterButtonHandler = (updatedFilters) => {
        setUctFiltersSelected(updatedFilters)
    }

    // Update the json correctly
    let updateHandler = (objKey, newValue) => {
        if (editType === "adid") {
            for (const item in saveJson) {
                let newItem = item;
    
                // Remove the string "Id" if it exists so the item can be used in an indexOf function
                if (item.indexOf('Id') !== -1) {
                    newItem = newItem.replace('Id', '');
                }
    
                // If the value contains ":" split it and only use the first value which will be a number or numbers and strings
                if (newValue.indexOf(':') !== -1) {
                    newValue = newValue.split(':')[0];
                }
                
                // If statement to deal with objkeys that contain the new item or to deal with specific values of objkey and item
                if ((objKey.toLowerCase().indexOf(newItem.toLowerCase()) !== -1 && item !== "adDescription") && item !== "adId" && item !== "landingPage" || (objKey === "srpInfo" && item === "previewSearchId") || (objKey === "externalLandingPage" && item === "landingPageAddtnlParams") || (objKey === "regInfo" && item === "regAttCategoryId")) {
                    if (item.indexOf('Id') !== -1 && item !== "previewSearchId") {
                        saveJson[item] = parseInt(newValue);
                    } else {
                        saveJson[item] = newValue;
                    }
                } else if ((objKey === "additionalAdDescription" || objKey === "jiraReference") && item === "adDescription") {
                    if (newValue === document.getElementsByClassName('additionalAdDescription')[0].getElementsByClassName('form-control')[0].value) {
                        saveJson[item] = `${document.getElementsByClassName('jiraReference')[0].getElementsByClassName('form-control')[0].value} ${newValue}`
                    } else if (newValue === document.getElementsByClassName('jiraReference')[0].getElementsByClassName('form-control')[0].value) {
                        saveJson[item] = `${newValue} ${document.getElementsByClassName('additionalAdDescription')[0].getElementsByClassName('form-control')[0].value}`
                    }
                } else if (objKey === newItem) {
                    saveJson[item] = newValue;
                }
            }
            setSaveJson(saveJson)
        } else if (editType === "superpartner") {
            for (const item in saveJson) {
                if (item.toLowerCase().indexOf('id') !== -1) {
                    saveJson[item] = objKey.id
                } else if (item.toLowerCase().indexOf('name') !== -1) {
                    saveJson[item] = objKey.name
                } 
            }
            
            setSaveJson(saveJson)
        } else if (editType === "partner") {
            for (const item in saveJson) {
                if (item.toLowerCase() === objKey.toLowerCase() && item !== "headerType") {
                    saveJson[item] = newValue
                } else if (item === "partnerId" && objKey === "id" || item === "brandId" && objKey === "brand" || item === "superPartnerId" && objKey === "superPartner") {
                    let splitValue = newValue.split(':')[0];

                    saveJson[item] = parseInt(splitValue)
                } else if (item === "headerType" && objKey === "headerType" || item === "verifiPrepaidBypassPercentage" && objKey === "verifyPrepaidBypass %") {
                    saveJson[item] = parseInt(newValue)
                } else if (item === "verticalType" && objKey === "vertical" || item === "channelType" && objKey === "channel" || item === "responsiveLandingPixel" && objKey === "landingPagePixel (responsive)" || item === "responsiveRegPixel" && objKey === "regPagePixel (responsive)" || item === "responsiveConfirmationPixel" && objKey === "conf/upsellPagePixel (responsive)" || item === "responsiveSrpPixel" && objKey === "srpPixel (responsive)" || item === "responsiveSrpLightboxPixel" && objKey === "srpLightboxPixel (responsive)") {
                    saveJson[item] = newValue
                }
            }

            setSaveJson(saveJson)
        }
    }

    // UCT Section Search Handler
    let searchHandler = (event) => {
        event.preventDefault();
        
        let searchValue = event.target.nodeName === "IMG" ? event.target.closest('.form-group').getElementsByClassName('form-control')[0].value : event.target.value;
        
        if ((event.keyCode === 13 || event.target.nodeName === "IMG") && searchValue !== '') {
            let curFilters = uctFiltersSelected;
            curFilters = curFilters.filter(item => item !== "adid");

            if (!curFilters.length || !curFilters.some(filter => filter.indexOf("searchText") !== -1)) {
                curFilters = curFilters.concat(`searchText ${searchValue}`)
            } else if (curFilters.some(filter => filter.indexOf("searchText") !== -1)) {
                curFilters = curFilters.filter(item => item.indexOf('searchText') === -1)
                curFilters = curFilters.concat(`searchText ${searchValue}`)
            }
            
            setUctFiltersSelected(curFilters)

        } else if (searchValue === "") {
            let curFilters = uctFiltersSelected;

            curFilters = curFilters.filter(item => item.indexOf('searchText') === -1).concat('adid')

            setUctFiltersSelected(curFilters)
        }
    }

    // Save the new UCT value/entry
    let updateUctHandler = (newText, id, jiraReference) => {
        let uctEntry;
        let newUctEntryObj = {};
        let user = userFirstName.toLowerCase() + userLastName.substring(0, 1).toLowerCase();

        uctEditState.forEach((key, index) => {
            if (key.id !== undefined && key.id.toString() === id) {
                uctEntry = key;
            }
        });

        if (uctEntry.value !== newText) {
            newUctEntryObj.id = uctEntry.id;
            newUctEntryObj.adId = uctEntry.ad_ID;
            newUctEntryObj.newValue = newText;
            newUctEntryObj.newRevisionComment = jiraReference;
            newUctEntryObj.newLastModifyingUser = user;
    
            dispatch(actionTypes.saveUCTDetails(newUctEntryObj));
        }
    }

    // Save the json
    let saveHandler = (json) => {
        let urlParams = {
            jira: document.getElementsByClassName('jiraReference')[0].getElementsByClassName('form-control')[0].value,
            user: userFirstName.toLowerCase() + userLastName.substring(0, 1).toLowerCase(),
            description: document.getElementsByClassName('reasonForChange')[0].getElementsByClassName('form-control')[0].value
        }
        if (editType === "adid" && saveJson !== null) {
            dispatch(actionTypes.saveAdidDetails(saveJson, urlParams))
        } else if (editType === "superpartner" && saveJson !== null) {
            let newSaveJson = saveJson;

            newSaveJson.lastModifyingUser = `${userFirstName.toLowerCase()}${userLastName.substring(0, 1).toLowerCase()}`;
            dispatch(actionTypes.saveSuperPartner(newSaveJson, urlParams))
        } else if (editType === "partner" && saveJson !== null) {
            let user = `${userFirstName.toLowerCase()}${userLastName.substring(0, 1).toLowerCase()}`;
            let newDate = new Date();
            let isoDate = newDate.toISOString();

            saveJson.lastModifyingUser = user;
            saveJson.lastModified = isoDate;

            for (const item in saveJson) {
                if (saveJson[item] === "NULL") {
                    saveJson[item] = null
                }
            }

            dispatch(actionTypes.savePartner(saveJson, urlParams))
        }
    }

    let editJson;

    if (editState === null || !filters.length) {
        editJson = (
            <Row className="mt-5 pt-5 w-100 display-ads">
                <Col className="text-center">
                    <Spinner size="lg" animation="border" variant="primary" className="m-auto" />
                </Col>
            </Row>
        )
    } else if (error) {
        editJson = (
            <Row className="mt-5 pt-5 justify-content-center">
                <Col>
                    <Alert variant="danger" className="text-center">
                        There was an issue getting the content for this page! Please go back to the results page to try again!
                    </Alert>
                </Col>
            </Row>
        )
    } else {
        let updatedJson = {
            headerJson: {},
            realHeaderJson: {},
            bodyJson: {},
            realJson: {},
            setJson: {},
            tableJson: [],
            selectedVertical: "",
            requiredFields: [],
            disabledFields: []
        }
        let dropdownOptions;
        let disabled = false;
        let salesAlert = false;
        let salesAlertText;

        if (editType === "adid") {
            updatedJson.headerJson = {
                "section1": {
                    "buttons": "save"
                },
                "section2": {
                    "title1": "Page & Flow Settings",
                    "buttons": "filter buttons",
                    "filterHandler": filterButtonHandler,
                    "filterOptions": adidFilters,
                    "selectedFilters": filters
                },
                "section3": {
                    "title1": "UCT Settings",
                    "type": "table",
                    "buttons": "filter buttons",
                    "search": true,
                    "searchPlaceholder": "Search UCT...",
                    "searchHandler": searchHandler,
                    "filterHandler": uctFilterButtonHandler,
                    "filterOptions": uctFilters,
                    "selectedFilters": uctFiltersSelected,
                    "updateHandler": updateUctHandler,
                    "filterText": "Entries With:"
                }
            }

            dropdownOptions = [{"type": ""}, {}];

            dropdownOptions[0] = options.brand;
            dropdownOptions[0]["type"] = "brand";
            dropdownOptions[1] = options.eoPromotion;
            dropdownOptions[1]["type"] = "eoPromotion";
            dropdownOptions[2] = options.faqSet;
            dropdownOptions[2]["type"] = "faqSet";
            dropdownOptions[3] = options.footerSet;
            dropdownOptions[3]["type"] = "footerSet";
            dropdownOptions[4] = options.headerSet;
            dropdownOptions[4]["type"] = "headerSet";
            dropdownOptions[5] = options.partner;
            dropdownOptions[5]["type"] = "partner";
            dropdownOptions[6] = options.privacyPolicy;
            dropdownOptions[6]["type"] = "privacyPolicy";
            dropdownOptions[7] = options.regInfo;
            dropdownOptions[7]["type"] = "regInfo";
            dropdownOptions[8] = options.srpInfo;
            dropdownOptions[8]["type"] = "srpInfo";
            dropdownOptions[9] = options.terms;
            dropdownOptions[9]["type"] = "terms";
            dropdownOptions[10] = options.testimonials;
            dropdownOptions[10]["type"] = "testimonials";
            dropdownOptions[11] = options.welcomePageInfo;
            dropdownOptions[11]["type"] = "welcomePageInfo";
            dropdownOptions[12] = ["welcome", "signup", "search", "NULL"];
            dropdownOptions[12]["type"] = "landingPage";

            updatedJson.setJson['section1'] = {
                "jiraReference": [],
                "reasonForChange": [],
                "additionalAdDescription": [],
                "name": [],
                "location": [],
                "partner": [],
                "brand": [],
                "landingPage": [],
                "externalLandingPage": []
            };
            updatedJson.setJson['section2'] = {
                "welcomePageInfo": [],
                "srpInfo": [],
                "regInfo": [],
                "headerSet": [],
                "footerSet": [],
                "faqSet": [],
                "eoPromotion": [],
                "privacyPolicy": [],
                "terms": [],
                "testimonials": []
            };
            updatedJson.setJson['section3'] = {};
        } else if (editType === "partner") {
            dropdownOptions = [{"type": ""}, {}];

            dropdownOptions[0] = partnerDropdownOptions.brand;
            dropdownOptions[0]["type"] = "brand";
            dropdownOptions[1] = partnerDropdownOptions.superPartner;
            dropdownOptions[1]["type"] = "superPartner";
            dropdownOptions[2] = partnerDropdownOptions.vertical;
            dropdownOptions[2]["type"] = "vertical";
            dropdownOptions[3] = partnerDropdownOptions.channel;
            dropdownOptions[3]["type"] = "channel";
            dropdownOptions[4] = partnerDropdownOptions.partnerType;
            dropdownOptions[4]["type"] = "partnerType";
            dropdownOptions[5] = partnerDropdownOptions.saleType;
            dropdownOptions[5]["type"] = "saleType";
            dropdownOptions[6] = partnerDropdownOptions.headerType;
            dropdownOptions[6]["type"] = "headerType";

            updatedJson.setJson['section1'] = {
                "id": null,
                "name": null,
                "superPartner": null,
                "brand": null,
                "vertical": null,
                "channel": null,
                "partnerType": null,
                "saleType": [],
                "verifyPrepaidBypass %": [],
                "logoImage": [],
                "logoSmallImage": [],
                "logoMobileImage": [],
                "logoMobileSmallImage": [],
                "logoUrl": [],
                "headerType": [],
                "headerHtml": [],
                "landingPagePixel (responsive)": [],
                "regPagePixel (responsive)": [],
                "conf/upsellPagePixel (responsive)": [],
                "srpPixel (responsive)": [],
                "srpLightboxPixel (responsive)": [],
                "responsiveVerificationPixel": []
            }
        }

        editState.jiraReference = ''
        editState.reasonForChange = ''
        for (const item in editState) {
            if (editType === "adid") {
                if (editState[item] === null) {
                    editState[item] = "NULL"
                }
                if (item.indexOf("adDescription") !== -1 || item === "name" || item.indexOf("location") !== -1 || item.indexOf("partner") !== -1 || item.indexOf("brand") !== -1 || item.indexOf("landingPage") !== -1) {
                    if (item.indexOf("partner") !== -1 || item.indexOf("brand") !== -1 || item === "landingPage") {
                        if (item.indexOf('Id') !== -1) {
                            updatedJson.realJson[item] = editState[item]
                        } else if (item === "landingPage") {
                            updatedJson.realJson[item] = editState[item]

                            updatedJson.setJson.section1[item].push({id: item})
                            updatedJson.setJson.section1[item].push({name: editState[item]})
                        } else {
                            updatedJson.setJson.section1[item].push({id: item})
                            updatedJson.setJson.section1[item].push({name: editState[item]})
                        }
                    } else if (item.indexOf('landingPageAddtnlParams') !== -1) {
                        updatedJson.realJson[item] = editState[item]

                        updatedJson.setJson.section1["externalLandingPage"].push({id: "externalLandingPage"})
                        updatedJson.setJson.section1["externalLandingPage"].push({name: editState[item]})
                        updatedJson.setJson.section1["externalLandingPage"].push({field: "input", required: true})
                    } else if (item.indexOf("adDescription") !== -1) {
                        updatedJson.headerJson.section1["title2"] = editState[item]
                        updatedJson.realJson[item] = editState[item]

                        let splitData = editState[item].split(/([A-Z][A-Z]+-[0-9]+)/);
                        let newAdDescription = "";
                        
                        for (let i = 0; i < splitData.length; i++) {
                            if (splitData[i].match(/([A-Z][A-Z]+-[0-9]+)/)) {
                                updatedJson.setJson.section1["jiraReference"].push({id: "jiraReference"})
                                updatedJson.setJson.section1["jiraReference"].push({name: ""})
                                updatedJson.setJson.section1["jiraReference"].push({field: "input", required: true})
                            } else {
                                newAdDescription === "" ? newAdDescription = `${newAdDescription.trim()}${splitData[i].trim()}` : newAdDescription = `${newAdDescription.trim()} ${splitData[i].trim()}`
                            }
                        }

                        if (splitData.indexOf(/([A-Z][A-Z]+-[0-9]+)/) === -1) {
                            updatedJson.setJson.section1["jiraReference"].push({id: "jiraReference"})
                            updatedJson.setJson.section1["jiraReference"].push({name: ""})
                            updatedJson.setJson.section1["jiraReference"].push({field: "input", required: true})
                        }

                        updatedJson.setJson.section1["reasonForChange"].push({id: "reasonForChange"})
                        updatedJson.setJson.section1["reasonForChange"].push({name: ""})
                        updatedJson.setJson.section1["reasonForChange"].push({field: "input", required: true})

                        updatedJson.setJson.section1["additionalAdDescription"].push({id: "additionalAdDescription"})
                        updatedJson.setJson.section1["additionalAdDescription"].push({name: newAdDescription})
                        updatedJson.setJson.section1["additionalAdDescription"].push({field: "input", required: true})
                    } else {
                        updatedJson.realJson[item] = editState[item]

                        updatedJson.setJson.section1[item].push({id: item})
                        updatedJson.setJson.section1[item].push({name: editState[item]})
                        updatedJson.setJson.section1[item].push({field: "input", required: true})
                    }
                } else if (item.indexOf("welcome") !== -1 || item.indexOf("srp") !== -1 || item.indexOf("search") !== -1 || item.indexOf("reg") !== -1 || item.indexOf("header") !== -1 || item.indexOf("footer") !== -1 || item.indexOf("faq") !== -1 || item.indexOf("eoPromotion") !== -1 || item.indexOf("privacy") !== -1 || item.indexOf("terms") !== -1 || item.indexOf("testimonial") !== -1) {
                    if (item.indexOf('Id') !== -1) {
                        updatedJson.realJson[item] = editState[item]
                    } else {
                        updatedJson.setJson.section2[item].push({id: item})
                        updatedJson.setJson.section2[item].push({name: editState[item]})
                    }
                } else if (item.indexOf("adId") !== -1) {
                    updatedJson.headerJson.section1["title1"] = editState[item]
                    updatedJson.realJson[item] = editState[item]
                } else if (item.indexOf("previewSearchId") !== -1) {
                    updatedJson.realJson[item] = editState[item]
                } else if (item.indexOf("sales") !== -1) {
                    if (editState[item] !== "NULL" && editState[item] !== 0) {
                        disabled = true;
                        salesAlert = true;
                        salesAlertText = (
                            <>
                                <h5 className="font-weight-bold">This AdID contains sales so it cannot be edited.</h5>
                                <p className="mb-0">Please clone this AdID if you would like to make changes to it.</p>
                            </>
                        )
                    }
                } 
            } else if (editType === "superpartner") {
                if (item.toLowerCase().indexOf('id') !== -1 || item.toLowerCase().indexOf('name') !== -1) {
                    if (item.toLowerCase().indexOf('id') !== -1) {
                        updatedJson.bodyJson['id'] = editState[item]
                        updatedJson.realJson[item] = editState[item]
                        updatedJson.requiredFields.push(item)
                        updatedJson.disabledFields.push('id')
                    } else {
                        updatedJson.bodyJson['name'] = editState[item]
                        updatedJson.realJson[item] = editState[item]
                        updatedJson.requiredFields.push(item)
                    }
                }
                updatedJson.bodyJson['jiraReference'] = ''
                updatedJson.bodyJson['reasonForChange'] = ''
                updatedJson.requiredFields.push('jiraReference')
                updatedJson.requiredFields.push('reasonForChange')
            } else if (editType === "partner") {
                let newArray = [];
                let fieldRequired;

                if (item === "jiraReference") {
                    newArray.push({id: "jiraReference"})
                    newArray.push({name: ''})
                    newArray.push({field: "input", required: true})
                    updatedJson.setJson.section1["jiraReference"] = newArray;
                    updatedJson.realJson[item] = editState[item]
                }
                if (item === "reasonForChange") {
                    newArray.push({id: "reasonForChange"})
                    newArray.push({name: ''})
                    newArray.push({field: "input", required: true})
                    updatedJson.setJson.section1['reasonForChange'] = newArray;
                    updatedJson.realJson[item] = editState[item]
                }
    
                if (item === "partnerId" || item === "name" || item === "verifiPrepaidBypassPercentage" ||  item === "logoImage" || item === "logoSmallImage" || item === "logoMobileImage" || item === "logoMobileSmallImage" || item === "logoUrl" || item === "headerHtml" || item === "responsiveLandingPixel" || item === "responsiveRegPixel" || item === "responsiveConfirmationPixel" || item === "responsiveVerificationPixel" || item === "responsiveSrpPixel" || item === "responsiveSrpLightboxPixel" || item === "channel" || item === "saleType" || item === "brand" || item === "superPartner" || item === "vertical" || item === "headerType" || item === "verticalType" || item === "channelType" || item === "partnerType") {
                    if (editState[item] === null) {
                        editState[item] = "NULL"
                    }
                    let itemName;

                    if (item === "partnerId" || item === "name" || item === "verifiPrepaidBypassPercentage" ||  item === "logoImage" || item === "logoSmallImage" || item === "logoMobileImage" || item === "logoMobileSmallImage" || item === "logoUrl") {
                        fieldRequired = item === "name" ? true : false;
                        
                        if (item === "partnerId") {
                            itemName = "id"
                        } else if (item === "verifiPrepaidBypassPercentage") {
                            itemName = "verifyPrepaidBypass %"
                        } else {
                            itemName = item;
                        }

                        newArray.push({id: itemName})
                        newArray.push({name: editState[item]})
                        newArray.push({field: "input", required: fieldRequired, colClass: "4", type: item === "verifiPrepaidBypassPercentage" || item === "partnerId" ? 'number' : "", disabled: item === "partnerId" ? true : ""})
                        updatedJson.setJson.section1[itemName] = newArray;
                        updatedJson.realJson[item] = editState[item]
                    } else if (item === "headerHtml" || item === "responsiveLandingPixel" || item === "responsiveRegPixel" || item === "responsiveConfirmationPixel" || item === "responsiveVerificationPixel" || item === "responsiveSrpPixel" || item === "responsiveSrpLightboxPixel") {
                        if (item === "responsiveSrpPixel") {
                            itemName = "srpPixel (responsive)"
                        } else if (item === "responsiveSrpLightboxPixel") {
                            itemName = "srpLightboxPixel (responsive)"
                        } else if (item === "responsiveRegPixel") {
                            itemName = "regPagePixel (responsive)"
                        } else if (item === "responsiveConfirmationPixel") {
                            itemName = "conf/upsellPagePixel (responsive)"
                        } else if (item === "responsiveLandingPixel") {
                            itemName = "landingPagePixel (responsive)"
                        } else {
                            itemName = item;
                        }

                        newArray.push({id: itemName})
                        newArray.push({name: editState[item]})
                        newArray.push({field: "textarea", required: true})
                        updatedJson.setJson.section1[itemName] = newArray;
                        updatedJson.realJson[item] = editState[item]
                    } else {
                        fieldRequired = item === "channelType" || item === "saleType" || item === "brand" || item === "superPartner" || item === "verticalType" || item === "headerType" ? true : false;

                        if (item === "channelType") {
                            itemName = "channel"
                        } else if (item === "verticalType") {
                            itemName = "vertical"
                        } else {
                            itemName = item;
                        }

                        newArray.push({id: itemName})
                        newArray.push({name: editState[item]})
                        newArray.push({colClass: "4", required: fieldRequired})
                        updatedJson.setJson.section1[itemName] = newArray;
                        if (item !== "superPartner" && item !== "brand") {
                            updatedJson.realJson[item] = editState[item]
                        }
                    }
                } else if (item.indexOf('List') === -1) {
                    updatedJson.realJson[item] = editState[item]
                }
            }
        }

        if (editType === "adid" && uctEditState !== null) {
            uctEditState.map((keyTwo, indexTwo) => {
                let newobj = {};
                let newLastModified = keyTwo.lastModified.replace('T', ' ').split('.');

                newobj.id = keyTwo.id.toString();
                newobj.name = keyTwo.name.toString();
                newobj.value = `editable ${keyTwo.value.toString()}`;
                newobj.brand = keyTwo.brandId.toString();
                newobj["revision comment"] = keyTwo.revisionComment.toString();
                newobj["last modified"] = newLastModified[0].toString();
                newobj["adid"] = `hideColumn ${keyTwo.adId}`;
                newobj["super partner"] = `hideColumn ${keyTwo.superPartnerId}`;
                newobj.partner = `hideColumn ${keyTwo.partnerId}`;
                newobj.vertical = `hideColumn ${keyTwo.verticalType}`;
                newobj["visitor tag"] = `hideColumn ${keyTwo.visitorTag}`;

                updatedJson.tableJson.push(newobj);
            })
        }

        if (saveJson === null) {
            setSaveJson(updatedJson.realJson);
        }
        
        if (editType === "adid" || editType === "partner") {
            editJson = (
                <MultiSectionCard {...props} type={editType} dropdownOptions={dropdownOptions} realJson={updatedJson.realJson} realHeaderJson={updatedJson.realHeaderJson} headerJson={updatedJson.headerJson} bodyJson={updatedJson.bodyJson} setJson={updatedJson.setJson} cardLabel={`View ${editType}`} orderDropdownBy={'split :'} btnFilters={adidFilters} selectedFilter={filters} filterButtonHandler={filterButtonHandler} customHandler={updateHandler} saveHandler={saveHandler} disableEditing={disabled} alertState={salesAlert} success={successAlert} alertStateMessage={salesAlertText} saving={loading} tableHeaders={uctTableHeaders} tableItems={updatedJson.tableJson} hiddenTableItems={true} bottomBtnText={"save"} notCreate={true} error={error} errorMessage={errorMessage} />
            )
        } else if (editType === "superpartner") {
            editJson = (
                <Row>
                    <Col xs={6}>
                        <EditableCard realJson={updatedJson.realJson} realHeaderJson={updatedJson.realHeaderJson} headerJson={updatedJson.headerJson} bodyJson={updatedJson.bodyJson} requiredFields={updatedJson.requiredFields} cardLabel={'edit super partner'} {...props} create={true} notCreate={true} createEdit={true} btnText={'Save'} category={'adidManager'} customSaveHandler={saveHandler} updateCreateStateHandler={updateHandler} cardLabelOnly={true} disabledFields={updatedJson.disabledFields} success={successAlert} />
                    </Col>
                </Row>
            )
        }
    }
    
    return ( 
        <Container fluid className="adid-manager-edit-content py-4 my-3">
            {editJson}
        </Container>
    );
}
 
export default AdIDManagerEdit;