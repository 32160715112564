import React from 'react';

import {ButtonGroup, Button, Tooltip, OverlayTrigger, Spinner} from 'react-bootstrap';

import imageEdit from '../../../Images/img-edit.svg';
import imageClone from '../../../Images/img-clone.svg';
import imagePreview from '../../../Images/img-preview.svg';

import './SetButtons.scss';

const SetButtons = (props) => {
    let btnSpinner = (target, key, category) => {
        target.closest('.btn').getElementsByClassName('img-icon')[0].classList.add('d-none');
        target.closest('.btn').getElementsByClassName('spinner-component')[0].classList.remove('d-none');

        setTimeout(() => {
            props.setBtnHandler(target, key, category);
        }, 50);
    }
    let buttons = props.buttons.map((key, index) => {
        let btnImg;

        // Switch statement to set the correct image based on the button case
        switch(key) {
            case  "edit":
                btnImg = imageEdit
                break;
            case "clone":
                btnImg = imageClone
                break;
            case "preview":
                btnImg = imagePreview
                break;
            default:
                btnImg = imageEdit
                break;
        }

        if (props.tooltip !== undefined) {
            let renderTooltip = (
                <Tooltip id="button-tooltip" {...props} className="text-capitalize">
                    {key}
                </Tooltip>
            );

            return (
                <OverlayTrigger key={index} placement="top" delay={{ show: 150, hide: 200 }} overlay={renderTooltip}>
                    <Button className={`bg-primary rounded-pill position-relative ${index !== props.buttons.length - 1 ? 'mr-2' : ''} btn-${key}`} onClick={(event) => btnSpinner(event.target, key, props.category)}><img className="position-absolute img-icon" src={btnImg} alt="Edit/Save Button" /><Spinner size="sm" animation="border" variant="white" className="m-auto d-none spinner-component position-absolute" /></Button>
                </OverlayTrigger>
            )
        } else {
            return <Button key={index} className={`bg-primary rounded-pill position-relative ${index !== props.buttons.length - 1 ? 'mr-2' : ''} btn-${key}`}><img className="position-absolute" src={btnImg} alt="Edit/Save Button" /></Button>
        }
    })

    return ( 
        <ButtonGroup className="set-buttons ml-lg-2">
            {buttons}
        </ButtonGroup>
    );
}
 
export default SetButtons;