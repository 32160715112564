import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {withRouter} from 'react-router-dom';

import * as actionTypes from '../../Store/Actions/AdIDManager';

import {Container, Row, Col, Spinner, Alert, Button} from 'react-bootstrap';
import EditableCard from '../../Components/Cards/EditableCard/EditableCard';
import ResultsDropdown from '../../Components/Dropdown/ResultsDropdown/ResultsDropdown';
import ColumnLabels from '../../Components/Labels/ColumnLabels/ColumnLabels';
import DropdownSearch from '../../Components/Search/DropdownSearch/DropdownSearch';
import Filters from '../../Components/Filters/Filters';

import './AdIDManagerResults.scss';

const AdidResults = (props) => {
    // State to set number of top AdIDs to display
    const [topAdsDisplayNum, setTopAdsDislayNum] = useState(null);
    // State to set whether the AdIDs being displayed are Top AdIDs or Search Results AdIDs
    const [adDisplayType, setAdDisplayType] = useState(null);
    // State to set whether AdIDs have been displayed in order to show loading state if user comes from other navigation page after already visiting this page
    const [adResults, setAdResults] = useState(null);
    // State to set what the user selected from the different Search Types dropdown
    const [searchDropdownSelection, setSearchDropdownSelection] = useState("AdID");

    // AdIDManager Redux Store states stored in variables
    const displayAdsResults = useSelector(state => state.adidManager.adidsResults);
    const topAdsAmount = useSelector(state => state.adidManager.topAdsAmount);
    const topAdsAmountOptions = useSelector(state => state.adidManager.topAdsAmountOptions);
    const columnLabels = useSelector(state => state.adidManager.columnLabels);
    const endpointError = useSelector(state => state.adidManager.error);
    const loading = useSelector(state => state.adidManager.loading);
    const filters = useSelector(state => state.adidManager.searchFilters);
    const searchTextPlaceholder = useSelector(state => state.adidManager.searchText);
    const viewAdidDetails = useSelector(state => state.adidManager.viewAdidDetails);
    const viewAdidUCTDetails = useSelector(state => state.adidManager.viewAdidUCTDetails);
    const viewSuperPartnerDetails = useSelector(state => state.adidManager.viewSuperPartnerDetails);
    const viewPartnerDetails = useSelector(state => state.adidManager.viewPartnerDetails);
    const editType = useSelector(state => state.adidManager.editType);
    const endpointResponse = useSelector(state => state.adidManager.endpointResponse);
    const errorMessage = useSelector(state => state.adidManager.errorMessage);
    const partnerDropdownOptions = useSelector(state => state.adidManager.partnerDropdownOptions);
    const recentlyCreated = useSelector(state => state.adidManager.recentlyCreated);
    const successAlert = useSelector(state => state.adidManager.successAlert);

    const dispatch = useDispatch();

    // Sets the state for the number of Top AdIDs to display
    const updateTopAdsDisplayNum = (newNum) => {
        setTopAdsDislayNum(newNum)
    }

    // Updates the type that the user is searching by when selecting a new value in the dropdown
    const updateSearchDropdownSelection = (selection) => {
        setSearchDropdownSelection(selection)
    }

    // Search function to display adids returned from the endpoint
    const searchHandler = (searchText) => {
        dispatch(actionTypes.initGetSearchAdsJson(searchDropdownSelection, searchText));
        setAdDisplayType("search");
    }

    // Resets search box and goes back to showing the top adids
    const resetSearchHandler = (loadingState) => {
        if (adDisplayType !== "topAds") {
            dispatch(actionTypes.setSearchText(null));
            dispatch(actionTypes.initGetTopAdsJson(topAdsAmount, loadingState));
            setTopAdsDislayNum(topAdsAmount);
            setAdDisplayType("topAds");
        }
    }

    // Function to send user to the correct page to either create/clone/edit an adid, partner, or super partner
    const btnHandler = (target, type, category) => {
        if (category.toLowerCase() === "adid") {
            let adidSelected = target.closest('.item').getElementsByTagName("p")[0].textContent;
            let vertical = target.closest('.editable-card').getElementsByClassName('vertical')[0].getElementsByClassName('item-name')[0].textContent;
            let brandUrl = target.closest('.editable-card').getElementsByClassName('brandUrl')[0].getElementsByClassName('item-name')[0].textContent;

            if (vertical === "foreclosure") {
                vertical = "fl"
            }
            
            dispatch(actionTypes.setSelectedAdid(adidSelected));
            dispatch(actionTypes.setVerticalType(vertical));
            dispatch(actionTypes.setBrandUrl(brandUrl));

            if (type === "clone") {
                dispatch(actionTypes.setCreateState(type, category.toLowerCase()));

                props.history.push('/adid-manager-create');
            } else if (type === "edit") {
                dispatch(actionTypes.initViewAdidDetails(adidSelected, category.toLowerCase()));
                dispatch(actionTypes.initViewAdidUCTDetails(adidSelected));

                props.history.push('/adid-manager-edit');
            } else if (type === "preview") {
 
                props.history.push('/adid-manager-preview');
            }
        } else if (category.toLowerCase() === "superpartner") {
            let superPartnerSelected = target.closest('.item').getElementsByTagName("p")[0].textContent.split(':')[0];

            dispatch(actionTypes.initViewSuperPartnerDetails(superPartnerSelected))

            props.history.push('/adid-manager-edit');
        } else if (category.toLowerCase() === "partner") {
            let partnerSelected = target.closest('.item').getElementsByTagName("p")[0].textContent.split(':')[0];

            if (type === "clone") {
                dispatch(actionTypes.setSelectedAdid(partnerSelected));
                dispatch(actionTypes.setCreateState(type, category.toLowerCase()));
    
                props.history.push('/adid-manager-create');
            } else if (type === "edit") {
                dispatch(actionTypes.initViewPartnerDetails(partnerSelected, category.toLowerCase()));

                props.history.push('/adid-manager-edit');
            }
        }
    }

    // Function to handle creating a partner or super partner
    const createHandler = (event, type) => {
        dispatch(actionTypes.setCreateState("create", type));
        props.history.push('/adid-manager-create');
    }

    useEffect(() => {
        // If the number of Top AdIDs to be dislayed is null, then set the default number of Top AdIDs to be displayed to "25", set the AdID display type to "topAds", and then dispatch the Redux Store action that makes the endpoint call for the Top AdIDs endpoint
        if (topAdsDisplayNum === null && displayAdsResults === null && topAdsAmount === null) {
            dispatch(actionTypes.initGetTopAdsJson("25", false))
            dispatch(actionTypes.initGetFullAdDetails())
            setTopAdsDislayNum("25")
            setAdDisplayType("topAds")
        } else if (topAdsDisplayNum === null && displayAdsResults === null && topAdsAmount !== null) {
            dispatch(actionTypes.initGetTopAdsJson(topAdsAmount, false))
            dispatch(actionTypes.initGetFullAdDetails())
            setTopAdsDislayNum(topAdsAmount)
            setAdDisplayType("topAds")
        }

        // This code is for if the user comes back from visiting adid details. This code sets the proper display type so the results page knows what UI to show (i.e. whether it should be the top ads UI or search UI)
        if (adDisplayType === null && displayAdsResults !== null && topAdsDisplayNum === null && searchTextPlaceholder === null) {
            setAdDisplayType("topAds")
            setTopAdsDislayNum(topAdsAmount)
        } else if (adDisplayType === null && displayAdsResults !== null && topAdsDisplayNum === null && searchTextPlaceholder !== null) {
            setAdDisplayType("search")
        }

        // If the user comes from another page/container after having already visited this page/container, set the state to loaded after half a second so a loading state is shown
        if (adResults === null && displayAdsResults !== null) {
            setTimeout(() => {
                setAdResults('ads loaded')
            }, 1000)
        } else if (adResults === null) {
            // If there are no AdIDs displayed then set the ad results state to loaded
            setAdResults('ads loaded')
        }
        // Reset the UCT details global state
        if (viewAdidUCTDetails !== null) {
            dispatch(actionTypes.setUctAdidDetails(null))
        }
        // Reset the endpoint response global state
        if (endpointResponse !== null) {
            dispatch(actionTypes.setEndpointResponse(null))
        }
        // Resets Super Partner Details
        if (viewSuperPartnerDetails !== null) {
            dispatch(actionTypes.setSuperPartnerDetails(null))
        }
        // Reset error state
        if (errorMessage !== null) {
            dispatch(actionTypes.getAdsErrorReset())
        }
        // Get Partner Dropdown Options if null
        if (partnerDropdownOptions === null || recentlyCreated === "super partner") {
            dispatch(actionTypes.initPartnerDropdownDetails())
            dispatch(actionTypes.setRecentlyCreated(null))
        }
        // Reset Partner details
        if (viewPartnerDetails !== null) {
            dispatch(actionTypes.setPartnerDetails(null))
        }
        // Recall endpoint to get AdID info if a new partner was created
        if (recentlyCreated === "partner") {
            dispatch(actionTypes.initGetFullAdDetails())
            dispatch(actionTypes.setRecentlyCreated(null))
        }
        // Reset success alert
        if (successAlert === "Successfully created Super Partner") {
            setTimeout(() => {
                dispatch(actionTypes.setSuccessAlert(null))
            }, 4000)
        }
        else if (successAlert !== null) {
            dispatch(actionTypes.setSuccessAlert(null))
        }
    }, [topAdsDisplayNum, displayAdsResults, topAdsAmount, dispatch, adDisplayType, adResults, searchTextPlaceholder, viewAdidUCTDetails, endpointResponse, viewSuperPartnerDetails, errorMessage, partnerDropdownOptions, viewPartnerDetails, recentlyCreated, successAlert]);

    useEffect(() => {
        // If the current state for Top AdIDs to be displayed does not match the AdIDManager Redux Store state, then dispatch the Redux Store action that makes the endpoint call for the Top AdIDs to get the updated number of results
        if (topAdsDisplayNum !== null && topAdsDisplayNum !== topAdsAmount) {
            dispatch(actionTypes.initGetTopAdsJson(topAdsDisplayNum))
            setAdDisplayType("topAds")
        }
        // If viewAdidDetails still contains data, set it back to the default null
        if (viewAdidDetails !== null) {
            dispatch(actionTypes.setAdidDetails(null))
        }
        // Reset the edit type if it is not null
        if (editType !== null) {
            dispatch(actionTypes.setEditState(null));
        }
    }, [topAdsDisplayNum, viewAdidDetails, dispatch, topAdsAmount, editType])

    let displayAdidCards;

    if (displayAdsResults === null || loading === true || filters === null || adResults === null) {
        // If the number of Top Adids to display is null or there are no adid results to display, show a spinner
        displayAdidCards = (
            <Row className="mt-5 pt-5 w-100 display-ads">
                <Col className="text-center">
                    <Spinner size="lg" animation="border" variant="primary" className="m-auto" />
                </Col>
            </Row>
        );
    } else if(endpointError) {
        // If there are errors, then show the error alert message
        displayAdidCards = (
            <Row className="mt-5 pt-5 justify-content-center display-ads">
                <Col>
                    <Alert variant="danger" className="text-center">
                        There was an issue getting the content for this page! Please refresh the page to try again!
                    </Alert>
                </Col>
            </Row>
        )
    } else {
        let adidJson = displayAdsResults;

        let mainJson = adidJson.map((key, index) => {
            let updatedJson = {
                headerJson: {},
                realHeaderJson: {},
                bodyJson: {},
                setJson: {},
                activeJson: {}
            }

            updatedJson.setJson["adId"] = [];
            updatedJson.setJson["superPartner"] = [];
            updatedJson.setJson["partner"] = [];
            updatedJson.setJson["sales"] = [];
            updatedJson.setJson["brand"] = [];
            updatedJson.setJson["vertical"] = [];
            updatedJson.setJson["landing"] = [];
            updatedJson.setJson["brandUrl"] = [];

            for (const item in key) {
                if(key[item] === null) {
                    key[item] = "null"
                }
                if (item.toLowerCase().indexOf('adid') !== -1) {
                    updatedJson.bodyJson[item] = key[item]
                    updatedJson.setJson["adId"].push({id: key[item], boldFont: true, width: '22Point5'})
                    updatedJson.setJson["adId"].push({name: key[item]})
                    updatedJson.setJson["adId"].push({buttons: ['edit', 'clone', 'preview'], tooltip: true})
                } else if (item === "brandUrl") {
                        updatedJson.setJson["brandUrl"].push({id: key[item]})
                        updatedJson.setJson["brandUrl"].push({name: key[item]})
                        updatedJson.setJson["brandUrl"].push({hide: true}) 
                } else if (item.toLowerCase().indexOf('superpartner') !== -1) {
                    updatedJson.bodyJson[item] = key[item]
                    if (item === "superPartnerId") {
                        updatedJson.setJson["superPartner"].push({id: key[item], width: '17Point5'})
                        updatedJson.setJson["superPartner"].push({name: key[item]})
                        updatedJson.setJson["superPartner"].push({buttons: ['edit'], tooltip: true})
                    } else if (item === "superPartnerName") {
                        updatedJson.setJson["superPartner"][0].id = `${updatedJson.setJson["superPartner"][0].id}: ${key[item]}`;
                        updatedJson.setJson["superPartner"][1].name = `${updatedJson.setJson["superPartner"][1].name}: ${key[item]}`;
                    }
                } else if (item.toLowerCase().indexOf('partner') !== -1 && item.toLowerCase().indexOf('super') === -1) {
                    updatedJson.bodyJson[item] = key[item]
                    if (item === "partnerId") {
                        updatedJson.setJson["partner"].push({id: key[item], width: 30})
                        updatedJson.setJson["partner"].push({name: key[item]})
                        updatedJson.setJson["partner"].push({buttons: ['edit', 'clone'], tooltip: true})
                    } else if (item === "partnerName") {
                        updatedJson.setJson["partner"][0].id = `${updatedJson.setJson["partner"][0].id}: ${key[item]}`;
                        updatedJson.setJson["partner"][1].name = `${updatedJson.setJson["partner"][1].name}: ${key[item]}`;
                    }
                } else if (item.toLowerCase().indexOf('sales') !== -1) {
                    updatedJson.bodyJson[item] = key[item]
                    updatedJson.setJson["sales"].push({id: key[item], width: '7Point5'})
                    updatedJson.setJson["sales"].push({name: key[item]})
                } else if (item.toLowerCase().indexOf('brand') !== -1) {
                    updatedJson.bodyJson[item] = key[item]
                    if (item === "brandId") {
                        updatedJson.setJson["brand"].push({id: key[item], width: 15})
                        updatedJson.setJson["brand"].push({name: key[item]})
                    } else if (item === "brandName") {
                        updatedJson.setJson["brand"][0].id = `${updatedJson.setJson["brand"][0].id}: ${key[item]}`;
                        updatedJson.setJson["brand"][1].name = `${updatedJson.setJson["brand"][1].name}: ${key[item]}`;
                    }
                } else if (item.toLowerCase().indexOf('vertical') !== -1) {
                    updatedJson.bodyJson[item] = key[item]
                    if (key[item] === "foreclosure_rto") {
                        key[item] = "frto"
                    }
                    
                    updatedJson.setJson["vertical"].push({id: key[item]})
                    updatedJson.setJson["vertical"].push({name: key[item]})
                    updatedJson.setJson["vertical"].push({hide: true})
                } else if (item.toLowerCase().indexOf('landing') !== -1) {
                    updatedJson.bodyJson[item] = key[item]
                    updatedJson.setJson["landing"].push({id: key[item], width: '7Point5'})
                    updatedJson.setJson["landing"].push({name: key[item]})
                } else {
                    updatedJson.bodyJson[item] = key[item];
                }
            }

            return (
                <Col xs={12} key={index}>
                    <EditableCard cardMargin={'mb-3'} realHeaderJson={updatedJson.realHeaderJson} {...props} headerJson={updatedJson.headerJson} bodyJson={updatedJson.bodyJson} setJson={updatedJson.setJson} activeJson={updatedJson.activeJson} cardLabel={props.page} category="adidManager" itemSetNoLabel={true} setBtnHandler={btnHandler} />
                </Col>
            )
        })

        if (adidJson.length < 1) {
            displayAdidCards = (
                <Row className="mt-5 pt-5 justify-content-center display-ads">
                    <Col xs={12}>
                        <Alert variant="danger" className="text-center">
                            <h5 className="font-weight-bold">Your search returned no results.</h5>
                            <p>Please make sure you are entering in the correct information into the search box and that you're selecting the correct search type from the dropdown.</p>
                        </Alert>
                    </Col>
                </Row>
            )
        } else {
            displayAdidCards = (
                <>
                    <Row className="display-ads">
                        {mainJson}
                    </Row>
                    {topAdsDisplayNum === null || displayAdsResults === null || adDisplayType !== "topAds" ? '' : 
                        <Row className="top-adid-count-container position-fixed bg-light w-100 align-items-center">
                            <Col xs={12}>
                                <ResultsDropdown defaultDisplay={topAdsDisplayNum} allOptions={topAdsAmountOptions} descriptionText="Top AdID Display Count" updateSelected={updateTopAdsDisplayNum} />
                            </Col>
                        </Row>
                    }
                </>
            )
        }
    }
    
    return ( 
        <Container fluid className="adid-results-content pb-4 mb-3 position-relative">
            <div className={successAlert === "Successfully created Super Partner" ? "w-100 d-flex justify-content-center" : "d-none"}>
                <Alert variant="success" className="text-center position-absolute z-top">
                    {successAlert}
                </Alert>
            </div>
            {displayAdsResults !== null && filters !== null && adResults !== null ?
                <div className='sticky top-0 z-10 ml-[-14px] mr-[-14px] p-[10px] bg-white'>
                    <Row className="search-filters w-100 align-items-center">
                        <Col xs={8} className="d-flex flex-row flex-wrap">
                            <DropdownSearch placeholder={searchTextPlaceholder === null ? "ex TEST1234" : searchTextPlaceholder} options={["AdID", "AdID Description", "Super Partner", "Partner"]} title="AdID" updateDropdownSelection={updateSearchDropdownSelection} searchHandler={searchHandler} resetSearch={resetSearchHandler} loading={adDisplayType === "topAds" ? false : true} classes="w-auto flex-fill" />
                            <Filters filters={filters} />
                        </Col>
                        <Col xs={4} className="d-flex justify-content-end create-partner-btns">
                            <Button onClick={(event) => createHandler(event, "super partner")} className="mr-3 font-weight-bold px-3">Add Super Partner <i className="fas fa-plus-circle ml-1"></i></Button>
                            <Button onClick={(event) => createHandler(event, "partner")} className="font-weight-bold px-3">Add Partner <i className="fas fa-plus-circle ml-1"></i></Button>
                        </Col>
                    </Row>
                    <Row className="column-label w-100 align-items-center">
                        <Col xs={12} className="w-100 mt-3">
                            <ColumnLabels labels={columnLabels} />
                        </Col>
                    </Row>
                </div>
            : ''}
            {displayAdidCards}
        </Container>
    );
}

export default withRouter(AdidResults);