import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {withRouter} from 'react-router-dom';

import * as actionTypes from '../../Store/Actions/AdIDManager';

import {Container, Row, Col, Spinner} from 'react-bootstrap';
import EditableCard from '../../Components/Cards/EditableCard/EditableCard';
import MultiSectionCard from '../../Components/Cards/MultiSectionCard/MultiSectionCard';

const AdIDManagerCreate = (props) => {
    // State variable to set the correct json used for this page
    const [createJson, setCreateJson] = useState(null);
    // State variable for the return partner json
    const [partnerJson, setPartnerJson] = useState(null);

    // AdIDManager Redux Store states stored in variables
    const createType = useSelector(state => state.adidManager.createType);
    const createCategory = useSelector(state => state.adidManager.createCategory);
    const cloneAdid = useSelector(state => state.adidManager.cloneAdid);
    const selectedAdid = useSelector(state => state.adidManager.selectedAdid);
    const error = useSelector(state => state.adidManager.error);
    const errorMessage = useSelector(state => state.adidManager.errorMessage);
    const createSuperPartner = useSelector(state => state.adidManager.createSuperPartner);
    const clonePartner = useSelector(state => state.adidManager.clonePartner);
    const endpointResponse = useSelector(state => state.adidManager.endpointResponse);
    const partnerDropdownOptions = useSelector(state => state.adidManager.partnerDropdownOptions);
    const createPartner = useSelector(state => state.adidManager.createPartner);
    const returnPartnerJson = useSelector(state => state.adidManager.returnPartnerJson);
    const userFirstName = useSelector(state => state.login.userFirstName);
    const userLastName = useSelector(state => state.login.userLastName);
    const successAlert = useSelector(state => state.adidManager.successAlert);

    useEffect(() => {
        // If the user is cloning an AdID then set the data used in this container to the clone AdID data
        if (createType === "clone" && createCategory.toLowerCase() === "adid" && createJson === null) {
            setCreateJson(cloneAdid)
        } else if (createType === "create" && createJson === null) {
            if (createCategory === "super partner") {
                setCreateJson(createSuperPartner)
            } else if (createCategory.toLowerCase() === "partner") {
                setCreateJson(createPartner)
            }
        } else if (createType === "clone" && createCategory.toLowerCase() === "partner" && createJson === null) {
            setCreateJson(clonePartner)
        }

        // Make sure the endpoint returns a success message on creation
        if (endpointResponse !== null && endpointResponse === "success" && createCategory.toLowerCase() !== "super partner") {
            props.history.push(props.editPath);
        } else if (endpointResponse !== null && endpointResponse === "success" && createCategory.toLowerCase() === "super partner") {
            props.history.push(props.mainPath)
        }
        if (errorMessage !== null && createType === "clone" && createCategory.toLowerCase() === "adid") {
            document.getElementsByClassName('newAdid')[0].getElementsByClassName('form-control')[0].classList.add('error-border')
        } else if (errorMessage !== null && createType === "create" && (createCategory.toLowerCase() === "partner" || createCategory.toLowerCase() === "super partner")) {
            document.getElementsByClassName('id')[0].getElementsByClassName('form-control')[0].classList.add('error-border')
        } else if (errorMessage !== null && createType === "clone" && createCategory.toLowerCase() === "partner") {
            document.getElementsByClassName('partnerId')[0].getElementsByClassName('form-control')[0].classList.add('error-border')
        }
        // Check if partner dropdown options is null
        if (partnerDropdownOptions === null) {
            dispatch(actionTypes.initPartnerDropdownDetails())
        }
        // Check if partnerJson is null to provide correct json object format to send back
        if (partnerJson === null) {
            setPartnerJson(returnPartnerJson)
        }
    }, [createJson, createType, createCategory, cloneAdid, endpointResponse, errorMessage, partnerDropdownOptions, returnPartnerJson]);

    let dispatch = useDispatch();

    // Update the partnerJson state variable to capture all of the changes the user makes when creating a Partner.
    let updateHandler = (objKey, newValue) => {
        let newPartnerObj = returnPartnerJson;

        for (const item in newPartnerObj) {
            if (item === objKey && item !== "headerType") {
                newPartnerObj[item] = newValue
            } else if (objKey === "landingPagePixel (responsive)" && item === "responsiveLandingPixel" || objKey === "regPagePixel (responsive)" && item === "responsiveRegPixel" || objKey === "conf/upsellPagePixel (responsive)" && item === "responsiveConfirmationPixel" || objKey === "srpPixel (responsive)" && item === "responsiveSrpPixel" || objKey === "srpLightboxPixel (responsive)" && item === "responsiveSrpLightboxPixel" || item === "verticalType" && objKey === "vertical" || item === "channelType" && objKey === "channel" || item === "verifiPrepaidBypassPercentage" && objKey === "verifyPrepaidBypass %") {
                newPartnerObj[item] = newValue
            } else if (item === "headerType" && objKey === "headerType" || item === "partnerId" && objKey === "id") {
                newPartnerObj[item] = parseInt(newValue)
            } else if (objKey === "brand" && item === "brandId" || objKey === "superPartner" && item === "superPartnerId") {
                newValue = newValue.split(':')[0]
                newPartnerObj[item] = parseInt(newValue)
            }
        }

        setPartnerJson(newPartnerObj)
    }

    // Save the newly created Partner
    let saveHandler = () => {
        if (error) {
            dispatch(actionTypes.getAdsErrorReset())
        }
        let saveJson = partnerJson;
        let user = `${userFirstName.toLowerCase()}${userLastName.substring(0, 1).toLowerCase()}`;
        let newDate = new Date();
        let isoDate = newDate.toISOString();

        saveJson.lastModifyingUser = user;
        saveJson.lastModified = isoDate;

        dispatch(actionTypes.initCreatePartner(saveJson))
    }

    let createContent;

    if (createJson === null) {
        createContent = (
            <Row className="mt-5 pt-5 w-100">
                <Col className="text-center">
                    <Spinner size="lg" animation="border" variant="primary" className="m-auto" />
                </Col>
            </Row>
        )
    } else {
        let updatedJson = {
            headerJson: {},
            realHeaderJson: {},
            bodyJson: {},
            requiredFields: [],
            setJson: {},
            disabledFields: [],
            maxLengthOptions: []
        }
        let dropdownOptions;

        if (createCategory.toLowerCase() === "partner" && createType === "create") {
            partnerDropdownOptions.vertical = partnerDropdownOptions.vertical.filter(item => item !== "any");
            dropdownOptions = [{"type": ""}, {}];

            dropdownOptions[0] = partnerDropdownOptions.brand;
            dropdownOptions[0]["type"] = "brand";
            dropdownOptions[1] = partnerDropdownOptions.superPartner;
            dropdownOptions[1]["type"] = "superPartner";
            dropdownOptions[2] = partnerDropdownOptions.vertical;
            dropdownOptions[2]["type"] = "vertical";
            dropdownOptions[3] = partnerDropdownOptions.channel;
            dropdownOptions[3]["type"] = "channel";
            dropdownOptions[4] = partnerDropdownOptions.partnerType;
            dropdownOptions[4]["type"] = "partnerType";
            dropdownOptions[5] = partnerDropdownOptions.saleType;
            dropdownOptions[5]["type"] = "saleType";
            dropdownOptions[6] = partnerDropdownOptions.headerType;
            dropdownOptions[6]["type"] = "headerType";

            updatedJson.setJson['section1'] = {}
        }

        for (const item in createJson) {
            if (createType === "clone" && createCategory.toLowerCase() === "adid" || createType === "create" && createCategory.toLowerCase() === "super partner" || createType === "clone" && createCategory.toLowerCase() === "partner") {
                if (item === "adid" || item === "partnerIdToClone") {
                    updatedJson.bodyJson[item] = selectedAdid;
                    if (item === "partnerIdToClone") {
                        updatedJson.disabledFields.push(item)
                    }
                } else {
                    if (item === "partnerId") {
                        updatedJson.maxLengthOptions.push({"name": "partnerId", "length": 9})
                    } else if (createCategory.toLowerCase() === "super partner" && item === "id") {
                        updatedJson.maxLengthOptions.push({"name": "id", "length": 9})
                    }
                    updatedJson.bodyJson[item] = createJson[item];
                }
                
                updatedJson.requiredFields.push(item);
            } else if (createType === "create" && createCategory.toLowerCase() === "partner") {
                let newArray = [];
                let fieldRequired;
                if (item === "id" || item === "name" || item === "verifyPrepaidBypass %" ||  item === "logoImage" || item === "logoSmallImage" || item === "logoMobileImage" || item === "logoMobileSmallImage" || item === "logoUrl") {
                    fieldRequired = item === "name" || item === "id" ? true : false;
                    newArray.push({id: item})
                    newArray.push({name: createJson[item]})
                    newArray.push({field: "input", required: fieldRequired, colClass: "4", maxLength: item === "id" ? 9 : ""})
                    updatedJson.setJson.section1[item] = newArray;
                } else if (item === "headerHtml" || item === "landingPagePixel (responsive)" || item === "regPagePixel (responsive)" || item === "conf/upsellPagePixel (responsive)" || item === "responsiveVerificationPixel" || item === "srpPixel (responsive)" || item === "srpLightboxPixel (responsive)") {
                    newArray.push({id: item})
                    newArray.push({name: createJson[item]})
                    newArray.push({field: "textarea", required: true})
                    updatedJson.setJson.section1[item] = newArray;
                } else {
                    fieldRequired = item === "channel" || item === "saleType" || item === "brand" || item === "superPartner" || item === "vertical" || item === "headerType" ? true : false;
                    newArray.push({id: item})
                    newArray.push({name: createJson[item]})
                    newArray.push({colClass: "4", required: fieldRequired})
                    updatedJson.setJson.section1[item] = newArray;
                }
            }
        }
        
        if (createCategory.toLowerCase() === "adid" || createCategory.toLowerCase() === "super partner" || createType === "clone" && createCategory.toLowerCase() === "partner") {
            createContent = (
                <Col md={6}>
                    <EditableCard create={true} realHeaderJson={updatedJson.realHeaderJson} {...props} headerJson={updatedJson.headerJson} bodyJson={updatedJson.bodyJson} cardLabel={`${createType} ${createCategory}`} cardLabelOnly={true} category="adidManager" requiredFields={updatedJson.requiredFields} createType={createType} createCategory={createCategory} errorState={error} errorMessage={errorMessage} disabledFields={updatedJson.disabledFields} maxLengthOptions={updatedJson.maxLengthOptions} success={successAlert}/>
                </Col>
            )
        } else if (createType === "create" && createCategory.toLowerCase() === "partner") {
            createContent = (
                <Col xs={12}>
                    <MultiSectionCard {...props} type={createCategory} dropdownOptions={dropdownOptions} realHeaderJson={updatedJson.realHeaderJson} headerJson={updatedJson.headerJson} bodyJson={updatedJson.bodyJson} setJson={updatedJson.setJson} cardLabel={`${createType} ${createCategory}`} orderDropdownBy={'split :'} customHandler={updateHandler} saveHandler={saveHandler} bottomBtnText={"create"} notCreate={true} error={error} errorMessage={errorMessage} success={successAlert}/>
                </Col>
            )
        }
    }

    return ( 
        <Container fluid className="adid-manager-create-content py-4 my-3">
            <Row>
                {createContent}
            </Row>
        </Container>
    );
}
 
export default withRouter(AdIDManagerCreate);