import React from 'react';

import Table from '../Table/Table';

const CollapseContent = (props) => {
    let content;

    if (props.collapseType === "table") {
        content = <Table tableItems={props.collapseContent} {...props} />
    }
    return ( 
        <>
            {content}
        </>
    );
}
 
export default CollapseContent;