import React, { useRef } from 'react';

import {Card, Row, Col, Alert, Spinner} from 'react-bootstrap';
import CreateComponentButtons from '../../CreateComponents/CreateComponentButtons/CreateComponentButtons';
import EditableInput from '../../Inputs/EditableInput/EditableInput';
import EditableDropdown from '../../Dropdown/EditableDropdown/EditableDropdown';
import MultiSectionCardHeader from './MultiSectionCardHeader/MultiSectionCardHeader';
import Table from '../../Table/Table';
import CustomTextarea from '../../Textarea/CustomTextarea';

import './MultiSectionCard.scss';

const MultiSectionCard = (props) => {
    // This function checks if fields have a "required" attribute. If they do, it makes sure that they are filled out and not empty
    let errorHandler = () => {
        let sections = document.getElementsByClassName('section');
        let results = [];

        Object.keys(sections).forEach((item, index) => {
            if (sections[item].getElementsByClassName('editable-input').length || sections[item].getElementsByClassName('dropdown-toggle').length) {
                let requiredFilledOut = true;
                let inputs = sections[item].getElementsByClassName('editable-input');
                let dropdowns = sections[item].getElementsByClassName('dropdown-toggle');

                // If input field value has a "required" attribute then make sure it is filled out and not empty. If it has not, then set the "requiredFilledOut" variable to false.
                Object.keys(inputs).forEach((item, index) => {
                    if (inputs[item].hasAttribute("required")) {
                        if (inputs[item].value === "") {
                            inputs[item].classList.add('error-border')
                            
                            requiredFilledOut = false
                        }
                    }
                })

                // If dropdown field has a "required" attribute then make sure an item in the dropdown has been selected. If it has not, then set the "requiredFilledOut" variable to false.
                Object.keys(dropdowns).forEach((item, index) => {
                    if (dropdowns[item].hasAttribute("required")) {
                        if (dropdowns[item].querySelector('.selected-text').textContent === "") {
                            dropdowns[item].classList.add('error-border')

                            requiredFilledOut = false
                        }
                    }
                })

                let errorInput = sections[item].getElementsByClassName('text-danger');

                // If the "requiredFilledOut" variable is set to false, then add the "error-border" class to the field to let the user know this field is required to be filled out. Otherwise, do nothing.
                if (requiredFilledOut) {
                    let errorBorders = sections[item].querySelectorAll('.error-border');

                    for (let i = 0; i < errorInput.length; i++) {
                        errorInput[i].classList.add('d-none')
                    }
                    
                    Object.keys(errorBorders).forEach((item, index) => {
                        errorBorders[item].classList.remove('error-border');
                    });

                    results.push(requiredFilledOut)

                    return requiredFilledOut
                } else {
                    // If it's just an emtpty field error, use this error message
                    for (let i = 0; i < errorInput.length; i++) {
                        errorInput[i].textContent = "These fields are required."
                    
                        errorInput[i].classList.remove('d-none')
                    }

                    results.push(requiredFilledOut)
                    
                    return requiredFilledOut
                }
            }
        })
        
        return results;
    }

    // Set the content to be deisplayed on this page. Display Header if it exists. Otherwise, go through and output an input or a dropdown depending on type.
    let content = Object.keys(props.setJson).map((key, index) => {
        return (
            <Row key={`${key}-${index}`} className={`section ${index > 0 ? 'mt-4 pt-5' : ''}`}>
                {props.headerJson[`section${index + 1}`] !== undefined && props.headerJson[`section${index + 1}`].title1 !== undefined ? 
                    <MultiSectionCardHeader {...props} headerIndex={index} cardLabel={props.cardLabel} fieldErrorHandler={errorHandler} />
                : <Col xs={12}><h5 className={`text-danger ${props.error ? "" : "d-none"} text-center font-weight-bold pb-4 mb-0`}>{props.errorMessage ? props.errorMessage : ""}</h5></Col>}
                {props.headerJson[`section${index + 1}`] !== undefined && props.headerJson[`section${index + 1}`].type !== undefined ? 
                    props.tableItems.length > 0 ? 
                    <Col xs={12} className="mb-5">
                        <Table {...props} updateHandler={props.headerJson[`section${index + 1}`] !== undefined && props.headerJson[`section${index + 1}`].updateHandler !== undefined ? props.headerJson[`section${index + 1}`].updateHandler : ''} tableFilters={props.headerJson[`section${index + 1}`] !== undefined && props.headerJson[`section${index + 1}`].selectedFilters !== undefined ? props.headerJson[`section${index + 1}`].selectedFilters : ''} />
                    </Col>
                    : 
                    <Col xs={12} className="text-center">
                        <Spinner size="lg" animation="border" variant="primary" className="m-auto" />
                    </Col>
                :
                    Object.keys(props.setJson[key]).map((keyTwo, indexTwo) => {
                        let subJson = props.setJson[key][keyTwo];

                        if (subJson[2] !== undefined && subJson[2].field !== undefined && subJson[2].field === "input") {
                            return (
                                <Col key={`${keyTwo}-${indexTwo}`} xs={12} sm={subJson[2].colClass !== undefined ? subJson[2].colClass : 6} className={`${subJson[0].id}`}>
                                    <EditableInput label={subJson[0].id} name={subJson[1].name} customHandler={props.customHandler} disableEditing={props.disableEditing !== undefined && props.disableEditing ? props.disableEditing : ""} overrideDisable={subJson[2].disabled !== undefined ? subJson[2].disabled : ""} required={subJson[2].required !== undefined && subJson[2].required ? subJson[2].required : ''} inputType={subJson[2].type !== undefined ? subJson[2].type : ""} {...props} maxLength={subJson[2].maxLength !== undefined ? subJson[2].maxLength : ""} />
                                </Col>
                            )
                        } else if (subJson[2] !== undefined && subJson[2].field !== undefined && subJson[2].field === "textarea") {
                            return (
                                <Col key={`${keyTwo}-${indexTwo}`} xs={12} sm={subJson[2].colClass !== undefined ? subJson[2].colClass : 6} className={`${subJson[0].id}`}>
                                    <CustomTextarea {...props} label={subJson[0].id} textareaValue={subJson[1].name} customHandler={props.customHandler} />
                                </Col>
                            )
                        } else {
                            let options = [];
                            props.dropdownOptions.map((dropdownKey, dropdownIdex) => {
                                if (dropdownKey.type === subJson[0].id) {
                                    return options.push(dropdownKey)
                                }
                            });

                            return (
                                <Col key={`${keyTwo}-${indexTwo}`} xs={12} sm={subJson[2] !== undefined && subJson[2].colClass !== undefined ? subJson[2].colClass : 6} className={`${subJson[0].id}`}>
                                    <EditableDropdown objValue={subJson} noSelectText={true} objKey={subJson[0].id} marginBottom={true} dropdownOptions={options} orderBy={props.orderDropdownBy} customHandler={props.customHandler} selectedFilter={props.selectedFilter} disableEditing={props.disableEditing !== undefined && props.disableEditing ? props.disableEditing : ""} required={subJson[2] !== undefined && subJson[2].required !== undefined ? subJson[2].required : ""} {...props} />
                                </Col>
                            )
                        }
                    })
                }
                {props.headerJson[`section${index + 1}`] !== undefined && props.headerJson[`section${index + 1}`].title1 !== undefined ? ""
                : <Col xs={12}><h5 className={`text-danger ${props.error ? "" : "d-none"} text-center font-weight-bold pt-4 mb-0`}>{props.errorMessage ? props.errorMessage : ""}</h5></Col>}
            </Row>
        )
    });

    const alertRef = useRef(null);
    
    if (props.success) {
        try {
            const executeScroll = () => alertRef.current.scrollIntoView()
            executeScroll();
        } catch (e) {
        }
    }

    return ( 
        <Card className="editable-card border-0 multi-section-card">
            <Card.Body>
                {props.alertState ? 
                    <Alert variant="danger" className="text-center">
                        {props.alertStateMessage}
                    </Alert>
                : ''}
                <div ref={alertRef} className={props.success ? "pt-3" : "d-none"}>
                    <Alert variant="success" className="text-center">
                        {props.success}
                    </Alert>
                </div>
                <p className="font-weight-bold text-muted text-uppercase small-heading text-small">{props.cardLabel}</p>
                {content}
                <CreateComponentButtons error={props.errorState} saving={""} savingCreateHandler={props.saveHandler} {...props} editHandler={""} btnText={props.bottomBtnText} notCreate={props.notCreate} disableEditing={props.disableEditing !== undefined && props.disableEditing ? props.disableEditing : ""} fieldErrorHandler={errorHandler} />
            </Card.Body>
        </Card>
    );
}
 
export default MultiSectionCard;