import React, {useState} from 'react';

import {InputGroup, Dropdown, FormControl} from 'react-bootstrap';

import SearchIcon from '../../../Images/img-search.svg';

import '../../Dropdown/FilterStyleDropdown.scss';
import './DropdownSearch.scss';

const DropdownSearch = (props) => {
    // State to set which dropdown item is active
    const [activeState, setActiveState] = useState(props.title); 

    // Function to change the dropdown selected value, send the new value to the update handler, and then set the state for the dropdown selected to the new value
    let selectedValue = (eventkey, event) => {
        let newValue = event.target.textContent;
        let selectedTextSpan = event.target.closest('.dropdown').getElementsByClassName('selected-text')[0];

        selectedTextSpan.textContent = newValue;

        props.updateDropdownSelection(newValue);

        setActiveState(newValue);
    }

    // Sets the value of the text searched by the user
    let searchValue = (event) => {
        event.preventDefault();
        
        let eventKeyCode = event.keyCode;
        let searchText = event.target.nodeName === "IMG" ? event.target.closest('.input-group').getElementsByClassName('form-control')[0].value : event.target.value;

        if ((eventKeyCode === 13 || event.target.nodeName === "IMG") && searchText !== "") {
            // If the user hits enter, clicks on the search icon, or the search text is NOT empty, send the search text to the search handler function
            props.searchHandler(searchText)
        } else if (searchText === "") {
            // If the use manually erases the text in the search box to nothing or there is no text in the search box, go back to the initial state
            props.resetSearch(true)
        }
    }

    let dropdownOptions = props.options.map((key, index) => {
        return <Dropdown.Item key={index} onSelect={selectedValue} className={`${key === activeState ? 'font-weight-bold' : ''}`}>{key}</Dropdown.Item>
    })

    return ( 
        <InputGroup className={`dropdown-search position-relative ${props.classes}`}>
            <Dropdown className="filter-style-dropdown" title={props.title}>
            <Dropdown.Toggle drop={"up"} className="bg-white border text-reset text-uppercase position-relative font-weight-bold rounded"><span className="mr-4 selected-text">{props.title}</span> <i className="fas fa-chevron-down position-absolute text-reset"></i></Dropdown.Toggle>
                <Dropdown.Menu>
                    {dropdownOptions}
                </Dropdown.Menu>
            </Dropdown>
            <FormControl className="border" placeholder={props.placeholder} onKeyUp={searchValue} />
            <span className="search-icon position-absolute">
                <a href="#" onClick={searchValue}>
                    <img src={SearchIcon} alt="search icon" />
                </a>
            </span>
        </InputGroup>
    );
}
 
export default DropdownSearch;