import React, {Component} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import img404 from '../../Images/img-404.svg';
import './PageNotFound.scss' 

class PageNotFound extends Component{
    render(){
        return (
            <Container className="PageNotFound-404 pt-5">
                <Row>
                    <Col className="text-center m-auto" sm={6}>
                        <div>
                            <img src={img404} alt="Page Not Found" className="img-404" />
                            <div className="text-404">
                                <h1>OOPS! </h1>
                                <p>The page you are looking for does not exist.</p>
                                <p className="pt-3"><a href="/" className="btn btn-primary btn-lg font-weight-bold px-5">Go Home</a></p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}
export default PageNotFound;