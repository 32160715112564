import { useState } from "react";

const usePagination = (itemsPerPage, data) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage - 1, data.length - 1);

  const currentItemsFrom = startIndex + 1;
  const currentItemsTo = endIndex + 1;

  const currentData = data.slice(startIndex, endIndex + 1);

  const isNextDisabled = currentPage === totalPages || totalPages === 0;
  const isPrevDisabled = currentPage === 1;

  return {
    currentPage,
    nextPage,
    prevPage,
    currentItemsFrom,
    currentItemsTo,
    currentData,
    isNextDisabled,
    isPrevDisabled,
  };
};

export default usePagination;
