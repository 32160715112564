import React, { useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, Col, Form, Row } from "react-bootstrap";

import * as actionTypes from "../../Store/Actions/Billing";
import usePagination from "../../Utils/Hooks/usePagination";

const defaultData = [];

const CascadeByPassByID = () => {
  const dispatch = useDispatch();
  const dataByPartnerId =
    useSelector((state) => state.billing.billingJsonByPartnertId) ??
    defaultData;
  const dataByPartnerIdLoading =
    useSelector((state) => state.billing.billingJsonByPartnertIdLoading) ??
    false;

  const [haveSearched, updatedHaveSearched] = useReducer(() => true, false);
  const [value, setValue] = useState("");
  const {
    currentPage,
    nextPage,
    prevPage,
    currentItemsFrom,
    currentItemsTo,
    currentData,
    isNextDisabled,
    isPrevDisabled,
  } = usePagination(10, dataByPartnerId);

  const onClick = () => {
    if (value) {
      dispatch(actionTypes.getBillingPartnersLoading(true));
      dispatch(actionTypes.getBinListJsonByPartnerId(value));
      updatedHaveSearched();
    }
  };
  const handleChange = (e) => {
    const input = e.target.value;

    // Check if the input is a valid number (using regex)
    if (/^\d*$/.test(input)) {
      setValue(input);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onClick();
    }
  };

  const classNameHeaderCol =
    "font-weight-bold text-uppercase text-small item-label d-flex align-items-center py-2";
  return (
    <div>
      <div className="d-flex flex-row mb-4">
        <Button
          variant="primary"
          className="create-btn text-capitalize font-weight-bold h-100"
          onClick={onClick}
        >
          Check Bin Bypass
        </Button>
        <Form className="px-1 w-auto flex-fill search-input">
          <Form.Control
            className="border mt-0 ml-4"
            type="text"
            defaultValue={""}
            value={value}
            placeholder="Seach by partner id"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </Form>
      </div>
      {currentItemsTo ? (
        <div className="d-flex mb-2">
          <button
            className="btn btn-outline-primary mr-2"
            disabled={isPrevDisabled}
            onClick={prevPage}
          >
            Prev
          </button>
          <button
            className="btn btn-outline-primary mr-2"
            disabled={isNextDisabled}
            onClick={nextPage}
          >
            Next
          </button>
          <div className="d-flex align-items-center">
            <div>Page {currentPage}</div>
          </div>
        </div>
      ) : null}
      <div className="bg-white shadow-sm rounded ">
        <Row className="px-3">
          <Col xs={1} className={classNameHeaderCol}>
            No
          </Col>
          <Col className={classNameHeaderCol}>Super Partner</Col>
          <Col className={classNameHeaderCol}>Partner ID</Col>
          <Col className={classNameHeaderCol}>Brand Def</Col>
          <Col className={classNameHeaderCol}>Vert Super Def</Col>
          <Col className={classNameHeaderCol}>Brand Partner</Col>
          <Col className={classNameHeaderCol}>Casc Type</Col>
          <Col className={classNameHeaderCol}>Expected Bypass Percentage</Col>
        </Row>
        {!dataByPartnerIdLoading &&
          currentData.map((d, i) => (
            <Row key={d.join("")} className="py-2 px-3">
              <Col
                xs={1}
                style={{ fontSize: "14px" }}
                className="item-name font-weight-light text-truncate align-self-center"
              >
                {currentItemsFrom + i}
              </Col>

              {d.map((i, n) => (
                <Col
                  key={n}
                  style={{ fontSize: "14px" }}
                  className="item-name font-weight-light text-truncate align-self-center"
                >
                  {String(i)}
                </Col>
              ))}
            </Row>
          ))}
        {currentData.length === 0 && !dataByPartnerIdLoading ? (
          <Row className="d-flex text-sm justify-content-center py-2 px-3 font-italic">
            <div>
              {haveSearched
                ? `No results found. Please try a different search.`
                : `Enter a search query and click the Check Bin Bypass button.`}
            </div>
          </Row>
        ) : null}
        {dataByPartnerIdLoading ? (
          <Row className="d-flex text-sm justify-content-center py-2 px-3 font-italic">
            <div>Loading...</div>
          </Row>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CascadeByPassByID;
