import React from 'react';

import {Transition} from 'react-transition-group';

const StateTranslateOpacityAnimation = (props) => {
    const defaultStyle = {
        transition: `all .3s ease`,
        opacity: 0,
        transform: `translateY(-30%)`
    }
      
    const transitionStyles = {
        entering: { transition: `all .3s ease`, opacity: 1, transform: `translateY(0%)` },
        entered:  { transition: `all .3s ease`, opacity: 1, transform: `translateY(0%)` },
        exiting:  { transition: `all .3s ease`, opacity: 1, transform: `translateY(0%)` },
        exited:  { transition: `all .3s ease`, opacity: 0, transform: `translateY(-15%)` },
    }

    return ( 
        <Transition in={props.trigger} timeout={300}>
            {state => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }}>
                    {props.children}
                </div>
            )}
        </Transition>
    );
}
 
export default StateTranslateOpacityAnimation;