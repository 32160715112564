import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./AdIDManagerPreview.scss";

const AdIDManagerPreview = (props) => {
  // State to set which page is selected
  const [currentPage, setCurrentPage] = useState("welcome");
  // State to set mobile/desktop view
  const [isMobile, setIsMobile] = useState(false);

  // AdIDManager Redux Store states stored in variables
  let selectedAdid = useSelector((state) => state.adidManager.selectedAdid);
  let verticalType = useSelector((state) => state.adidManager.verticalType);
  let brandUrl = useSelector((state) => state.adidManager.brandUrl);
  if (brandUrl !== null) {
    brandUrl = brandUrl.replace("http://", "");
  } 

  const backButtonHandler = () => {
      props.history.push("/adid-manager-results")
  }

  // Saves state in localstorage
  const saveState = (state, name) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(name, serializedState);
    } catch (e) {
      console.log(e);
    }
  };

  // Retrieves state from localstorage
  const loadState = (name) => {
    try {
      const serializedState = localStorage.getItem(name);
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    } catch (e) {
      return undefined;
    }
  };

  useEffect(() => {
    saveState(brandUrl, "brandUrl");
    saveState(selectedAdid, "currentAdid");
    saveState(verticalType, "currentVertType");
  }, [brandUrl, selectedAdid, verticalType]);

  // When the user refreshes, we lose access to the current global
  // redux state variables and retrieve them from localStorage
  if (brandUrl === null && selectedAdid === null && verticalType === null) {
    selectedAdid = loadState("currentAdid");
    brandUrl = loadState("brandUrl");
    verticalType = loadState("currentVertType");
  }

  // array with parameters for every page changing button
    const pageOptions = ["welcome", "signup", "search"];

    // creates page changing buttons 
    let buttonPageOptions = pageOptions.map((key, index) => {
        return (
            (verticalType === "credco" &&  key !== "search") || verticalType !== "credco" ? 
                <Button
                    onClick={() => setCurrentPage(key)}
                    className={`font-weight-bold px-3 text-capitalize mx-1`}
                    variant={
                        currentPage === key ? "primary" : "outline-primary"
                    }
                    >
                    {key}
                </Button> : 
            ""
        )
    });

    // creates iframes for every page
    let iframePageOptions = pageOptions.map((key, index) => {
        return (
            <div className={currentPage === key ? "container mt-3 embed-responsive embed-responsive-16by9 iframe-container" : "hidden"}>
                <iframe
                    className="embed-responsive-item preview-iframe"
                    title={selectedAdid}
                    src={`https://${brandUrl}/${key}.html?adid=${selectedAdid}&custzip=91362`}
                ></iframe>
            </div>
        )
    });

    // array with parameters for buttons that toggle mobile/desktop view
    const viewOptions = [
        {
            name: "Desktop",
            marginClasses: "mx-1",
            setMobile: false
        },
        {
            name: "Mobile",
            marginClasses: "ml-1 mr-0",
            setMobile: true
        }
    ];

    // creates view changing buttons 
    let buttonViewOptions = viewOptions.map((key, index) => {
        return (
            <Button
                onClick={() => setIsMobile(key.setMobile)}
                className={`font-weight-bold px-3 ${key.marginClasses}`}
                variant={isMobile !== key.setMobile ? "outline-primary" : "primary"}
                >
                {key.name}
            </Button>
        )
    });

  return (
    <Container fluid className="adid-manager-preview-content position-fixed my-3 col-10 container-fluid">
        <>
            <Row className="preview-selectors bg-light w-100 align-items-center">
                <Col xs={8} className="col-8">
                    <div className="d-inline-block pr-2 mr-2 border-right border-dark">
                        <Button variant="outline-primary" 
                                onClick={backButtonHandler} 
                                className="font-weight-bold px-3 mr-1">
                                    Back to results
                        </Button>
                    </div>
                    {buttonPageOptions}
                </Col>
                <Col xs={4} className="text-right col-4">
                    {buttonViewOptions}
                </Col>
            </Row>
            <div className={isMobile ? "adid-preview w-100 align-items-center row mr-3 is-mobile" : "adid-preview align-items-center mr-4 ml-0 row"}>
                {iframePageOptions}
            </div>
        </>
    </Container>
  );
};

export default withRouter(AdIDManagerPreview);
