import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import * as disclosureActionTypes from '../../../Store/Actions/Disclosure';
import * as contentManagerActionTypes from '../../../Store/Actions/ContentManager';
import * as billingActionTypes from '../../../Store/Actions/Billing';
import * as adidManagerActionTypes from '../../../Store/Actions/AdIDManager';

import {Card, Alert, Form} from 'react-bootstrap';
import EditableHeader from './EditableHeader/EditableHeader';
import EditableBody from './EditableBody/EditableBody';
import CreateComponentButtons from '../../CreateComponents/CreateComponentButtons/CreateComponentButtons';

import './EditableCard.scss';
import EditableInput from '../../Inputs/EditableInput/EditableInput';

class EditableCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editMode: this.props.create ? true : false,
            realHeaderJson: this.props.realHeaderJson,
            headerJson: this.props.headerJson,
            bodyJson: this.props.bodyJson,
            setJson: this.props.setJson,
            transitionAnimate: false,
            saving: false,
            disclosureSaving: this.props.disclosureSaving,
            contentManagerSaving: this.props.contentManagerSaving,
            billingSaving: this.props.billingSaving,
            commentInput: '',
            ticketInput: '',
            apiError: null
        }

        this.alertRef = React.createRef()
    }
    
    componentDidUpdate(prevProps) {
        // Checks to see if the disclosureSaving prop has changed when the user creates a new Disclosure related Item
        if (this.props.disclosureSaving !== prevProps.disclosureSaving) {
            this.setState((prevProps) => {
                return {
                    ...prevProps,
                    disclosureSaving: this.props.disclosureSaving
                }
            });
        }
        
        // Checks to see if the contentManagerSaving prop has changed when the user creates a new Content Manager related Item
        if (this.props.contentManagerSaving !== prevProps.contentManagerSaving) {
            this.setState((prevProps) => {
                return {
                    ...prevProps,
                    contentManagerSaving: this.props.contentManagerSaving
                }
            })
        }
        
        // Checks to see if the billingSaving prop has changed when the user creates a new Billing related Item
        if (this.props.billingSaving !== prevProps.billingSaving) {
            this.setState((prevProps) => {
                return {
                    ...prevProps,
                    billingSaving: this.props.billingSaving
                }
            })
        }
        
        // Checks to see if the different saving states are set to true due to the user creating an item related to the HTMLTableSectionElement. If one of them is true, then it sends the user back to the main page (aka not the create page)
        if (this.state.disclosureSaving || this.state.contentManagerSaving || this.state.billingSaving) {
            this.props.history.push(this.props.mainPath);
        }
    }

    // This function checks to see if the Editable Card is in a transition state so an Animation can be triggered
    transitionHandler = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                transitionAnimate: true
            }
        });

        setTimeout(() => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    transitionAnimate: false
                }
            });
        }, 500)
    }

    // This function updates the header and body json will user changes so it can be ready if the user saves their changes
    updateJsonHandler(type, objKey, json, setJson) {
        if (type === "body") {
            if (this.props.page === "adid manager edit") {
                this.props.updateCreateStateHandler(json)
            }
            
            if (this.props.cardLabel === 'disclosure sets') {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        bodyJson: json,
                        setJson: setJson 
                    }
                })
            } else {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        bodyJson: json 
                    }
                })
            }
        } else if (type === "header") {
            this.setState(prevState => {
                let newKeyValue = {};
                newKeyValue[objKey] = json.name;

                return {
                    ...prevState,
                    headerJson: json,
                    realHeaderJson: {...prevState.realHeaderJson, ...newKeyValue}  
                }
            })
        }

        // If there is a unique error message displayed and the user goes to update a field, set the error and save states to false
        if (document.getElementsByClassName('error-state-message')[0] !== undefined) {
            this.setState((prevProps) => {
                return {
                    ...prevProps,
                    saving: false
                }
            })
            if (this.props.page === 'adid manager create') {
                this.props.onGetAdsErrorReset();
            }
        }
    }

    // This function checks if fields have a "required" attribute. If they do, it makes sure that they are filled out and not empty
    errorHandler() {
        let inputs = document.getElementsByClassName('editable-card')[0].getElementsByClassName('form-control');
        let dropdowns = document.getElementsByClassName('editable-card')[0].getElementsByClassName('dropdown-toggle');
        
        let requiredFilledOut = true;

        let compareJson, nameDuplicateError, emptyFieldError;

        // Set what the compare Json should be based on the page. This variable is used to check if a name already exists for what the user has entered.
        if (this.props.category === "disclosure") {
            compareJson = this.props.disclosures
        } else if (this.props.category === "contentManager") {
            compareJson = this.props.contentManager
        } 

        // This code checks to see if the name already exists and requires the user to create a new one. This will exclude the bin profile page as you can have more than one entry with the same bin number
        if (this.props.page !== "bin profile" && Object.keys(this.props.headerJson).length ) {
            let objKeyMatch = document.querySelectorAll('.editable-header .form-control')[0].closest('.editable-input').id;
            let formControl = document.querySelectorAll('.editable-header .form-control')[0];
            let headerValue = document.querySelectorAll('.editable-header .form-control')[0].value;

            // Check if name exists for value entered by user
            for (let i = 0; i < compareJson.length; i++) {
                if (compareJson[i][objKeyMatch] === headerValue) {
                    formControl.classList.add('error-border')
                    
                    requiredFilledOut = false
                    nameDuplicateError = true
                }
            }
        }

        // If input field value has a "required" attribute then make sure it is filled out and not empty. If it has not, then set the "requiredFilledOut" variable to false.
        Object.keys(inputs).forEach((item, index) => {
            if (inputs[item].hasAttribute("required")) {
                if (inputs[item].value === "") {
                    inputs[item].classList.add('error-border')
                    
                    requiredFilledOut = false
                    emptyFieldError = true
                }

                // if we are on cascade-bypass-edit and this is the "ticket" input
                if (this.props.page === "bin bypass" 
                    && this.props.editMode 
                    && item === '3' 
                    && inputs[item].value.length < 4
                    && document.querySelector('.ticket')?.parentNode?.children?.length < 2) {
                    const divReference = document.querySelector('.ticket');
                    const divToCreate = document.createElement('p');
                    divToCreate.innerHTML = "Please enter at least 4 characters for this field.";
                    divToCreate.style.color = "#ED4F4F"
                    divReference.parentNode.appendChild(divToCreate);
                } else if (this.props.page === "bin bypass" 
                && this.props.editMode 
                && item === '3' && inputs[item]?.value?.length >= 4 && document?.querySelector('.ticket')?.parentNode?.children?.length > 1) {
                    const divParent = document.querySelector('.ticket').parentNode
                    divParent.removeChild(divParent.lastChild)
                }
                // if we are on cascade-bypass-edit and this is the "comment" input
                if (this.props.page === "bin bypass" 
                    && this.props.editMode 
                    && item === '4' 
                    && inputs[item].value.length < 3 
                    && document.querySelector('.comment').parentNode.children.length < 2) {
                    const divReference = document.querySelector('.comment');
                    const divToCreate = document.createElement('p');
                    divToCreate.innerHTML = "Please enter at least 3 characters for this field.";
                    divToCreate.style.color = "#ED4F4F"
                    divReference.parentNode.appendChild(divToCreate);
                } else if (this.props.page === "bin bypass" 
                && this.props.editMode 
                && item === '4' && inputs[item].value.length >= 3 && document.querySelector('.comment').parentNode.children.length > 1) {
                    const divParent = document.querySelector('.comment').parentNode
                    divParent.removeChild(divParent.lastChild)
                }
                // if we are on the create bin list page and this is the ticket input
                if (this.props.page === "bin profile" 
                    && this.props.location.pathname === '/bin-list-manager-create'
                    && item === '2' 
                    && inputs[item].value.length < 4
                    && document.querySelector('.ticket')?.parentNode?.children?.length < 2) {
                    const divReference = document.querySelector('.ticket');
                    const divToCreate = document.createElement('p');
                    divToCreate.innerHTML = "Please enter at least 4 characters for this field.";
                    divToCreate.style.color = "#ED4F4F"
                    divReference.parentNode.appendChild(divToCreate);
                } else if (this.props.page === "bin profile" 
                && this.props.location.pathname === '/bin-list-manager-create'
                && item === '2' && inputs[item].value.length >= 4 && document?.querySelector('.ticket')?.parentNode?.children?.length > 1) {
                    const divParent = document.querySelector('.ticket').parentNode
                    divParent.removeChild(divParent.lastChild)
                }
                // if we are on the create bin list page and this is the bin desciption input
                if (this.props.page === "bin profile" 
                    && this.props.location.pathname === '/bin-list-manager-create'
                    && item === '1' 
                    && inputs[item].value.length < 3
                    && document.querySelector('.description')?.parentNode?.children?.length < 2) {
                    const divReference = document.querySelector('.description');
                    const divToCreate = document.createElement('p');
                    divToCreate.innerHTML = "Please enter at least 3 characters for this field.";
                    divToCreate.style.color = "#ED4F4F"
                    divReference.parentNode.appendChild(divToCreate);
                } else if (this.props.page === "bin profile" 
                && this.props.location.pathname === '/bin-list-manager-create'
                && item === '1' && inputs[item].value.length >= 3 && document?.querySelector('.description')?.parentNode?.children?.length > 1) {
                    const divParent = document.querySelector('.description').parentNode
                    divParent.removeChild(divParent.lastChild)
                }
            }
        })

        // If dropdown field has a "required" attribute then make sure an item in the dropdown has been selected. If it has not, then set the "requiredFilledOut" variable to false.
        Object.keys(dropdowns).forEach((item, index) => {
            if (dropdowns[item].hasAttribute("required")) {
                if (dropdowns[item].querySelector('.selected-text').textContent === "") {
                    dropdowns[item].classList.add('error-border')

                    requiredFilledOut = false
                    emptyFieldError = true
                }
            }
        })
    
        // If a text editor is present and is required then make sure the text for it isn't blank
        if (this.props.bodyContent === "text editor") {
            if (this.props.requiredFields.includes(Object.keys(this.props.bodyJson)[0]) && (this.props.bodyJson[Object.keys(this.props.bodyJson)[0]] === "" || this.props.bodyJson[Object.keys(this.props.bodyJson)[0]] === "<p></p>")) {
                document.getElementsByClassName('rdw-editor-toolbar')[0].classList.add('error-border')
                document.getElementsByClassName('edit-section')[0].classList.add('error-border')

                requiredFilledOut = false
                emptyFieldError = true
            }
        }

        // If the "requiredFilledOut" variable is set to false, then add the "error-border" class to the field to let the user know this field is required to be filled out. Otherwise, do nothing.
        if (requiredFilledOut) {
            let errorBorders = document.querySelectorAll('.error-border');

            if (this.props.create) {
                document.getElementsByClassName('text-danger')[0].classList.add('d-none')
            } else {
                document.getElementsByClassName('card-edit-mode')[0]?.getElementsByClassName('text-danger')[0].classList.add('d-none')
            }
            
            Object.keys(errorBorders).forEach((item, index) => {
                errorBorders[item].classList.remove('error-border');
            });

            return requiredFilledOut
        } else {
            // If name duplicate error and an empty field error exist, use this error message
            if (nameDuplicateError && emptyFieldError) {
                document.getElementsByClassName('text-danger')[0].textContent = "That name already exists. Please enter a new name and fill out the required fields highlighted in red!"
            } 
            // If only name duplicate error exist, use this error message 
            else if (nameDuplicateError) {
                document.getElementsByClassName('text-danger')[0].textContent = "That name already exists. Please enter a new name!"
            } 
            // If it's just an emtpty field error, use this error message
            else {
                document.getElementsByClassName('text-danger')[0].textContent = "These fields are required."
            }
            
            document.getElementsByClassName('text-danger')[0].classList.remove('d-none')
            
            return requiredFilledOut
        }
    }

    // This function sets the saving state variable to true to let the app know an item has been created and is saving
    savingCreateHandler = () => {
        if (this.errorHandler()) {
            this.setState((prevProps) => {
                return {
                    ...prevProps,
                    saving: true
                }
            })
        }
    }

    // This function handles when the Editable Card is being saved from either an edit state or create state.
    handleEditMode = (mode) => {
        this.setState({
            editMode: !mode
        })
        let individualJson = {...this.state.realHeaderJson, ...this.state.bodyJson};

        let user = this.props.firstName.toLowerCase() + this.props.lastName.substring(0, 1).toLowerCase();
        if (mode && !this.props.create) {
            //If the user is in edit mode send the updated JSON in the function to make the necessary AJAX calls
            if (this.props.category === 'disclosure') {
                this.props.onUpdateCreateDisclosureJson(this.props.endpoint, individualJson, "update")
            } else if (this.props.category === 'contentManager') {
                this.props.onUpdateCreateContentManagerJson(this.props.endpoint, individualJson, "update")
            } else if (this.props.category === 'billing') {
                if (this.props.page === "bin profile") {
                    individualJson["userUpdated"] = user;
                    individualJson["reasonBinCreated"] = `${this.state?.ticketInput} - ${this.state?.commentInput}`;
                    this.props.onUpdateBinListJson(individualJson)
                } else if (this.props.page === "bin bypass") {
                    individualJson["userUpdated"] = user.replace(' ', '');
                    this.props.onUpdateCascadeBypass(this.props.location.state.id, individualJson)
                    setTimeout(() => {
                        if (this.props.success) {
                            this.props.history.push({
                                pathname: '/cascade-bypass',
                                state: {defaultSearch: individualJson?.partnerId?.toString()}
                            })
                        } else {
                            setTimeout(() => {
                                if (this.props.success) {
                                    this.props.history.push({
                                        pathname: '/cascade-bypass',
                                        state: {defaultSearch: individualJson?.partnerId?.toString()}
                                    })
                                } else {
                                    if (individualJson?.ticket && individualJson?.ticket?.length >= 4 && individualJson?.comment && individualJson?.comment?.length >= 3) {
                                        this.setState((prevProps) => {
                                            return {
                                                ...prevProps,
                                                apiError: 'Failed to update'
                                            }
                                        })
                                        setTimeout(() => {
                                            this.setState((prevProps) => {
                                                return {
                                                    ...prevProps,
                                                    apiError: null
                                                }
                                            })
                                        }, 4000)
                                    }
                                }
                            }, 1000)
                        }
                    }, 4000)
                }
            }
        } else if(this.props.create && this.errorHandler()) {
            // If the user is in create mode send the updated JSON in the function to make the necessary AJAX calls
            if (this.props.category === 'disclosure') {
                this.props.onUpdateCreateDisclosureJson(this.props.endpoint, individualJson, "create")
            } else if (this.props.category === 'contentManager') {
                this.props.onUpdateCreateContentManagerJson(this.props.endpoint, individualJson, "create")
            } else if (this.props.category === 'billing') {
                individualJson["userCreated"] = user;
                if (this.props.page === "bin profile") {
                    this.props.onCreateBinListJson(individualJson)
                } else if (this.props.page === "bin bypass") {
                    if (!individualJson.value) individualJson.value = 0; 
                    this.props.onCreateCascadeBypass(individualJson)
                    setTimeout(() => {
                        if (this.props.success) {
                            this.props.history.push({
                                pathname: '/cascade-bypass',
                                state: {defaultSearch: individualJson?.partnerId?.toString()}
                            })
                        } else {
                            setTimeout(() => {
                                if (this.props.success) {
                                    this.props.history.push({
                                        pathname: '/cascade-bypass',
                                        state: {defaultSearch: individualJson?.partnerId?.toString()}
                                    })
                                } else {
                                    this.setState((prevProps) => {
                                        return {
                                            ...prevProps,
                                            apiError: 'Failed to create'
                                        }
                                    })
                                    setTimeout(() => {
                                        this.setState((prevProps) => {
                                            return {
                                                ...prevProps,
                                                apiError: null
                                            }
                                        })
                                    }, 4000)
                                }
                            }, 1000)
                        }
                    }, 4000)
                }
            } else if (this.props.category === "adidManager") {
                individualJson["newLastModifyingUser"] = user;
                
                if (this.props.create && this.props.createEdit) {
                    this.props.customSaveHandler(individualJson)

                    setTimeout(() => {
                        this.setState((prevProps) => {
                            return {
                                ...prevProps,
                                saving: false
                            }
                        })
                    }, 500)
                } else {
                    if (individualJson.name === "") {
                        individualJson.name = document.getElementsByClassName('name')[0].getElementsByClassName('form-control')[0].value
                    } else if (individualJson.partnerName === "") {
                        individualJson.partnerName = document.getElementsByClassName('partnerName')[0].getElementsByClassName('form-control')[0].value
                    }
                    
                    this.props.onSetCreateCloneEndpointState(this.props.createType, this.props.createCategory, individualJson);
                }
            }
        } 
    }

    render() { 
        // If the Editable Card is in create mode, then use this Footer Component that contains the Cancel/Create buttons
        let cardFooter = (
            <Card.Footer className={`bg-white border-0 pt-0 pb-4}`}>
                <p className={`text-danger d-none text-center font-weight-bold ${!this.props.create ? 'pb-4' : ''}`}></p>
                {this.props.errorState ?  <p className={`text-danger text-center font-weight-bold error-state-message`}>{this.props.errorMessage}</p> : ''}
                {this.props.create || this.props.editMode && this.props.page === "bin bypass" ? <CreateComponentButtons disableEditing={this.state.saving} error={this.props.errorState} saving={this.state.saving} savingCreateHandler={this.handleEditMode} {...this.props} editHandler={this.handleEditMode} transitionHandler={this.transitionHandler} transitionAnimate={this.state.transitionAnimate} /> : ""}
            </Card.Footer>
        )

        return ( 
            <Card className={`editable-card border-0 ${this.props.cardMargin !== undefined ? this.props.cardMargin : 'mb-4'} ${this.state.editMode ? 'card-edit-mode' : ''}`}>
                <div ref={this.alertRef} className={this.props.success || this.state.apiError ? "pt-3 mx-3" : "d-none"}>
                    <Alert variant={this.props.success ? "success" : "danger"} className="text-center">
                        {this.props.success ? this.props.success : this.state.apiError}
                    </Alert>
                </div>
                {Object.keys(this.props.headerJson).length > 0 ? <Card.Header className={`bg-white border-0 ${this.state.editMode ? 'pb-0' : ''}`}>
                    <EditableHeader transitionHandler={this.transitionHandler} transitionAnimate={this.state.transitionAnimate} updateHandler={this.updateJsonHandler.bind(this)} editMode={this.state.editMode} editHandler={this.handleEditMode} {...this.props} />
                </Card.Header> : !this.props.cardLabelOnly ? <span className="pt-4"></span> : ''}
                {this.props.cardLabelOnly ? 
                    <Card.Header className="bg-white border-0 pb-0">
                        <div className="editable-header">
                            <p className="font-weight-bold text-muted text-uppercase small-heading mt-3">{this.props.cardLabel}</p>
                        </div>
                    </Card.Header>
                : ''}
                <Card.Body className="pt-0">
                    <EditableBody disableEditing={
                        this.state.editMode && 
                        this.props.page === "bin profile" &&
                        this.props.location.pathname === "/bin-list-manager-results" &&
                        (this.state?.commentInput?.length < 3 || this.state?.ticketInput?.length < 4)
                        } transitionHandler={this.transitionHandler} transitionAnimate={this.state.transitionAnimate} updateHandler={this.updateJsonHandler.bind(this)} editMode={this.state.editMode} editHandler={this.handleEditMode} {...this.props} />
                </Card.Body>
                {cardFooter}
                {this.state.editMode && this.props.page === "bin profile" && this.props.location.pathname === "/bin-list-manager-results" &&
                    <div className="p-3 py-4 bg-light w-100" xs={4}>
                        <div className='d-flex justify-content-start justify-content-xl-center py-2'>
                            <Form className="d-flex">
                                <Form.Group controlId="card-name" className={`d-flex mb-0 mr-5`}>
                                    <Form.Label className="font-weight-bold text-uppercase mr-3 my-auto">Comment</Form.Label>
                                    <Form.Control onChange={(e) => this.setState((prevState) => {
                                        return {
                                            ...prevState,
                                            commentInput: e.target.value
                                        }
                                    })} placeholder={"Reason for change"} className="font-weight-light border p-3" />
                                </Form.Group>
                                <Form.Group controlId="card-name" className={`d-flex mb-0`}>
                                    <Form.Label className="font-weight-bold text-uppercase mr-3 my-auto">Ticket</Form.Label>
                                    <Form.Control onChange={(e) => this.setState((prevState) => {
                                        return {
                                            ...prevState,
                                            ticketInput: e.target.value
                                        }
                                    })} placeholder={"Ticket Number"} className="font-weight-light border p-3" />
                                </Form.Group>
                            </Form>
                        </div>
                    </div>}
            </Card>
         );
    }

    executeScroll = () => this.myRef.current.scrollIntoView()
}

const mapStateToProps = (state) => {
    return {
        disclosures: state.disclosure.disclosures,
        disclosureSaving: state.disclosure.saving,
        contentHeading: state.contentManager.contentHeadingJson,
        contentManagerSaving: state.contentManager.saving,
        billingSaving: state.billing.saving,
        firstName: state.login.userFirstName,
        lastName: state.login.userLastName
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCreateDisclosureJson: (query, json, method) => dispatch(disclosureActionTypes.initUpdateCreateDisclosureJson(query, json, method)),
        onInitGetContentManagerJson: (query,json) => dispatch(contentManagerActionTypes.initGetContentManagerJson(query, json)),
        onInitCreateDisclosureJson: (query,json) => dispatch(disclosureActionTypes.initCreateDisclosureJson(query, json)),
        onUpdateCreateContentManagerJson: (query,json, method) => dispatch(contentManagerActionTypes.initUpdateCreateContentManagerJson(query, json, method)),
        onUpdateBinListJson: (json) => dispatch(billingActionTypes.initUpdateBinListJson(json)),
        onCreateBinListJson: (json) => dispatch(billingActionTypes.initCreateBinListJson(json)),
        onCreateCascadeBypass: (json) => dispatch(billingActionTypes.createCascadeBypass(json)),
        onUpdateCascadeBypass: (id, json) => dispatch(billingActionTypes.updateCascadeBypass(id, json)),
        onSetCreateCloneEndpointState: (type, category, json) => dispatch(adidManagerActionTypes.setCreateCloneEndpointState(type, category, json)),
        onGetAdsErrorReset: () => dispatch(adidManagerActionTypes.getAdsErrorReset())
    }
}
 
export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(EditableCard));