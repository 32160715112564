import React from 'react';

const ColumnLabels = (props) => {
    let labels = props.labels.map((key, index) => {
        return <p key={`${key}-${index}`} className={`width-${key.width} font-weight-bold d-inline-block ${key.padding} position-relative`}>{key.name}</p>
    });

    return ( 
        <>
            {labels}
        </>
    );
}
 
export default ColumnLabels;