import React, {Component} from 'react';

import {Button} from 'react-bootstrap';

import imageEdit from '../../../Images/img-edit.svg';

import './EditSaveButton.scss';

class EditSaveButton extends Component {
    state = {  }
    nextPath = () => {
        let json = this.props.bodyJson
        this.props.history.push({
            pathname: this.props.editPath,
            state: json
        });
    }
    onClick = (event) => {
        if (this.props.editPath) return this.nextPath();
        if (this.props.transitionHandler) {
            this.props.transitionHandler(); 
            this.props.editHandler(this.props.editMode, event);
            return
        }
        this.props.editHandler(this.props.editMode, event)
    }
    render() { 
        let editButton;

        // If the card/element is not in create mode then it shows the edit/save Button. Otherwise it will not be shown
        if (!this.props.create) {
            editButton = (
                <Button style={this.props.editMode && this.props.page === "bin profile" ? {top: 108} : {}} className={`position-absolute edit-save-btn bg-primary rounded-pill ${this.props.editMode ? 'save-btn' : 'edit-btn'} ${this.props.disableEditing !== undefined && this.props.disableEditing ? 'disabled-btn' : ''}`} onClick={this.onClick} disabled={this.props.disableEditing !== undefined ? this.props.disableEditing : ''}>
                    <span className="save-text text-white font-weight-bold position-absolute">{this.props.editMode ? "Save" : "Edit"}</span> <img className="position-absolute edit-icon" src={imageEdit} alt="Edit/Save Button" />
                </Button>
            )
        } else {
            editButton = null;
        }
        return ( 
            <>
                {editButton}
            </>
        );
    }
}
 
export default EditSaveButton;