import React from 'react';

import {Dropdown} from 'react-bootstrap';

import '../FilterStyleDropdown.scss';
import './ResultsDropdown.scss';

const ResultsDropdown = (props) => {
    // Sets the dropdown value to be the selected dropdown option and then sends the new value to the update handler
    let selectedValue = (eventkey, event) => {
        let newValue = event.target.textContent;
        let selectedTextSpan = event.target.closest('.dropdown').getElementsByClassName('selected-text')[0];

        selectedTextSpan.textContent = newValue;

        props.updateSelected(newValue)
    }

    let options = props.allOptions.map((key, index) => {
        return (
            <Dropdown.Item key={`${index}_${key}`} onSelect={selectedValue}>{key}</Dropdown.Item>
        )
    });
    
    return ( 
        <div className="d-flex align-items-center results-dropdown">
            <p className="text-uppercase font-weight-bold text-small mb-0 mr-3">{props.descriptionText}</p>
            <Dropdown className="filter-style-dropdown">
                <Dropdown.Toggle drop={"up"} className="bg-white border text-reset text-uppercase position-relative font-weight-bold rounded text-small"><span className="mr-4 selected-text">{props.defaultDisplay}</span> <i className="fas fa-chevron-down position-absolute text-reset"></i></Dropdown.Toggle>
                <Dropdown.Menu className="text-small">
                    {options}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}
 
export default ResultsDropdown;