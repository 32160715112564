import React from 'react'
import { Button } from 'react-bootstrap'

const MovePageButtons = ({pageNum, billing, movePage}) => {

  return (
    <div className="d-flex align-items-center">
      <p className='mr-2'>Page {pageNum}:</p>
      {pageNum > 1 && 
          <Button variant="outline-primary" className="mr-3 mb-3" onClick={() => movePage('prev')}>
              Previous
          </Button>
      }
      {pageNum < Math.ceil(billing?.length / 50) && 
          <Button variant="outline-primary" className="mb-3" onClick={() => movePage('next')}>
              Next
          </Button>
      }
    </div>
  )
}

export default MovePageButtons