"use client";

import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition, Disclosure } from "@headlessui/react";
import { ChevronRightIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Bars3Icon } from "@heroicons/react/24/outline";

import UserMenu from "../UserMenu/UserMenu";
import Logo from "../../../Images/img-logo.svg";

// import "./SidebarNavigation.scss";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SidebarNavigation = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  // This function sets the active state for the main menu navigation item and shows the collapsable elements for the active section and which one is selected
  //   useEffect(() => {
  //     if (
  //       document.querySelector(".current-page") !== null &&
  //       document.querySelector(".current-page").closest(".accordion") !== null
  //     ) {
  //       document
  //         .querySelector(".current-page")
  //         .closest(".accordion")
  //         .firstChild.click();
  //     } else if (document.querySelector(".current-page") !== null) {
  //       document.querySelector(".current-page").classList.add("bg-primary");
  //     }
  //   }, []);

  // const sidebarNavItems = () => {
  //   return NavigationJson.map((key, index) => {
  //     return (
  //       <NavigationItems
  //         key={`${key.menuItem.item}-${index}`}
  //         navItem={key}
  //         navIndex={index + 1}
  //       />
  //     );
  //   });
  // };

  return (
    <div>
      <MobileSidebar open={mobileSidebarOpen} setOpen={setMobileSidebarOpen} />
      <div className="flex">
        <input
          type="checkbox"
          id="drawer-toggle"
          className="peer sr-only relative"
          checked={sidebarOpen}
          onChange={() => setSidebarOpen(!sidebarOpen)}
        />
        <div className="relative left-0 top-0 hidden h-full -translate-x-[200px] transform bg-white shadow-lg transition-all duration-300 peer-checked:translate-x-0 lg:fixed lg:inset-y-0 lg:z-40 lg:flex lg:w-72 lg:flex-col">
          <label
            htmlFor="drawer-toggle"
            className="absolute right-0 top-20 z-50 -mr-3 inline-block size-3 cursor-pointer rounded-full bg-blue-600 p-[18px] transition-all duration-300"
          >
            <ChevronRightIcon
              className={classNames(
                sidebarOpen ? "rotate-180" : "rotate-0",
                "absolute left-[5px] top-[3px] h-7 w-7 text-white"
              )}
              aria-hidden="true"
            />
          </label>
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-slate-900 px-6 ">
            {sidebarOpen ? <SidebarContent /> : null}
          </div>
        </div>
      </div>

      <div className="sticky top-0 z-40 flex items-center gap-x-6  px-4 py-4 sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setMobileSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
          Mission Control
        </div>
        <a href="#">
          <span className="sr-only">Your profile</span>
          <span className="h-8 w-8 rounded-full bg-gray-50" />
        </a>
      </div>
    </div>
    // <Nav variant="pills" className="sidebar-navigation py-4 mt-3 px-0 flex-column w-full" fill>
    //     {sidebarNavItems()}
    // </Nav>
  );
};

const SidebarContent = () => {
  const oldMissionControlUrl = "https://mission-control.nationsinfocorp.com/";
  const newMissionControlUrl = "https://mission-control-web.nationsinfocorp.com/";
  console.log({ env: process.env });
  const navigation = [
    {
      name: "Sale Site",
      href: "#",
      current: true,
      children: [
        { name: "Super Partner", href: "#", current: false },
        { name: "Partner", href: "#", current: false },
        { name: "ADID", href: "#", current: false },
        {
          name: "Split Test",
          href: "#",
          current: false,
          children: [
            {
              name: "Tag",
              href: `${newMissionControlUrl}split-test/tag`,
              current: false,
            },
            { name: "ADID", href: "#", current: false },
          ],
        },
        { name: "UCT / TCT", href: "#", current: false },
      ],
    },
    {
      name: "Fbflow Manager",
      href: `${newMissionControlUrl}fbflow`,
      current: false,
    },
    {
      name: "Disclosure",
      current: false,
      children: [
        {
          name: "Terms",
          href: `${oldMissionControlUrl}disclosure-terms`,
          current: false,
        },
        {
          name: "Buttons",
          href: `${oldMissionControlUrl}disclosure-buttons`,
          current: false,
        },
        {
          name: "Disclosure Sets",
          href: `${oldMissionControlUrl}disclosure-sets`,
          current: false,
        },
      ],
    },
    {
      name: "Content Manager",
      href: "#",
      current: false,
      children: [
        {
          name: "Content Headings",
          href: `${oldMissionControlUrl}content-manager-content-headings`,
          current: false,
        },
      ],
    },
    {
      name: "Billing",
      current: false,
      children: [
        {
          name: "Bin List Manager",
          href: `${oldMissionControlUrl}bin-list-manager`,
          current: false,
        },
        {
          name: "Cascade Bypass",
          href: `${oldMissionControlUrl}cascade-bypass`,
          current: false,
          children: null, // putting null here for the sake of fixing subItem.children.map error
        },
      ],
    },
    {
      name: "AdID Manager",
      current: false,
      href: `${oldMissionControlUrl}adid-manager-results`,
    },
  ];

  return (
    <>
      <div className="flex h-24 shrink-0 items-center border-b border-gray-400">
        <img className="h-8 w-auto" src={Logo} alt="Mission Control" />
      </div>
      <nav className="mt-10 flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  {!item.children ? (
                    <a
                      href={item.href}
                      className={classNames(
                        item.current ? "bg-blue-600" : "hover:bg-blue-900",
                        "!no-underline block rounded-md py-2 pl-10 pr-2 text-sm font-semibold leading-6 text-white"
                      )}
                    >
                      {item.name}
                    </a>
                  ) : (
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={classNames(
                              item.current
                                ? "bg-blue-600"
                                : "hover:bg-blue-900 hover:no-underline",
                              "flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-white"
                            )}
                          >
                            <ChevronRightIcon
                              className={classNames(
                                open ? "rotate-90" : "",
                                "h-5 w-5 shrink-0 text-white"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Disclosure.Button>
                          <Disclosure.Panel as="ul" className="mt-1 px-2">
                            {item.children.map((subItem) => (
                              <li key={subItem.name}>
                                {!subItem.children ? (
                                  <a
                                    href={subItem.href}
                                    className={classNames(
                                      subItem.current
                                        ? "bg-blue-600"
                                        : "hover:bg-blue-900",
                                      "!no-underline block rounded-md py-2 pl-10 pr-2 text-sm font-semibold leading-6 text-white"
                                    )}
                                  >
                                    {subItem.name}
                                  </a>
                                ) : (
                                  <Disclosure as="div">
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          className={classNames(
                                            subItem.current
                                              ? "bg-blue-600"
                                              : "hover:bg-blue-900 hover:no-underline",
                                            "flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-white"
                                          )}
                                        >
                                          <ChevronRightIcon
                                            className={classNames(
                                              open ? "rotate-90" : "",
                                              "h-5 w-5 shrink-0 text-white"
                                            )}
                                            aria-hidden="true"
                                          />
                                          {subItem.name}
                                        </Disclosure.Button>
                                        <Disclosure.Panel
                                          as="ul"
                                          className="mt-1 px-2"
                                        >
                                          {subItem.children.map(
                                            (subSubItem) => (
                                              <li key={subSubItem.name}>
                                                <Disclosure.Button
                                                  as="a"
                                                  href={subSubItem.href}
                                                  className={classNames(
                                                    subSubItem.current
                                                      ? "bg-blue-600"
                                                      : "hover:bg-blue-900",
                                                    "!no-underline block rounded-md py-2 pl-9 pr-2 text-sm leading-6 text-white"
                                                  )}
                                                >
                                                  {subSubItem.name}
                                                </Disclosure.Button>
                                              </li>
                                            )
                                          )}
                                        </Disclosure.Panel>
                                      </>
                                    )}
                                  </Disclosure>
                                )}
                              </li>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )}
                </li>
              ))}
              <li className="block lg:hidden border-t border-slate-500">
                <UserMenu />
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
};

const MobileSidebar = ({ open, setOpen }) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:hidden" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-slate-900 px-6">
                <SidebarContent />
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SidebarNavigation;
