import React, {Component} from 'react';

import { EditorState, RichUtils } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import VariableDropdown from './CustomToolbarOptions/VariableDropdown/VariableDropdown';
import Badge from './CustomToolbarOptions/Badge/Badge';

import EditorBold from '../../Images/img-editor-bold.svg';
import EditorItalic from '../../Images/img-editor-italic.svg';
import EditorUnderline from '../../Images/img-editor-underline.svg';
import EditorLink from '../../Images/img-editor-link.svg';
import EditorUndo from '../../Images/img-editor-undo.svg';


import './TextEditor.scss';

class TextEditor extends Component {
    constructor (props) {
        super(props)
        this.state = {
            editorState: EditorState.createEmpty()
        }
    }

    // This function mathces the key in the key/value pair for the mapObj variable and then changes the value to the correct one
    replaceAll(str, mapObj, componentDidMount){
        let re;
        let newArray = [];
        
        if (componentDidMount) {
            for (const key in mapObj) {
                newArray.push("\\" + key)
            }
        } else {
            for (const key in mapObj) {
                if (key === "[(xxx) xxx-xxxx]") {
                    let newKey = "\\[\\(\\xxx\\)\\ xxx-xxxx]"
                    newArray.push(newKey)
                } else {
                    newArray.push("\\" + key)
                }
            }
        }

        re = new RegExp(newArray.join("|"),"gi");
    
        return str.replace(re, function(matched){
            return mapObj[matched];
        });
    }

    onChange = editorState => {
		this.setState({
			editorState
		});
    };

    // This function sets the content displayed in the Text Editor on load if there is any
    componentDidMount() {
        const html = this.props.bodyJson[Object.keys(this.props.bodyJson)[0]];

        let mapObj = {
            "${visitorinfo.sessionAd.offer.displayTrialDuration}": "[trialDuration]",
            "${visitorinfo.sessionAd.offer.displayTrialAmount}": "[trialAmount]",
            "${visitorinfo.sessionAd.offer.displayOfferAmount}": "[offerAmount]",
            "${brandinfo.brandPhone}": "[brandPhone]",            
            "${visitorinfo.sessionAd.offer.displayTrialEndDate}": "[trialEndDate]",
            "${brandinfo.brandEmail}": "[supportEmail]"
        }

        // // Locates if the text editor text contains one of the keys in the mapObj variable and replaces it with the correct value in that object
        let updatedHtml = this.replaceAll(html, mapObj, true);

        // Updates the payment frequency variable to the correct display value
        let payFreqUpdateHtml = updatedHtml.replace(/\${visitorinfo.sessionAd.offer.paymentFrequency == '1m' \? 'per month' : visitorinfo.sessionAd.offer.paymentFrequency == '1w' \? 'per week' : visitorinfo.sessionAd.offer.displayPaymentFrequency}/gi, "[paymentFrequency]");

        // Convert variable text to an entity so when the backspace/delete button is pressed it will delete the entire variable
        let contentSt = convertFromHTML({
            // Converts span tag to look like a badge in text editor
            htmlToStyle: (nodeName, node, currentStyle) => {
                if (nodeName === 'span') {
                    return currentStyle.add('BADGE');
                } else { 
                    return currentStyle;
                }
            },
            // Converts span tag to mutable object to make sure variables and text in span tag stay in same badge UI on load
            htmlToEntity: (nodeName, node, createEntity) => {
                if (nodeName === 'span' && node.classList.contains('label', 'label-primary')) {
                    return createEntity(
                        'SPAN',
                        'MUTABLE',
                        new Date().getTime()
                    )
                } else if (nodeName === 'a') {
                    return createEntity(
                        'LINK',
                        'MUTABLE',
                        {url: node.href, targetOption: node.target}
                    )
                }
            },
            // Converts thymeleaf variables designated in the mapObj variable above into human readable content in the text editor
            textToEntity: (text, createEntity) => {
                const result = [];

                let re;
                let newArray = [];
                
                for (const key in mapObj) {
                    newArray.push("\\" + mapObj[key])
                }

                newArray.push("\\" + "[paymentFrequency]")

                re = new RegExp(newArray.join("|"),"gi");

                text.replace(re, (match, name, offset) => {
                    const entityKey = createEntity(
                        'VARIABLE',
                        'IMMUTABLE',
                        new Date().getTime()
                    );
                    result.push({
                        entity: entityKey,
                        offset: name,
                        length: match.length,
                        result: match
                    });
                });
                return result;
            }
        })(payFreqUpdateHtml)

        const contentBlock = htmlToDraft(payFreqUpdateHtml);

        if (contentBlock) {
            const editorState = EditorState.createWithContent(contentSt);
            this.setState({
                editorState
            })
        }
    }

    onEditorStateChange = (editorState) => {
        let content = convertToHTML({
            styleToHTML: (style) => {
                if (style === 'BADGE') {
                  return <span class="label label-primary" />
                }
            },
            entityToHTML: (entity, originalText) => {
                if (entity.type === 'LINK') {
                  return <a href={entity.data.url} target={entity.data.targetOption}>{originalText}</a>;
                }
                return originalText;
            }
        })(editorState.getCurrentContent());

        // Check if the text editor is in a create page and check to see if it has error-border class. If it does and text has been entered into the editor then remove that class
        if (this.props.create && document.getElementsByClassName('rdw-editor-toolbar')[0].classList.contains('error-border') && document.getElementsByClassName('edit-section')[0].classList.contains('error-border') && editorState.getCurrentContent().hasText()) {
            document.getElementsByClassName('rdw-editor-toolbar')[0].classList.remove('error-border')
            document.getElementsByClassName('edit-section')[0].classList.remove('error-border')
        }

        let mapObj = {
            "[trialDuration]": "${visitorinfo.sessionAd.offer.displayTrialDuration}",
            "[trialAmount]": "${visitorinfo.sessionAd.offer.displayTrialAmount}",
            "[offerAmount]": "${visitorinfo.sessionAd.offer.displayOfferAmount}",
            "[paymentFrequency]": "${visitorinfo.sessionAd.offer.paymentFrequency == '1m' ? 'per month' : visitorinfo.sessionAd.offer.paymentFrequency == '1w' ? 'per week' : visitorinfo.sessionAd.offer.displayPaymentFrequency}",
            "[brandPhone]": "${brandinfo.brandPhone}",
            "[trialEndDate]": "${visitorinfo.sessionAd.offer.displayTrialEndDate}",
            "[supportEmail]": "${brandinfo.brandEmail}"
        }

        let updatedContent = this.replaceAll(content,mapObj).replace(/(\r\n|\n|\r)/gm,"").replace(/"/g, "'");
        let newString;

        // Prevent putting variables in a separate span due to it being its own entity
        if (updatedContent.includes("}</span><span class='label label-primary'>") || updatedContent.includes("</span><span class='label label-primary'>${")) {
            newString = updatedContent.replace(/}<\/span><span class='label label-primary'>/gi, "}").replace(/<\/span><span class='label label-primary'>\${/gi, "${")
        }

        if (newString !== undefined) {
            updatedContent = newString
        }
        
        this.props.bodyJson[Object.keys(this.props.bodyJson)[0]] = updatedContent;

        this.props.updateHandler("body", Object.keys(this.props.bodyJson), this.props.bodyJson);

        this.setState({
			editorState
        });
    };
    
    toggleBadgeState = () => {
        // Toggles inline style for BADGE style
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, "BADGE"));
    }

    render() {
        const { editorState } = this.state;

        const styleMap = {
            'BADGE': {
                color: '#fff',
                backgroundColor: '#444CF8',
                display: 'inline-block',
                padding: '0.25em 0.4em',
                fontWeight: '700',
                lineHeight: '1',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                verticalAlign: 'baseline',
                borderRadius: '0.25rem',
                transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            }
        }; 

        document.querySelectorAll('.rdw-option-wrapper[title="Link"]').forEach(item => {
            item.addEventListener('click', (event) => {
                setTimeout(() => {
                    let linkModal = document.getElementsByClassName('rdw-link-modal')[0];

                    if (linkModal !== undefined) {
                        let linkTarget = document.getElementById('linkTarget');

                        if (document.getElementsByClassName('link-example')[0] === undefined) {
                            linkTarget.insertAdjacentHTML("afterend", '<p class="link-example"><strong>Example Link Target:</strong> For our "Contact Us" page ONLY enter "/contact-us.html"</p>')
                        }
                    }
                }, 10)
            })
        })

        return (
            <>
                <Editor
                    editorState={editorState}
                    wrapperClassName={this.props.editMode ? "text-editor edit-mode" : "text-editor"}
                    editorClassName="edit-section"
                    onEditorStateChange={this.onEditorStateChange}
                    readOnly={!this.props.editMode}
                    toolbar={{
                        options: ['inline', 'link', 'history'],
                        inline: {
                            options: ['bold', 'italic', 'underline'],
                            bold: {icon: EditorBold},
                            italic: {icon: EditorItalic},
                            underline: {icon: EditorUnderline}
                        },
                        link: {
                            options: ['link'],
                            link: {icon: EditorLink}
                        },
                        history: {
                            options: ['undo'],
                            undo: {icon: EditorUndo}
                        }
                    }}
                    stripPastedStyles={true}
                    toolbarCustomButtons={[<VariableDropdown />, <Badge toggleBadgeState={this.toggleBadgeState} />]}
                    customStyleMap={styleMap}
                />
            </>
        );
    }
}
 
export default TextEditor;