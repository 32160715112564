import React, { Component } from 'react';

import {Badge} from 'react-bootstrap';

import Checkmark from '../../../Images/img-check.svg';
import Times from '../../../Images/img-times.svg';

import './ActiveInactiveButton.scss';

class ActiveInactiveButton extends Component {
    state = { 
        activeStatus: this.props.activeJson.status
    }

    // Changes the status of the button to either the active or inactive state
    changeStatus = () => {
        if (this.props.editMode) {
            this.props.activeJson.status = !this.props.activeJson.status

            this.setState((prevProps) => {
                return {
                    ...prevProps,
                    activeStatus: !prevProps.activeStatus
                }
            })

            this.props.bodyJson[this.props.activeJson.objKey] = this.props.activeJson.status;
            
            this.props.updateHandler("body", null, this.props.bodyJson);
        }
    }

    render() { 
        return ( 
            <span className={`active-status align-self-center d-flex align-items-center ${this.props.activeJson.name.replace(" ", "-")}`}>
                <span className="active-descriptor text-uppercase font-weight-bolder mr-3">{this.props.activeJson.name}</span>
                <Badge pill className={`active-status-btn d-flex flex-row align-items-center position-relative p-2 align-self-center mr-3 ${this.props.editMode ? this.state.activeStatus ? "active-edit-btn" : "inactive-edit-btn" : this.state.activeStatus ? "active-btn" : "inactive-btn"} ${this.props.editMode ? 'cursor-pointer' : ""}`} onClick={this.changeStatus}>
                    <span className="active-text position-relative">{this.state.activeStatus ? "Active" : "Inactive"}</span><span className="indicator rounded-circle float-right position-absolute"><img src={this.state.activeStatus ? Checkmark : Times} alt="checkmark" /></span>
                </Badge>
            </span>
        );
    }
}
 
export default ActiveInactiveButton;