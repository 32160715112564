import React from "react";
import { connect } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import Routes from "../Routes/Routes";
import UserMenu from "../../Components/Navigation/UserMenu/UserMenu";
import SidebarNavigation from "../../Components/Navigation/SidebarNavigation/SidevarNavigation";
import Login from "../../Components/Login/Login";
import * as actionTypes from "../../Store/Actions/Login";

import "./MissionControl.scss";

import logo from "../../Images/img-logo.svg";

const MissionControl = (props) => {
  let mainContent, navigation;

  // If the user is logged in, show the page content and sidebar navigation. Otherwise, show UI that the user needs to login
  if (props.loggedIn === true) {
    mainContent = <Routes />;
    navigation = <SidebarNavigation />;
  } else if (
    props.loggedIn === false &&
    (window.localStorage.missionControlLoggedIn === undefined ||
      window.localStorage.missionControlLoggedIn === "false")
  ) {
    props.onUserNeedsToLogin();
  } else {
    mainContent = <Login />;
  }

  return (
    <Container fluid className="bg-white mission-control-app vh-100">
      {/* <Row className="top-nav justify-content-center position-fixed mb-5">
        <div className="top-nav-logo border-bottom d-flex align-items-center justify-content-center bg-slate-900">
          <a href="/">
            <img
              className="img-fluid"
              src={logo}
              alt="Nations Info Corp logo"
            />
          </a>
        </div>
      </Row> */}
      <Row className="main-section position-fixed d-flex h-100 w-100">
        <div className="sidebar-nav p-0">{navigation}</div>
        <div className="main-content bg-light flex-fill">
          <div className="user-menu align-items-center bg-white hidden justify-end lg:flex sticky z-1">
            <UserMenu />
          </div>
          {mainContent}
        </div>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.login.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUserNeedsToLogin: () => dispatch(actionTypes.userNeedsToLogin()),
    onUserLoggedIn: () => dispatch(actionTypes.userLoggedIn()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MissionControl);
