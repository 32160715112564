import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';

import {Row, Col, Button, Spinner} from 'react-bootstrap';

import './CreateComponentButtons.scss';

const CreateComponentButtons = (props) => {
    const [customSaving, setCustomSaving] = useState(null);

    useEffect(() => {
        if (customSaving) {
            setTimeout(() => {
                setCustomSaving(false)
            }, 300)
        }
    }, [customSaving])

    let createButtonText;

    // If the user has clicked on the "Create" button then set the "Create" buttons text to be a Spinner. Otherwise, make it the default text
    if (props.saving && props.page === "bin bypass" && !props.location.pathname.includes('edit')) {
        createButtonText = (<span>Creating {props.btnText} </span>)
    } else if (props.saving && props.page === "bin bypass" && props.location.pathname.includes('edit')) {
        createButtonText = (<span>Saving Changes </span>)
    } else if ((props.saving && !props.errorState) || (!props.saving && customSaving)) {
        createButtonText = <Spinner size="sm" animation="border" variant="white" className="m-auto" />
    } else if (props.notCreate) {
        createButtonText = <span>{props.btnText}{props.btnText.toLowerCase() === 'save' ? "" : <i className="fas fa-plus-circle ml-1"></i>}</span>
    } else if (props.location.pathname.includes('edit')) {
        createButtonText = (<span>Save Changes</span>)
    } else {
        createButtonText = (<span>Create {props.btnText} <i className="fas fa-plus-circle ml-1"></i></span>)
    }

    // Save hanlder to save changes
    let saveHandler = () => {
        if (typeof props.fieldErrorHandler === "function") {
            if(props.fieldErrorHandler().indexOf(false) === -1) {
                setCustomSaving(true)
                props.savingCreateHandler();
            }
        } else if (typeof props.customSaveHandler !== "function") {
            props.savingCreateHandler();
        } else if (typeof props.customSaveHandler === "function") {
            if (props.cardLabel === "edit super partner") {
                props.editHandler(true)
            }
            props.customSaveHandler()
            setCustomSaving(true)
        }
        if (props.editMode) {
            
            // ADEV-12524: Due to component being shared to many modules.
            // Narrow down solution to only be applicable to "/bin-list-manager-create" URL
            // If the URL is bin-list-manager-create, then don't run the editHandler function
            // to prevent duplicate data from being saved.

            if(props.createPath === "/bin-list-manager-create") return;
            props.editHandler(props.editMode); 
        } 
    }

    return ( 
        <Row>
            <Col className="create-component-buttons">
                <div className="float-right">
                    <Button variant="outline-primary" className="font-weight-bold mr-3" onClick={() => {props.history.push(props.mainPath)}}>Close</Button>
                    <Button variant="primary" className="font-weight-bold text-capitalize" onClick={saveHandler} disabled={props.disableEditing !== undefined && props.disableEditing ? props.disableEditing : ""}>{createButtonText} </Button>
                </div>
            </Col>
        </Row>
    );
}
 
export default withRouter(CreateComponentButtons);