export const GET_DISCLOSURE_JSON_FAILED = 'GET_DISCLOSURE_JSON_FAILED';
export const GET_DISCLOSURE_JSON = 'GET_DISCLOSURE_JSON';
export const GET_SET_JSON = 'GET_SET_JSON';
export const UPDATE_CREATE_DISCLOSURE_JSON = 'UPDATE_CREATE_DISCLOSURE_JSON';
export const CREATE_DISCLOSURE_JSON = 'CREATE_DISCLOSURE_JSON';
export const SAVING_NEW_DISCLOSURE = 'SAVING_NEW_DISCLOSURE';

export const GET_CONTENT_MANAGER_JSON = 'GET_CONTENT_MANAGER_JSON';
export const GET_CONTENT_MANAGER_JSON_FAILED = 'GET_CONTENT_MANAGER_JSON_FAILED';
export const SAVING_NEW_CONTENT_MANAGER = 'SAVING_NEW_CONTENT_MANAGER';
export const CREATE_CONTENT_MANAGER_JSON = 'CREATE_CONTENT_MANAGER_JSON';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_NEEDS_TO_LOGIN = 'USER_NEEDS_TO_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGGED_IN= 'USER_LOGGED_IN';

export const GET_BIN_LIST_JSON = 'GET_BIN_LIST_JSON';
export const GET_BIN_LIST_JSON_FAILED = 'GET_BIN_LIST_JSON_FAILED';
export const SAVING_NEW_BILLING_MANAGER = 'SAVING_NEW_BILLING_MANAGER';
export const GET_CASCADE_BYPASS_LIST_JSON = 'GET_CASCADE_BYPASS_LIST_JSON';
export const GET_CASCADE_BYPASS_HISTORY_JSON = 'GET_CASCADE_BYPASS_HISTORY_JSON';
export const CREATE_CASCADE_BYPASS = 'CREATE_CASCADE_BYPASS'
export const UPDATE_CASCADE_BYPASS = 'UPDATE_CASCADE_BYPASS'


export const GET_TOP_ADS_JSON = 'GET_TOP_ADS_JSON';
export const SET_TOP_ADS_AMOUNT = 'SET_TOP_ADS_AMOUNT';
export const SET_ADIDS_JSON = 'SET_ADIDS_JSON';
export const GET_ADS_ERROR = 'GET_ADS_ERROR';
export const GET_ADS_ERROR_RESET = 'GET_ADS_ERROR_RESET';
export const SET_ADS_LOADING = 'SET_ADS_LOADING';
export const GET_SEARCH_ADS_JSON = 'GET_SEARCH_ADS_JSON';
export const SET_SEARCH_TEXT = 'SET_SEARCH_TEXT';
export const GET_FULL_AD_DETAILS_JSON = 'GET_FULL_AD_DETAILS_JSON';
export const SET_AD_MANAGER_CREATE_STATE = 'SET_AD_MANAGER_CREATE_STATE';
export const SET_SELECTED_ADID = 'SET_SELECTED_ADID';
export const GET_AD_DETAILS_JSON = 'GET_AD_DETAILS_JSON';
export const SET_AD_DETAILS_JSON = 'SET_AD_DETAILS_JSON';
export const SET_UCT_AD_DETAILS_JSON = 'SET_UCT_AD_DETAILS_JSON';
export const SET_AD_MANAGER_EDIT_STATE = 'SET_AD_MANAGER_EDIT_STATE';
export const SET_VERTICAL_TYPE = 'SET_VERTICAL_TYPE';
export const SET_SUPER_PARTNER_DETAILS = 'SET_SUPER_PARTNER_DETAILS';
export const SET_PARTNER_DETAILS = 'SET_PARTNER_DETAILS';
export const SET__PARTNER_DROPDOWN_OPTIONS = 'SET__PARTNER_DROPDOWN_OPTIONS';
export const SET_ENDPOINT_RESPONSE = 'SET_ENDPOINT_RESPONSE';
export const SET_RECENTLY_CREATED = 'SET_RECENTLY_CREATED';
export const SET_BRAND_URL = 'SET_BRAND_URL';
export const SET_SUCCESS_ALERT = 'SET_SUCCESS_ALERT';

export const SET_BILLING_JSON_BY_PARTNER_ID = 'SET_BILLING_JSON_BY_PARTNER_ID';
export const SET_BILLING_JSON_BY_PARTNER_ID_LOADING = 'SET_BILLING_JSON_BY_PARTNER_ID_LOADING';
