import React, { useState } from 'react';
import { addDays } from 'date-fns';

import { DateRangePicker } from 'react-date-range';
import {Button} from 'react-bootstrap';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './DateRangePicker.scss';

const DatePicker = (props) => {
    const [date, setDate] = useState([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
    ]);

    let datesPicked = (selected) => {
        if (selected.selection.startDate === null) {
            selected.selection.startDate = selected.selection.endDate
        } else if (selected.selection.endDate === null) {
            selected.selection.endDate = selected.selection.startDate
        }
        
        let selectedStartDateSplit = selected.selection.startDate.toString().split(" ");
        let selectedEndDateSplit = selected.selection.endDate.toString().split(" ");
        let startDateOnly = `${selectedStartDateSplit[1]} ${selectedStartDateSplit[2]}, ${selectedStartDateSplit[3]}`;
        let endDateOnly = `${selectedEndDateSplit[1]} ${selectedEndDateSplit[2]}, ${selectedEndDateSplit[3]}`;

        props.selectHandler(null, startDateOnly, endDateOnly, props.dateCategory, "date picker")

        setDate([selected.selection])
    }

    let allDates = () => {
        setDate([
            {
                startDate: null, 
                endDate: null, 
                key: 'selection'
            }
        ]);

        props.selectHandler(null, "All", null, props.dateCategory, "date picker")
    }

    return ( 
        <div className={`bg-white date-picker-wrapper ${props.dateCategory.replace(" ", "-")}`}>
            <DateRangePicker
            onChange={(item) => datesPicked(item)}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={date}
            direction="horizontal"
            />
            <Button variant="primary" size="sm" className="ml-3 mb-3 position-absolute" onClick={allDates}>All Dates</Button>
        </div>
    );
}
 
export default DatePicker;