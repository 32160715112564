import React, {Component} from 'react';

import {Row, Col, Card, Button} from 'react-bootstrap';
import EditableInput from '../EditableInput/EditableInput';
import AppendEditableInput from './AppendEditableInput/AppendEditableInput';
import StateTranslateOpacityAnimation from '../../AnimationWrappers/TranslateOpacityAnimation/StateTranslateOpacityAnimation';

import './MultipleEditableInputs.scss';

class MultipleEditableInputs extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            mobileBtn: false
        }
    }

    // Checks to see if the bodyJson has a key of "buttonTextMobile" to set the mobileBtn state variable to true
    componentDidMount() {
        if(this.props.bodyJson["buttonTextMobile"]) {
            this.setState(prevState => {
                return {
                    ...prevState,
                    mobileBtn: true
                }
            });
        }
    }

    // When a user clicks the "Add Mobile Button" button it updates the json to add the key/value pair. If the user clicks the "Remove Mobile Button" button it delets the key/value pair from the json.
    mobileHandler = () => {
        if (this.props.editMode) {
            if (!this.state.mobileBtn) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        mobileBtn: true
                    }
                });
    
                this.props.bodyJson["buttonTextMobile"] = "";
    
                this.props.updateHandler("body", "", this.props.bodyJson);
            } else if (this.state.mobileBtn) {
                this.setState(prevState => {
                    return {
                        ...prevState,
                        mobileBtn: false
                    }
                });
    
                delete this.props.bodyJson["buttonTextMobile"];
    
                this.props.updateHandler("body", "", this.props.bodyJson);
            }
        }
    }

    // Checks to see if a Mobile Button exists. If it does, show the "Remove Mobile Button" butotn. If it does not exist, show the "Add Mobile Button".
    mobileBtnExistsHandler = () => {
        let mobileBtnExists;

        if (!this.state.mobileBtn) {
            mobileBtnExists = <Button variant="primary" onClick={this.mobileHandler} block><i className="far fa-check-square"></i> Add Mobile Button</Button>
        } else {
            mobileBtnExists = <Button variant="outline-primary" onClick={this.mobileHandler} block><i className="far fa-window-close"></i> Remove Mobile Button</Button>
        }

        if (this.props.editMode) {
            return mobileBtnExists;
        } else {
            return ""
        }
    }

    render() {
        let editableInputs = Object.keys(this.props.bodyJson).map((key, index) => {
            let label = key.split(/(?=[A-Z])/).join(" ");

            if (key.toLowerCase().indexOf('mobile') === -1) {
                return <EditableInput jsonKey={key} key={`${key}-${index}`} body={true} {...this.props} label={label} name={this.props.bodyJson[key]} readOnly={!this.props.editMode} required={this.props.requiredFields !== undefined && this.props.requiredFields.includes(key)} />
            } else {
                return false;
            }
        });

        return ( 
            <>
                <Card className="multiple-editable-inputs border">
                    <Card.Body>
                        <Row>
                            <Col xs={7}>
                                {editableInputs}
                            </Col>
                            <Col>
                                {this.mobileBtnExistsHandler()}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <StateTranslateOpacityAnimation trigger={this.state.mobileBtn}>
                    <AppendEditableInput mobileBtn={this.state.mobileBtn} {...this.props} />
                </StateTranslateOpacityAnimation>
            </>
        );
    }
}
 
export default MultipleEditableInputs;