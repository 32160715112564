import React, {Component} from 'react';

import {Badge, Tooltip, OverlayTrigger, Button} from 'react-bootstrap';
import EditableInput from '../../../Inputs/EditableInput/EditableInput';
import TranslateOpacityAnimation from '../../../AnimationWrappers/TranslateOpacityAnimation/TranslateOpacityAnimation';
import EditSaveButton from '../../../Buttons/EditSaveButton/EditSaveButton';
import Clipboard from '../../../SVG/Clipboard/Clipboard';

import './EditableHeader.scss';

class EditableHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transitionAnimate: false
        }
    }

    // This function copies the ID of the card to the users clipboard
    copyToClipboard = (event) => {
        event.preventDefault();

        let hElement = event.target.closest('h5');

        if (this.props.headerJson.id !== undefined) {
                let badgeElement = hElement.getElementsByClassName('badge')[0];

                let textArea = document.createElement("textarea");
                textArea.value = this.props.headerJson.id;
                // make the textarea out of viewport
                textArea.style.position = "fixed";
                textArea.style.left = "-999999px";
                textArea.style.top = "-999999px";
                textArea.style.opacity = 0;
                badgeElement.appendChild(textArea);
                textArea.select();

            let newSpan = document.createElement('SPAN');
            newSpan.className = 'copied-span text-small align-middle';
            newSpan.textContent = "Copied";
            
            hElement.appendChild(newSpan);

            setTimeout(() => {
                document.getElementsByClassName('copied-span')[0].classList.add('fade')
            }, 100)

            setTimeout(() => {
                document.getElementsByClassName('copied-span')[0].classList.remove('fade')
            }, 1250);

            setTimeout(() => {
                document.getElementsByClassName('copied-span')[0].remove()
            }, 1750);

            return new Promise((res, rej) => {
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }

    render() { 
        let editName, editNameLabel, editButton;
        // Gets the ID if one exists for the Editable Header section
        let splitId = this.props.headerJson.id !== undefined ? this.props.headerJson.id.split('-')[0] : "";
        
        // Gets the label name to be used when the Editable Card is in edit mode. If the key has "Name" in it and is one word, it will split it into 2 different words. Otherwise, it just uses the key.
        for (const key in this.props.realHeaderJson) {
            if (key.indexOf('Name') !== -1) {
                editNameLabel = key.split(/(?=[A-Z])/).join(" ");
            } else {
                editNameLabel = key
            }
        }

        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                Copy to Clipboard
            </Tooltip>
        );

        // If the Editable Card is NOT in edit mode then use the normal name/ID Text. Otherwise, use an Editable Input when the Editable Card IS in edit mode
        if (!this.props.editMode) {
            editName = <h5 className="font-weight-bold">{this.props.headerJson.name} <Badge pill variant="secondary" className="align-middle position-relative py-2 px-3 ml-2 font-weight-bold">
                {splitId}
                <OverlayTrigger placement="bottom" delay={{ show: 150, hide: 200 }} overlay={renderTooltip}>
                    <a href="#" onClick={this.copyToClipboard} className="clipboard-icon position-absolute">
                        <Clipboard />
                    </a>
                </OverlayTrigger>
            </Badge></h5>
        } else {
            editName = <EditableInput realHeaderKey={Object.keys(this.props.realHeaderJson)[0]} {...this.props} label={editNameLabel} name={this.props.headerJson.name} maxLength={this.props.headerJson.maxLength} required={this.props.headerJson.required} type={this.props.headerJson.type} />
        }
        
        // If the Editable Card isn't in create mode then use/show the Edit/Save Button. Otherwise, show nothing
        if (!this.props.create) {
            editButton = (
                <>
                    <Button onClick={(event) => {this.props.transitionHandler(); this.props.editHandler(!this.props.editMode, event); }} 
                            className={`position-absolute rounded-pill font-weight-bold cancel-btn ${!this.props.editMode ? 'd-none' : ''}`}
                            variant="outline-primary"
                    >
                            Cancel
                    </Button>
                    <EditSaveButton {...this.props} />
                </>
            )
        } else {
            editButton = null;
        }

        return ( 
            <div className="editable-header">
                <p className="font-weight-bold text-muted text-uppercase small-heading mt-3">{this.props.cardLabel}</p>
                    {Object.keys(this.props.headerJson).length > 0 ? <TranslateOpacityAnimation transitionAnimate={this.props.transitionAnimate}>
                        {editName}
                    </TranslateOpacityAnimation>
                    : ''}
                    {editButton}
            </div>
         );
    }
}
 
export default EditableHeader;