import React from 'react';
import {Route, Switch, Redirect, useLocation} from 'react-router-dom';

import ScrollToTop from '../../Components/ScrollToTop/ScrollToTop';
import Disclosures from '../Disclosures/Disclosures';
import DisclosuresCreate from '../Disclosures/DisclosuresCreate';
import ContentManager from '../ContentManager/ContentManager';
import ContentManagerCreate from '../ContentManager/ContentManagerCreate';
import Billing from '../Billing/Billing';
import BinList from '../Billing/BinList';
import BillingCreate from '../Billing/BillingCreate';
import AdIDResults from '../AdIDManager/AdIDManagerResults';
import AdIDManagerCreate from '../AdIDManager/AdIDManagerCreate';
import AdIDManagerEdit from '../AdIDManager/AdIDManagerEdit';
import AdIDManagerPreview from '../AdIDManager/AdIDManagerPreview';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import PageNotFound from '../404/PageNotFound';

const Routes = () => {
    let location = useLocation();

    // Animates switching between different routes AND defines the routes and what components are used with them, as well as the properties the component needs
    const animatedSwitch =  (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="routes-animation"
                timeout={300}
                exit={false}>
                <Switch location={location}>
                    <Route path="/disclosure-terms" component={() => <Disclosures createPath={"/disclosure-terms-create"} page={"disclosure terms"} btnText={"terms"} endpoint={"terms"} bodyContent="text editor" />} />
                    <Route path="/disclosure-terms-create" component={() => <DisclosuresCreate mainPath={"/disclosure-terms"} page={"disclosure terms"} btnText={"terms"} endpoint={"terms"} bodyContent="text editor" />} />
                    <Route path="/disclosure-buttons" component={() => <Disclosures createPath={"/disclosure-buttons-create"} page={"buttons"} btnText={"buttons text"} endpoint={"buttons"} bodyContent="multiple inputs" />} />
                    <Route path="/disclosure-buttons-create" component={() => <DisclosuresCreate mainPath={"/disclosure-buttons"} page={"buttons"} btnText={"buttons text"} endpoint={"buttons"} bodyContent="multiple inputs" />} />
                    <Route path="/disclosure-sets" component={() => <Disclosures createPath={"/disclosure-sets-create"} page={"disclosure sets"} btnText={"disclosure set"} endpoint={"disclosureSet"} bodyContent="item set" />} />
                    <Route path="/disclosure-sets-create" component={() => <DisclosuresCreate mainPath={"/disclosure-sets"} page={"disclosure sets"} btnText={"disclosure set"} endpoint={"disclosureSet"} bodyContent="item set" />} />

                    <Route path="/content-manager-content-headings" component={() => <ContentManager createPath={"/content-manager-content-headings-create"} page={"content headings"} btnText={"content heading"} endpoint={"headings"} bodyContent="text editor" />} />
                    <Route path="/content-manager-content-headings-create" component={() => <ContentManagerCreate mainPath={"/content-manager-content-headings"} page={"content headings"} btnText={"content heading"} endpoint={"headings"} bodyContent="text editor" />} />

                    <Route path="/bin-list-manager" component={() => <BinList createPath={"/bin-list-manager-create"} btnText={"bin list"} endpoint={"bin list"} bodyContent="active item set" filterName="bins" page="bin profile" endsWith={false} searchFilter={true} /> } />
                    <Route path="/bin-list-manager-results" component={() => <Billing createPath={"/bin-list-manager-create"} btnText={"bin list"} endpoint={"bin list"} bodyContent="active item set" filterName="bins" page="bin profile" endsWith={false} searchFilter={true} /> } />
                    <Route path="/bin-list-manager-create" component={() => <BillingCreate createPath={"/bin-list-manager-create"} mainPath={"/bin-list-manager"} btnText={"bin list"} endpoint={"bin list"} bodyContent="item set" filterName="bins" page="bin profile" endsWith={false} searchFilter={true} /> } />
                    <Route path="/cascade-bypass" component={() => <Billing editPath={"/cascade-bypass-edit"} createPath={"/cascade-bypass-create"} bodyContent="item set collapse" filterName="bypasses" page="cascade bypass" endsWith={false} searchFilter={true} collapseType="table" collapsedBtnText={"View History"} nonCollapsedBtnText={"Hide History"} /> } />
                    <Route path="/cascade-bypass-create" component={() => <BillingCreate editPath={"/cascade-bypass-edit"} createPath={"/cascade-bypass-create"} mainPath={"/cascade-bypass"} btnText={"bin bypass"} endpoint={"bin bypass"} bodyContent="item set" filterName="bins" page="bin bypass" endsWith={false} searchFilter={true} /> } />
                    <Route path="/cascade-bypass-edit" component={() => <BillingCreate editPath={"/cascade-bypass-edit"} createPath={"/cascade-bypass-create"} mainPath={"/cascade-bypass"} btnText={"bin bypass"} endpoint={"bin bypass"} bodyContent="item set" filterName="bins" page="bin bypass" endsWith={false} searchFilter={true} /> } />

                    <Route path="/adid-manager-results" component={() => <AdIDResults bodyContent="item set" filterName="" page="adid manager results" searchFilter={true} /> } />
                    <Route path="/adid-manager-create" component={() => <AdIDManagerCreate bodyContent="inputs only" filterName="" page="adid manager create" searchFilter={true} mainPath={"/adid-manager-results"} editPath={'/adid-manager-edit'} /> } />
                    <Route path="/adid-manager-edit" component={() => <AdIDManagerEdit bodyContent="inputs only" filterName="" page="adid manager edit" searchFilter={true} mainPath={"/adid-manager-results"} /> } />
                    <Route path="/adid-manager-preview" component={() => <AdIDManagerPreview bodyContent="inputs only" filterName="" page="adid manager preview" searchFilter={true} mainPath={"/adid-manager-results"} /> } />

                    <Route path="/404" component={PageNotFound} />
                    <Route exact path='/' />
                    <Redirect to="/404" />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
    
    return ( 
        <div>
            <ScrollToTop />
            {animatedSwitch}
        </div>
    );
}
 
export default Routes;