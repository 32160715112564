import React from 'react';

import {CSSTransition} from 'react-transition-group';

const TranslateOpacityAnimation = (props) => {
    return ( 
        <CSSTransition
            in={props.transitionAnimate}
            classNames="opacity-translate-animation"
            timeout={300}
            exit={false}>
            {props.children}
        </CSSTransition>
     );
}
 
export default TranslateOpacityAnimation;