import * as actionTypes from '../Actions/ActionTypes';

// Initial state for disclosures
const initialState = {
    disclosures: null,
    buttonSet: null,
    termSet: null,
    disclosureCreateTerms: {
        "termName": "",
        "termDescription": {
            value: "",
            required: true
        }
    },
    disclosureCreateSets: {
        "disclosureSetName": "",
        "termIDSelected": "",
        "termNameSelected": "",
        "buttonIDSelected": "",
        "buttonNameSelected": ""
    },
    disclosureCreateButtons: {
        "buttonName": "",
        "buttonTextTop": "",
        "buttonTextBottom": {
            value: "",
            required: true
        }
    },
    saving: false,
    error: false
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.UPDATE_CREATE_DISCLOSURE_JSON:
            return {
                ...state,
                saving: false,
                error: false
            }
        // Set the disclosures json based on the API response
        case actionTypes.GET_DISCLOSURE_JSON:
            return {
                ...state,
                disclosures: action.disclosures,
                saving: false,
                error: false
            }
        // Set the error state to true if the disclosure API call returns an error
        case actionTypes.GET_DISCLOSURE_JSON_FAILED:
            return {
                ...state,
                saving: false,
                error: true
            }
        // Set the button and term sets based on the API response
        case actionTypes.GET_SET_JSON:
            return {
                ...state,
                buttonSet: action.buttonSet,
                termSet: action.termSet,
                saving: false,
                error: false
            }
        // Set the saving state to true
        case actionTypes.SAVING_NEW_DISCLOSURE:
            return {
                ...state,
                saving: true
            }
        default: return state
    }
}

export default reducer;