import React from "react";
import { connect } from "react-redux";
import * as actionTypes from "../../../Store/Actions/Login";

import { Navbar, NavDropdown } from "react-bootstrap";
import { GoogleLogout } from "react-google-login";

import profileImage from "../../../Images/img-profile-user.svg";

import "./UserMenu.scss";

const UserMenu = (props) => {
  // variable to check if screen width is less than 1024px
  const isMobile = window.innerWidth < 1024;
  // This function logs the user out
  const logout = () => {
    localStorage.setItem("missionControlLoggedIn", "false");
    props.onLogoutUser();
  };

  let menu = "";

  // If the user is logged in, show the user's first and last name, as well as a dropdown to allow the user to logout
  if (props.loggedIn === true) {
    menu = (
      <NavDropdown
        title={
          <span className={`inline-flex gap-3 ${isMobile && "text-white"}`}>
            <img
              src={profileImage}
              alt="Profile"
              className="img-fluid profile-img"
            />{" "}
            {props.firstName} {props.lastName}
          </span>
        }
        id="user-logout"
        className="user-dropdown text-right text-reset"
      >
        <GoogleLogout
          clientId="491609097370-nrf6gvgga6kpnfcdcdvp9en5h2q38mrp.apps.googleusercontent.com"
          buttonText="Logout"
          render={(renderProps) => (
            <NavDropdown.Item
              onClick={renderProps.onClick}
              disabled={false}
              className="text-primary font-weight-bold px-0"
            >
              Logout
            </NavDropdown.Item>
          )}
          onLogoutSuccess={logout}
        ></GoogleLogout>
      </NavDropdown>
    );
  }

  return (
    <Navbar
      bg={isMobile ? "bg-slate-900" : "white"}
      className="user-nav"
    >
      {menu}
    </Navbar>
  );
};

const mapStateToProps = (state) => {
  return {
    firstName: state.login.userFirstName,
    lastName: state.login.userLastName,
    loggedIn: state.login.loggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogoutUser: () => dispatch(actionTypes.logoutUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
