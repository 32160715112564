import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {Button} from 'react-bootstrap';

import EditorBadge from '../../../../Images/img-editor-badge.svg';
import './Badge.scss';

class Badge extends Component {
    static propTypes = {
        editorState: PropTypes.object,
    }


    render() { 
        const { editorState } = this.props;

        return ( 
            <>
                <Button variant="default" className={`font-weight-bold badge-btn d-flex ${editorState.getCurrentInlineStyle().has("BADGE") ? 'active' : ''}`} onClick={this.props.toggleBadgeState}><img src={EditorBadge} alt="badge-icon" /></Button>
            </>
        );
    }
}
 
export default Badge;