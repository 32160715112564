import React, { Component } from 'react';

import {Table as BootstrapTable} from 'react-bootstrap';

import AdidManagerTableRows from '../../Containers/AdIDManager/Components/TableRows';

import './Table.scss';

class Table extends Component {
    state = {  }
    
    render() { 
        let headers = this.props.tableHeaders.map((key, index) => {
            return (
                <th key={`${key}-${index}`} className="text-uppercase">{key.replace(/([A-Z])/g, ' $1')}</th>
            )
        })

        let body;

        if (this.props.tableItems !== undefined || this.props.tableItems !== null) {
            if (this.props.hiddenTableItems) {
                body = this.props.tableItems.map((key, index) => {
                    return <AdidManagerTableRows key={`${key}.${index}`} {...this.props} rowKey={key} rowIndex={index} />
                })
            } else {
                body = this.props.tableItems.map((key, index) => {
                    return (
                        <tr key={`${key}.${index}`}>
                            {this.props.tableHeaders.map((keytwo, indextwo) => {
                                return <td key={`${keytwo}-${indextwo}`} className={keytwo}>{key[keytwo]}</td>
                            })}
                        </tr>
                    )
                })
            }
        } else {
            body = ""
        }

        if (this.props.page === "cascade bypass") {
            body.sort((a, b) => {
                let aDateSplit = a.props.children[9].props.children.replace(",", "").replace(/\./g, "");
                let bDateSplit = b.props.children[9].props.children.replace(",", "").replace(/\./g, "");
    
                return new Date(bDateSplit) - new Date(aDateSplit);
            })
        }
        
        return ( 
            <BootstrapTable responsive={true} borderless={true} className="data-table mb-0">
                <thead className="bg-light">
                    <tr>
                        {headers}
                    </tr>
                </thead>
                <tbody>
                    {body}
                </tbody>
            </BootstrapTable>
        );
    }
}
 
export default Table;