import * as actionTypes from './ActionTypes';

// Sets the users first/last name and they're logged in with the returned json from the google auth API call
export const setUserInfo = (firstName, lastName) => {
    localStorage.setItem('missionControlLoggedIn', 'true');
    return {
        type: actionTypes.USER_LOGIN,
        userFirstName: firstName,
        userLastName: lastName
    }
}

// Sets the loggedIn state to false
export const logoutUser = () => {
    return {
        type: actionTypes.USER_LOGOUT
    }
}

// Sets the state to show the button for the user to login
export const userNeedsToLogin = () => {
    return {
        type: actionTypes.USER_NEEDS_TO_LOGIN
    }
}

// Sets the loggedIn state to true
export const userLoggedIn = () => {
    return {
        type: actionTypes.USER_LOGGED_IN
    }
}