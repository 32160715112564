import * as actionTypes from '../Actions/ActionTypes';

// Initial state for billing
const initialState = {
    topAdsAmountOptions: ['25', '50', '75', '100', '125'],
    topAdsAmount : null,
    adidsResults: null,
    columnLabels: [{name: 'AdID', width: "22Point5", padding: "pl-2"}, {name: 'Super Partner', width: "17Point5", padding: "pl-3"}, {name: 'Partner', width: "30", padding: "pl-3"}, {name: 'Sales', width: "7Point5", padding: "pl-2 pr-3"}, {name: 'Brand', width: "15", padding: "pl-2"}, {name: 'Landing', width: "7Point5", padding: "pl-2"}],
    dropdownOptions: null,
    searchFilters: null,
    searchText: null,
    error: false,
    errorMessage: null,
    createType: null,
    createCategory: null,
    cloneAdid: {
        adid: "",
        newAdid: "",
        adDescription: "",
        jiraReference: ""
    },
    selectedAdid: null,
    brandUrl: null,
    successAlert: null,
    viewAdidDetails: null,
    viewAdidUCTDetails: null,
    uctTableHeaders: ['id', 'name', "value", "brand", "revision comment", "last modified"],
    adidFilters: ["fl", "frto", "rto", "credco", "not recommended"],
    uctFilters : ["hide adid", "super partner", "partner", "brand", "vertical", "visitor tag", "all"],
    editType: null,
    verticalType: null,
    createSuperPartner: {
        id: "",
        name: ""
    },
    viewSuperPartnerDetails: null,
    clonePartner: {
        partnerIdToClone: "",
        partnerId: "",
        partnerName: ""
    },
    createPartner: {
        "id": "",
        "name": "",
        "superPartner": "",
        "brand": "",
        "vertical": "",
        "channel": "",
        "partnerType": "",
        "saleType": "",
        "verifyPrepaidBypass %": "",
        "logoImage": "",
        "logoSmallImage": "",
        "logoMobileImage": "",
        "logoMobileSmallImage": "",
        "logoUrl": "",
        "headerType": "",
        "headerHtml": "",
        "landingPagePixel (responsive)": "",
        "regPagePixel (responsive)": "",
        "conf/upsellPagePixel (responsive)": "",
        "srpPixel (responsive)": "",
        "srpLightboxPixel (responsive)": "",
        "responsiveVerificationPixel": ""
    },
    returnPartnerJson: {
        "addEoValue": 1,
        "apiPassword": null,
        "apiUsername": null,
        "billingAction": "",
        "billingAttributes": "",
        "billingFormat": "",
        "billingMethod": "",
        "billingTemplate": "",
        "brandId": 0,
        "brandIdentifier": null,
        "brandedPartner": null,
        "channelType": "",
        "commission": 0,
        "confirmationPixel": null,
        "displayDeclinerPromo": false,
        "headerHtml": null,
        "headerType": 0,
        "isActive": true,
        "isUnsub": false,
        "landingPixel": "",
        "lastModified": "",
        "lastModifyingUser": "",
        "logoImage": null,
        "logoMobileImage": null,
        "logoMobileSmallImage": null,
        "logoSmallImage": null,
        "logoUrl": null,
        "memberLoginPixel": null,
        "name": "",
        "partnerAd": "",
        "partnerId": 0,
        "partnerType": null,
        "regPixel": "",
        "repConfirmationPixel": "",
        "reportAffiliates": false,
        "reportEoValue": 0,
        "reportTail": 0,
        "responsiveConfirmationPixel": null,
        "responsiveLandingPixel": null,
        "responsiveRegPixel": null,
        "responsiveSrpLightboxPixel": "",
        "responsiveSrpPixel": null,
        "responsiveVerificationPixel": null,
        "saleType": "",
        "securityLevel": 1,
        "srpLightboxPixel": "",
        "srpPixel": "",
        "superPartnerId": 0,
        "unsubEmail": "",
        "unsubPassword": null,
        "unsubSeedEmail": null,
        "unsubUsername": "",
        "url": "",
        "verifiPrepaidBypassPercentage": null,
        "verificationPixel": null,
        "verticalType": null
    },
    partnerDropdownOptions: null,
    viewPartnerDetails: null,
    endpointResponse: null,
    recentlyCreated: null,
    loading: true
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_ADIDS_JSON:
            return {
                ...state,
                adidsResults: action.adidsResults,
                editType: null,
                endpointResponse: null,
                error: false,
                errorMessage: null,
                loading: false,
                viewAdidDetails: null,
                viewAdidUCTDetails: null,
                viewSuperPartnerDetails: null,
                verticalType: null
            }
        case actionTypes.SET_TOP_ADS_AMOUNT:
            return {
                ...state,
                topAdsAmount: action.topAdsAmount,
                editType: null,
                endpointResponse: null,
                error: false,
                errorMessage: null,
                loading: false,
                viewAdidDetails: null,
                viewAdidUCTDetails: null,
                viewSuperPartnerDetails: null,
                verticalType: null
            }
        case actionTypes.GET_ADS_ERROR:
        case actionTypes.GET_ADS_ERROR_RESET:
            return {
                ...state,
                error: action.error,
                errorMessage: action.errorMessage,
                loading: false
            }
        case actionTypes.SET_ADS_LOADING:
            return {
                ...state,
                loading: action.loading
            }
        case actionTypes.GET_FULL_AD_DETAILS_JSON:
            let filters = [
                {
                    type: "brand",
                    options: {},
                    class: "brand",
                    contains: true
                },
                {
                    type: "vertical",
                    options: {
                        option1: "All",
                        option2: "RTO",
                        option3: "Foreclosure",
                        option4: "FRTO",
                        option5: "Credco"
                    },
                    class: "vertical"
                },
                {
                    type: "landing",
                    options: {
                        option1: "All",
                        option2: "Welcome",
                        option3: "Search",
                        option4: "Signup"
                    },
                    class: "landing"
                }
            ];
            let brandFilters = filters[0].options;

            brandFilters["option0"] = "All";

            action.options.brand.map((key, index) => {
                return brandFilters[`option${index + 1}`] = `${key.brandId}: ${key.name}`;
            })

            return {
                ...state,
                searchFilters: filters,
                dropdownOptions: action.options
            }
        case actionTypes.SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.searchText
            }
        case actionTypes.SET_AD_MANAGER_CREATE_STATE:
            return {
                ...state,
                createType: action.createType,
                createCategory: action.createCategory
            }
        case actionTypes.SET_SELECTED_ADID:
            return {
                ...state,
                selectedAdid: action.selectedAdid
            }
        case actionTypes.SET_AD_DETAILS_JSON:
            return {
                ...state,
                viewAdidDetails: action.adidDetails
            }
        case actionTypes.SET_AD_MANAGER_EDIT_STATE:
            return {
                ...state,
                editType: action.editType,
                viewAdidDetails: null
            }
        case actionTypes.SET_UCT_AD_DETAILS_JSON:
            return {
                ...state,
                viewAdidUCTDetails: action.viewAdidUCTDetails
            }
        case actionTypes.SET_VERTICAL_TYPE:
            return {
                ...state,
                verticalType: action.verticalType
            }
        case actionTypes.SET_SUPER_PARTNER_DETAILS:
            return {
                ...state,
                viewSuperPartnerDetails: action.viewSuperPartnerDetails
            }
        case actionTypes.SET_ENDPOINT_RESPONSE:
            return {
                ...state,
                endpointResponse: action.endpointResponse
            }
        case actionTypes.SET__PARTNER_DROPDOWN_OPTIONS:
            return {
                ...state,
                partnerDropdownOptions: action.partnerDropdownOptions
            }
        case actionTypes.SET_PARTNER_DETAILS:
            return {
                ...state,
                viewPartnerDetails: action.viewPartnerDetails
            }
        case actionTypes.SET_RECENTLY_CREATED:
            return {
                ...state,
                recentlyCreated: action.recentlyCreated
            }
        case actionTypes.SET_BRAND_URL:
            return {
                ...state,
                brandUrl: action.brandUrl
            }
        case actionTypes.SET_SUCCESS_ALERT:
            return {
                ...state,
                successAlert: action.successAlert
            }
        
        default: return state
    }
}

export default reducer;