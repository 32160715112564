import * as actionTypes from '../Actions/ActionTypes';

// Initial state for billing
const initialState = {
    billingJson: null,
    binListCreate: {
        "cascadeBinType": "",
        "bin": "",     
        "reasonBinCreated": "",    
        "active": "",     
        "brandId": "",
        "userCreated": ""
    },
    brandIds: null,
    binListFilters: [
        {
            type: "type",
            options: {
                option1: "All",
                option2: "casB",
                option3: "casP",
                option4: "casD",
                option5: "blacklist"
            },
            class: "bin-type"
        },
        {
            type: "brand",
            options: {},
            class: "brand"
        },
        {
            type: "status",
            options: {
                option1: "All",
                option2: "Active",
                option3: "Inactive"
            },
            class: "bin-status"
        }
    ],
    cascadeBypassHistory: null,
    cascadeBypassFilters: [
        {
            type: "last modified",
            options: {
                option1: "date-picker"
            },
            class: "last-modified",
            datePicker: "true"
        },
        {
            type: "brand",
            options: {},
            class: "brand"
        },
        // {
        //     type: "vertical",
        //     options: {
        //         option0: "All",
        //         option1: "any",
        //         option2: "foreclosure",
        //         option3: "rto",
        //         option4: "rep",
        //         option5: "mpco",
        //         option6: "credco",
        //         option7: "nicky",
        //         option8: "rto_credit",
        //         option9: "credit_foreclosure",
        //         option10: "foreclosure_rto"
        //     },
        //     class: "vertical-type"
        // },
        // {
        //     type: "type",
        //     options: {
        //         option1: "All",
        //         option2: "casB",
        //         option3: "casP",
        //         option4: "casD",
        //         option5: "blacklist"
        //     },
        //     class: "bin-type"
        // },
        {
            type: "status",
            options: {
                option1: "All",
                option2: "active",
                option3: "inactive"
            },
            class: "status"
        }
    ],
    cascadeBypassTableHeaders: ['event', 'binType', 'status', 'adid', 'verticalType', 'partnerId', 'brand', 'superPartner', 'value', 'last modified', 'lastModifyingUser'],
    cascadeBypassSortBy: {
        initialSort: "last modified",
        options: {
            option1: "bin type",
            option2: "partner ID",
            option3: "status",
            option4: "last modified"
        }
    },
    saving: false,
    collapsed: null,
    error: false
}

const reducer = (state = initialState, action) => {
    let filters;

    switch(action.type) {
        // Set the binlist filters, brandIDs and billingjson
        case actionTypes.GET_BIN_LIST_JSON:
            filters = state.binListFilters;

            // Adds an "All" option to the list of brand ID's
            filters[1].options["0"] = "All";

            // Pushes all brand ID key/value pairs into the options object
            for (const key in action.brandIds) {
                filters[1].options[key] = action.brandIds[key]
            }

            return {
                ...state,
                billingJson: action.billingJson,
                brandIds: action.brandIds,
                binListFilters: filters,
                saving: false,
                error: false
            }
        // Set the cascade bypass filters, brandIDs and billingjson
        case actionTypes.GET_CASCADE_BYPASS_LIST_JSON:
            filters = state.cascadeBypassFilters;

            // Adds an "All" option to the list of brand ID's
            filters[1].options["0"] = "All";
            filters[1].options["1"] = "-1";

            // Pushes all brand ID key/value pairs into the options object
            for (const key in action.brandIds) {
                filters[1].options[key + 1] = action.brandIds[key]
            }

            return {
                ...state,
                billingJson: action.billingJson,
                cascadeBypassHistory: action.cascadeBypassHistory,
                brandIds: action.brandIds,
                cascadeBypassFilters: filters,
                saving: false,
                error: false
            }
        // Set the error state to true if the bin list API call returns an error
        case actionTypes.GET_BIN_LIST_JSON_FAILED:
            return {
                ...state,
                saving: false,
                error: true
            }
        // Set the saving state to true
        case actionTypes.SAVING_NEW_BILLING_MANAGER:
            return {
                ...state,
                saving: true
            }

        // SET_BILLING_JSON_BY_PARTNER_ID
        case actionTypes.SET_BILLING_JSON_BY_PARTNER_ID:
            return {
                ...state,
                billingJsonByPartnertId: action.billingJsonByPartnertId,
            }
        case actionTypes.SET_BILLING_JSON_BY_PARTNER_ID_LOADING:
            return {
                ...state,
                billingJsonByPartnertIdLoading: action.billingJsonByPartnertIdLoading,
            }
        default: return state
    }
}

export default reducer;