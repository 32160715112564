import * as actionTypes from './ActionTypes';
import axios from 'axios';

// Sets the error state to true if there was an error response from the API call
export const getDisclosureJsonFailed = () => {
    return {
        type: actionTypes.GET_DISCLOSURE_JSON_FAILED
    }
}

// Sets the disclosure json with the returned json from the API call
export const getDisclosureJson = (json) => {
    return {
        type: actionTypes.GET_DISCLOSURE_JSON,
        disclosures: json
    }
}

export const getSetJson = (buttons, terms) => {
    return {
        type: actionTypes.GET_SET_JSON,
        buttonSet: buttons,
        termSet: terms
    }
}

// API call to get the disclosure json
export const initGetDisclosureJson = (query) => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_SERVICE_URL}/${query}`)
        .then(response => {
            if (query !== "disclosureSet") {
                dispatch(getDisclosureJson(response.data))
            } else {
                dispatch(getDisclosureJson(response.data.disclosureSet))
            }
        })
        .catch(error => {
            dispatch(getDisclosureJsonFailed());
        });
    }
}

// API call to get the disclosure set json
export const initGetSetJson = () => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_SERVICE_URL}/disclosureSet`)
        .then(response => {
            dispatch(getSetJson(response.data.listOfButtons, response.data.listOfTerms))
        })
        .catch(error => {
            dispatch(getDisclosureJsonFailed());
        });
    }
}

// API call to update the disclosure item
export const initUpdateCreateDisclosureJson = (query, json, method) => {
    return dispatch => {
        axios.post(`${process.env.REACT_APP_SERVICE_URL}/${query}`, json)
        .then(response => {
            if (method === "create") {
                dispatch(savingNewDisclosure())
            }
        })
        .catch(error => {
            dispatch(getDisclosureJsonFailed());
        });
    }
}

// API call to create the disclosure item
export const initCreateDisclosureJson = (query, json) => {
    return {
        type: actionTypes.CREATE_DISCLOSURE_JSON,
        disclosureGetAllJson: json
    }
}

// Sets the state for disclosures to true
export const savingNewDisclosure = () => {
    return {
        type: actionTypes.SAVING_NEW_DISCLOSURE
    }
}