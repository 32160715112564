import MissionControl from './Containers/MissionControl/MissionControl';

import './App.scss';

function App() {
  return (
    <MissionControl />
  );
}

export default App;