import * as actionTypes from './ActionTypes';
import axios from 'axios';
import { setSuccessAlert } from './AdIDManager';

// Sets the billing json with the returned json from the API call
export const getBillingJson = (json, page) => {
    if (page === "bin-list") {
        return {
            type: actionTypes.GET_BIN_LIST_JSON,
            billingJson: json.cascadeBinInfoList,
            brandIds: json.brandAcronyms
        }
    } else if (page === "cascade-bypass") {
        return {
            type: actionTypes.GET_CASCADE_BYPASS_LIST_JSON,
            billingJson: json.uctList,
            cascadeBypassHistory: json.uctHistory,
            brandIds: json.brandAcronyms
        }
    }
}

export const getBillingPartners = (data) => {
  return {
    type: actionTypes.SET_BILLING_JSON_BY_PARTNER_ID,
    billingJsonByPartnertId: data,
  };
};

export const getBillingPartnersLoading = (loading) => {
  return {
    type: actionTypes.SET_BILLING_JSON_BY_PARTNER_ID_LOADING,
    billingJsonByPartnertIdLoading: loading,
  };
};

// Sets the brands with the returned json from the API call
export const getBillingBrands = (json) => {
        return {
            type: actionTypes.GET_BIN_LIST_JSON,
            billingJson: [],
            brandIds: json
        }
}

// Sets the error state to true if there was an error response from the API call
export const getBillingJsonFailed = () => {
    return {
        type: actionTypes.GET_BIN_LIST_JSON_FAILED
    }
}

// API call to get the list of cascade bins
export const initGetBinListJson = () => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_REALTYSTORE_SERVICE_URL}/cascadeBin/getAllCascadeBins.ajax?firstResult=0&maxResults=1000`, {
            headers: {
                Authorization: `bearer ${process.env.REACT_APP_BEARER_TOKEN}`
            }
        })
        .then(response => {
            dispatch(getBillingBrands(response.data, 'bin-list'))
        })
        .catch(error => {
            dispatch(getBillingJsonFailed());
        });
    }
}

// API call to get the list of brand Ids
export const initGetBrandIds = () => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_REALTYSTORE_SERVICE_URL}/cascadeBin/brands.ajax`, {
            headers: {
                Authorization: `bearer ${process.env.REACT_APP_BEARER_TOKEN}`
            }
        })
        .then(response => {
            dispatch(getBillingBrands(response.data))
        })
        .catch(error => {
            dispatch(getBillingJsonFailed());
        });
    }
}

// API call to get the list of cascade bins by brand id and bin type
export const getBinListJsonByBrandIdandBinType = (brandId, binType) => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_REALTYSTORE_SERVICE_URL}/cascadeBin/binlist.ajax?brandId=${brandId}&cascadeBinType=${binType}`, {
            headers: {
                Authorization: `bearer ${process.env.REACT_APP_BEARER_TOKEN}`
            }
        })
        .then(response => {
            dispatch(getBillingJson(response.data, 'bin-list'))
        })
        .catch(error => {
            dispatch(getBillingJsonFailed());
        });
    }
}

// API call to get the list of cascade bins by bin number
export const getBinListJsonByBinNumber = (binNumber) => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_REALTYSTORE_SERVICE_URL}/cascadeBin/binlist.ajax?binNumber=${binNumber}`, {
            headers: {
                Authorization: `bearer ${process.env.REACT_APP_BEARER_TOKEN}`
            }
        })
        .then(response => {
            dispatch(getBillingJson(response.data, 'bin-list'))
        })
        .catch(error => {
            dispatch(getBillingJsonFailed());
        });
    }
}

// API call to get the list of cascade bins by bin number
export const getBinListJsonByPartnerId = (partnertId) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_REALTYSTORE_SERVICE_URL}/cascadeBypass/getCascadeBypassByPartnerId.ajax?partnerId=${partnertId}`,
        {
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
          },
        }
      )
      .then((response) => {
        dispatch(getBillingPartners(response.data));
        dispatch(getBillingPartnersLoading(false))
      })
      .catch((error) => {
        dispatch(getBillingPartnersLoading(false))
        dispatch(getBillingPartners([]));
        dispatch(getBillingJsonFailed());
      });
  };
};

// API call to update the specific bin list
export const initUpdateBinListJson = (json) => {
    return dispatch => {
        axios.post(`${process.env.REACT_APP_REALTYSTORE_SERVICE_URL}/cascadeBin/updateCascadeBinInfo.ajax?cascadeBinInfoJson=${JSON.stringify(json)}`, 
        {},
        {
            headers: {
                Authorization: `bearer ${process.env.REACT_APP_BEARER_TOKEN}`
            }
        })
        .then(response => {
        })
        .catch(error => {
            dispatch(getBillingJsonFailed());
        })
    }
}

// API call to create a new bin list
export const initCreateBinListJson = (json) => {
    if (json?.ticket?.length < 4 || !json?.ticket || json?.reasonBinCreated?.length < 3 || !json?.reasonBinCreated) {
        return dispatch => {
            return false
        }
    }
    return dispatch => {
        let parsedId = parseInt(json.brandId);
        json.brandId = parsedId;

        let newBin = parseInt(json.bin);
        json.bin = newBin;

        json.reasonBinCreated = `${json.ticket} - ${json.reasonBinCreated}`

        axios.post(`${process.env.REACT_APP_REALTYSTORE_SERVICE_URL}/cascadeBin/addCascadeBinInfo.ajax?cascadeBinInfoJson=${JSON.stringify(json)}`, 
        {},
        {
            headers: {
                Authorization: `bearer ${process.env.REACT_APP_BEARER_TOKEN}`
            }
        })
        .then(response => {
            dispatch(savingNewBilling())
        })
        .catch(error => {
            dispatch(getBillingJsonFailed());
        })
    }
}

// Sets the state for billing to true
export const savingNewBilling = () => {
    return {
        type: actionTypes.SAVING_NEW_BILLING_MANAGER
    }
}

// API call to get the list of cascade bypasses
export const initGetCascadeBypassJson = () => {
    return dispatch => {
        axios.get(`${process.env.REACT_APP_REALTYSTORE_SERVICE_URL}/cascadeBypass/getCascadeBypassUCT.ajax`, {
            headers: {
                Authorization: `bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
            }
        })
        .then(response => {
            // let data = {
            //     brandAcronyms: response.data.brandAcronyms,
            //     uctHistory: response.data.uctHistory,
            //     uctList: []
            // };
            // for (let i = 0; i < response.data.uctList.length; i++) {
            //     if (response.data.uctList[i].adId.includes('TEST')) data.uctList.push(response.data.uctList[i])
            // }
            // console.log(data)
            dispatch(getBillingJson(response.data, 'cascade-bypass'))
        })
        .catch(error => {
            dispatch(getBillingJsonFailed());
        });
    }
}

// API call to update a cascade bypass 
export const updateCascadeBypass = (id, json) => {
    if (json?.ticket?.length < 4 || !json?.ticket || json?.comment?.length < 3 || !json?.comment) {
        return dispatch => {
            return false
        }
    }
    const revisionComment = `${json.ticket} - ${json.comment}`

    let newDate = new Date();
    let isoDate = newDate.toISOString();
    const formattedJson = {
        name: json.binType,
        id: id,
        active: json.status === 'active',
        brandId: json.brandId,
        adId: json.adid,
        partnerId: json.partnerId,
        value: json.value,
        lastModifyingUser: json.userUpdated,
        lastModified: isoDate,
        revisionComment: revisionComment, 
    }

    return dispatch => {
        axios.post(`${process.env.REACT_APP_REALTYSTORE_SERVICE_URL}/cascadeBypass/updateCascadeBypassUCT.ajax?cascadeBypassJson=${JSON.stringify(formattedJson)}`, 
        {},
        {
            headers: {
                Authorization: `bearer ${process.env.REACT_APP_BEARER_TOKEN}`
            }
        })
        .then(response => {
            if (response.data) {
                dispatch(setSuccessAlert("Successfully updated Cascade Bypass"))
                setTimeout(() => {
                    dispatch(setSuccessAlert(null))
                }, 5000)
            }
        })
        .catch(error => {
            console.log(error)
        })
    }
}

// API call to create a new cascade bypass
export const createCascadeBypass = (json) => {
    let newDate = new Date();
    let isoDate = newDate.toISOString();
    if (json?.ticket?.length < 4 || !json?.ticket || json?.comment?.length < 3 || !json?.comment) {
        return dispatch => {
            return false
        }
    }
     
    const formattedJson = {
        name: json.cascadeType,
        brandId: parseInt(json.brandId),
        adId: json.adid,
        partnerId: parseInt(json.partnerId),
        value: json.value,
        lastModifyingUser: json.userCreated,
        lastModified: isoDate,
        description: `${json.comment}`, 
        revisionComment: `${json.ticket}`,
        creditCardType: "any", 
        creditCardCategory: "any", 
        cascadeType: json.cascadeType,
    }
    
    return dispatch => {
        axios.post(`${process.env.REACT_APP_REALTYSTORE_SERVICE_URL}/cascadeBypass/addCascadeBypassUCT.ajax?cascadeBypassJson=${JSON.stringify(formattedJson)}`, 
        {},
        {
            headers: {
                Authorization: `bearer ${process.env.REACT_APP_BEARER_TOKEN}`
            }
        })
        .then(response => {
            if (response.data) {
                dispatch(setSuccessAlert("Successfully created Cascade Bypass"))
                setTimeout(() => {
                    dispatch(setSuccessAlert(null))
                }, 5000)
            }
        })
        .catch(error => {
            console.log(error);
        })
    }
}