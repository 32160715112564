import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as actionTypes from '../../Store/Actions/ContentManager';

import {Container, Row, Col, Spinner, Alert} from 'react-bootstrap';
import EditableCard from '../../Components/Cards/EditableCard/EditableCard';
import CreateButton from '../../Components/Buttons/CreateButton/CreateButton';

import './ContentManager.scss';

class ContentManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getContentManagerJson: null
        };
    }

    componentDidMount() {
        // Trigger the get function that makes the API call to get the json needed for this page
        this.props.onInitGetContentManagerJson(this.props.endpoint);
    }

    componentDidUpdate(prevProps) {
        // If the contentManager property has updated set the "getContentManagerJson" state variable to the updated contentManager property variable
        if (this.props.contentManager !== prevProps.contentManager) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    getContentManagerJson: this.props.contentManager
                }
            });
        }
    }

    render() { 
        let contentManagerContent;

        if (this.state.getContentManagerJson === null) {
            // If the getContentManagerJson state variable is null, show a spinner
            contentManagerContent = (
                <Row className="mt-5 pt-5">
                    <Col className="text-center">
                        <Spinner size="lg" animation="border" variant="primary" className="m-auto" />
                    </Col>
                </Row>
            );
        } else if(this.props.error) {
            // If there are errors, then show the error alert message
            contentManagerContent = (
                <Row className="mt-5 pt-5 justify-content-center">
                    <Col>
                        <Alert variant="danger" className="text-center">
                            There was an issue getting the content for this page! Please refresh the page to try again!
                        </Alert>
                    </Col>
                </Row>
            )
        } else {
            // If the "getContentManagerJson" state variable has content and is NOT null
            let content = this.state.getContentManagerJson.map((key,index) => {
                let updatedJson = {
                    headerJson: {},
                    realHeaderJson: {},
                    bodyJson: {}
                }
                
                for (const item in key) {
                    if (item.toLowerCase().indexOf('id') !== -1) {
                        updatedJson.realHeaderJson[item] = key[item]
                        updatedJson.headerJson.id = key[item]
                    } else if (item.toLowerCase().indexOf('name') !== -1) {
                        updatedJson.realHeaderJson[item] = key[item]
                        updatedJson.headerJson.name = key[item]
                    } else {
                        updatedJson.bodyJson[item] = key[item]
                    }
                }

                return (
                    <Col key={index}>
                        <EditableCard realHeaderJson={updatedJson.realHeaderJson} {...this.props} headerJson={updatedJson.headerJson} bodyJson={updatedJson.bodyJson} cardLabel={this.props.page} category="contentManager" />
                    </Col>
                )
            })

            // Sorts the Editable Cards by the headerJson key "name" value 
            content.sort((a, b) => {
                let textA = a.props.children.props.headerJson.name.toLowerCase();
                let textB = b.props.children.props.headerJson.name.toLowerCase();

                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            })

            contentManagerContent = (
                <div>
                    <Row className="mb-4 pb-3">
                        <Col>
                            <CreateButton {...this.props} content={this.state.getContentManagerJson} buttonText={this.props.btnText} />
                        </Col>
                    </Row>
                    <Row>
                        <div className="card-columns w-100">
                            {content}
                        </div>
                    </Row>
                </div>
            )
        }
        
        return ( 
            <Container fluid className="content-manager-content py-4 my-3">
                {contentManagerContent}
            </Container>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        contentManager: state.contentManager.contentManagerJson,
        error: state.contentManager.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitGetContentManagerJson: (query) => dispatch(actionTypes.initGetContentManagerJson(query))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ContentManager);