import React, {Component} from 'react';

import {Col, ListGroup} from 'react-bootstrap';
import SetButtons from '../Buttons/SetButtons/SetButtons';

import './ItemSet.scss';

class ItemSet extends Component {
    
    render() { 
        // Sets what items are contained within the list group for the Item Set
        let set = Object.keys(this.props.setJson).map((key, index) => {
            return (
                <ListGroup.Item key={`${key}-${index}`} className={`item p-0 pr-3 ${index !== 0 ? ' pl-3' : ''} ${key.replace(" ", "-")} ${this.props.setJson[key][0]?.width !== undefined ? `width-${this.props.setJson[key][0].width}` : ''} ${this.props.setJson[key][2] !== undefined && this.props.setJson[key][2].buttons !== undefined ? 'd-flex flex-wrap flex-lg-nowrap' : ''} ${this.props.setJson[key][2] !== undefined && this.props.setJson[key][2].hide !== undefined && this.props.setJson[key][2].hide === true ? 'd-none' : ''}`} xs={4}>
                    {this.props.itemSetNoLabel === true ? '' : <p className="font-weight-bold text-uppercase text-small mb-1 item-label">{key.endsWith("s") || this.props.endsWith === false ? key : `${key}s`}</p>}
                    <p className={`mb-0 item-name ${this.props.setJson[key][0]?.boldFont === true ? 'font-weight-bold' : 'font-weight-light'} ${this.props.setJson[key][2] !== undefined && this.props.setJson[key][2].buttons !== undefined ? 'mr-auto' : ''} text-truncate align-self-center`}>{this.props.setJson[key][1]?.name}</p>
                    <p hidden>{this.props.setJson[key][0]?.id}</p>
                    {this.props.setJson[key][2] !== undefined && this.props.setJson[key][2].buttons !== undefined ? <SetButtons buttons={this.props.setJson[key][2].buttons} tooltip={this.props.setJson[key][2].tooltip} category={key} setBtnHandler={this.props.setBtnHandler} /> : ''}
                </ListGroup.Item>
            )
        })

        return ( 
            <div className="item-set flex-fill">
                <Col className="pl-0">
                <ListGroup horizontal className="d-flex flex-fill align-items-center">
                    {set}
                </ListGroup>
                </Col>
            </div>
         );
    }
}
 
export default ItemSet;