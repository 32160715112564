import React from 'react';

const Clipboard = () => {
    return ( 
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 512 512" className="clipboard-icon" fill="#FCA311">
            <path className="st0" d="M352,213.3H160c-11.8,0-21.3,9.5-21.3,21.3S148.2,256,160,256h192c11.8,0,21.3-9.5,21.3-21.3
            C373.3,222.9,363.8,213.3,352,213.3z"/>
            <path className="st0" d="M352,320H160c-11.8,0-21.3,9.5-21.3,21.3s9.6,21.3,21.3,21.3h192c11.8,0,21.3-9.5,21.3-21.3
            S363.8,320,352,320z"/>
            <path className="st0" d="M394.7,64h-22.4C367,27.9,336.2,0,298.7,0h-85.3c-37.5,0-68.4,27.9-73.6,64h-22.4c-35.3,0-64,28.7-64,64v320
            c0,35.3,28.7,64,64,64h277.3c35.3,0,64-28.7,64-64V128C458.7,92.7,430,64,394.7,64z M213.3,42.7h85.3c17.6,0,32,14.4,32,32
            s-14.4,32-32,32h-85.3c-17.6,0-32-14.4-32-32C181.3,57,195.7,42.7,213.3,42.7z M416,448c0,11.8-9.6,21.3-21.3,21.3H117.3
            c-11.8,0-21.3-9.6-21.3-21.3V128c0-11.8,9.6-21.3,21.3-21.3h28.8c12,25.1,37.5,42.7,67.2,42.7h85.3c29.7,0,55.1-17.5,67.2-42.7
            h28.8c11.8,0,21.3,9.6,21.3,21.3L416,448L416,448z"/>
        </svg>
    );
}
 
export default Clipboard;