import React, {useState, useEffect, useRef} from 'react';

import {Form} from 'react-bootstrap';

import EditSaveButton from '../../../Components/Buttons/EditSaveButton/EditSaveButton';

const AdidManagerTableRows = (props) => {
    const textareaRef = useRef(null);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const scrollHt = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHt + "px";
    }, [props.rowKey]);

    // Set the edit mode and save changes to the UCT entry value
    let editHandler = (editMode, event) => {
        let cardBody = document.getElementsByClassName('card-body')[0].children;
        let section = cardBody.item(3);

        if (document.getElementsByClassName('jiraReference')[0].getElementsByClassName('form-control')[0].value !== '') {
            section.getElementsByClassName('text-danger')[0].classList.add('d-none')
            setEditMode(editMode);

            if (!editMode) {
                let newValue = event.target.closest('.form-group').getElementsByTagName('textarea')[0].value;
                let id = event.target.closest('tr').getElementsByClassName('id')[0].textContent;
                let jiraReference = event.target.closest('.editable-card').getElementsByClassName('jiraReference')[0].getElementsByClassName('form-control')[0].value;

                props.updateHandler(newValue, id, jiraReference)
            }
        } else {
            section.getElementsByClassName('text-danger')[0].classList.remove('d-none')
            section.getElementsByClassName('text-danger')[0].textContent = (
                "A Jira Reference is required. Please fill out the Jira Reference field in the proper format (example: ADEV-0000) at the top of the page and save the change before updating a UCT Entry."
            )
        }
        
    }
    // variable - hide rows based on filters
    let hideRow;
    // variable - hide rows based on search filter 
    let hideSearchRow;

    // Go through the filter options and looks for the proper column in the table row to see if that value is not equal to -1 or any
    if (props.tableFilters !== undefined) {
        if (props.tableFilters.includes('all')) {
            hideRow = false;
        } else {
            for (let i = 0; i < props.tableFilters.length; i++) {
                if (props.tableFilters[i].indexOf('searchText') === -1) {
                    if ((props.rowKey[props.tableFilters[i]].indexOf("any") !== -1 || props.rowKey[props.tableFilters[i]].indexOf("-1") !== -1) && hideRow !== false) {
                        hideRow = true;
                    } else {
                        hideRow = false;
                    }
                }
            }
        }
    }

    // If there is search text used as part of the filters, then go through and see if the table rows have any matching text
    if (props.tableFilters.some(filter => filter.indexOf("searchText") !== -1)) {
        for (let i = 0; i < props.tableFilters.length; i++) {
            if (props.tableFilters[i].indexOf('searchText') !== -1) {
                let searchText = props.tableFilters[i].split(' ')[1];

                for (const item in props.rowKey) {
                    if (props.rowKey[item].toLowerCase().indexOf(searchText.toLowerCase()) !== -1 && hideSearchRow !== false) {
                        hideSearchRow = false
                    } else if (hideSearchRow !== false) {
                        hideSearchRow = true
                    }
                }
            }
        }
    }
    
    return (
        <tr key={`${props.rowKey}.${props.rowIndex}`} className={`${hideSearchRow !== undefined ? (hideRow === false || hideRow === undefined) && hideSearchRow === false ? '' : 'd-none' : hideRow ? 'd-none' : ''}`}>
            {Object.keys(props.rowKey).map((keyTwo, indexTwo) => {
                return <td key={`${keyTwo}-${indexTwo}`} className={`${keyTwo} ${typeof props.rowKey[keyTwo] === "string" && props.rowKey[keyTwo].indexOf('hideColumn') !== -1 ? 'd-none' : ''}`}>{typeof props.rowKey[keyTwo] === "string" && props.rowKey[keyTwo].indexOf('hideColumn') !== -1 ? props.rowKey[keyTwo].replace('hideColumn ', '') : props.rowKey[keyTwo].indexOf('editable') !== -1 ? 
                <Form.Group className={`position-relative ${!editMode ? 'resize' : 'editing'}`}>
                    <EditSaveButton editHandler={editHandler} editMode={editMode} disableEditing={props.disableEditing} />
                    <Form.Control ref={textareaRef}  className={`w-100 ${!editMode ? 'pt-0' : ''}`} as="textarea" defaultValue={props.rowKey[keyTwo].replace('editable ', '')} readOnly={!editMode} plaintext={!editMode} />
                </Form.Group>
                : props.rowKey[keyTwo]}</td>
            })}
        </tr>
    )
}
 
export default AdidManagerTableRows;