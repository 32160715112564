import React, {Component} from 'react';
import {TransitionGroup} from 'react-transition-group';

import TextEditor from '../../../TextEditor/TextEditor';
import ItemSet from '../../../ItemSet/ItemSet';
import EditableDropdown from '../../../Dropdown/EditableDropdown/EditableDropdown';
import MultipleEditableInputs from '../../../Inputs/MultipleEditableInputs/MultipleEditableInputs';
import TranslateOpacityAnimation from '../../../AnimationWrappers/TranslateOpacityAnimation/TranslateOpacityAnimation';
import EditSaveButton from '../../../Buttons/EditSaveButton/EditSaveButton';
import ActiveInactiveButton from '../../../Buttons/ActiveInactiveButton/ActiveInactiveButton';
import EditableInput from '../../../Inputs/EditableInput/EditableInput';
import CollapseContent from '../../../CollapseContent/CollapseContent';

import {Row, Col, Accordion} from 'react-bootstrap';

class EditableBody extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    render() { 
        let content;
        
        // If else coniditons to determine what will be shown in the Editable Body portion of the Editable Card
        if (this.props.bodyContent === "text editor") {
            content = (
                    <TextEditor {...this.props} />
            )
        } else if (this.props.bodyContent === "item set") {
            if (!this.props.editMode) {
                content = (
                    <ItemSet {...this.props} />
                )
            } else if (this.props.editMode) {
                content = Object.keys(this.props.setJson).map((key, index) => {
                    // If the field has an key/value pair for "field" then it will use the first if CSSConditionRule. Otherwise, it defaults to a dropdown
                    if (this.props.setJson[key][2] !== undefined && this.props.setJson[key][2].field !== undefined) {
                        if (this.props.setJson[key][2].field === "input") {
                            return (
                                <EditableInput placeholder={this.props.setJson[key][1].placeholder} key={`${key}-${index}`} jsonKey={this.props.setJson[key][1].realBinTypeName} body={true} {...this.props} label={this.props.setJson[key][1].name} name={this.props.setJson[key][1].default} required={this.props.setJson[key][2].required} type={this.props.setJson[key][2].type} />
                            )
                        } else {
                            return (
                                <EditableDropdown default={this.props.setJson[key][1]?.default || ''} key={`${key}-${index}`} {...this.props} objKey={key} objValue={this.props.setJson[key]} index={index} required={this.props.setJson[key][2] !== undefined ? this.props.setJson[key][2].required : ""} />
                            )
                        }
                    } else {
                        return (
                            <EditableDropdown default={this.props.setJson[key][1]?.default || ''} key={`${key}-${index}`} {...this.props} objKey={key} objValue={this.props.setJson[key]} index={index} required={this.props.setJson[key][2] !== undefined ? this.props.setJson[key][2].required : ""} />
                        )
                    }
                });
            }
        } else if (this.props.bodyContent === "multiple inputs") {
            // This is the if condition of the Editable Body uses more than one Editable Input
            content = (
                    <MultipleEditableInputs {...this.props} />
            )
        } else if (this.props.bodyContent === "active item set") {
            // This is the if condition for if the Editable Body layout is Active/Inactive Button ---> Item Set -----> Edit Save Button
            content = (
                <div className="position-relative d-flex">
                    <ActiveInactiveButton {...this.props} />
                    <ItemSet {...this.props} />
                    <EditSaveButton {...this.props} />
                </div>
            )
        }  else if (this.props.bodyContent === "item set collapse") {
            // This is the if condition for a card with an item set layout and a collapsable component
            let toggleBtnText = (event) => {
                if (event.target.textContent === this.props.collapsedBtnText) {
                    event.target.textContent = this.props.nonCollapsedBtnText
                } else if (event.target.textContent === this.props.nonCollapsedBtnText) {
                    event.target.textContent = this.props.collapsedBtnText
                }
            }

            content = (
                <Accordion>
                    <Row>
                        <Col>
                            <ItemSet {...this.props} />
                            <Accordion.Toggle eventKey={this.props.collapseIndex.toString()} className={`collapse-btn ${this.props.collapseBtn} btn btn-primary rounded-pill`} onClick={toggleBtnText}>
                                View History
                            </Accordion.Toggle>
                        </Col>
                        <Col xs={1}>
                            <EditSaveButton {...this.props} />
                        </Col>
                    </Row>
                    <Row className="collapse-section">
                        <Col>
                        <Accordion.Collapse
                                eventKey={this.props.collapseIndex.toString()}
                                onEntered={elem => elem.classList.remove("collapse")}>
                                <div className="mt-4 pt-4 border-top">
                                    <CollapseContent collapseContent={this.props.collapseContent} {...this.props} />
                                </div>
                            </Accordion.Collapse>
                        </Col>
                    </Row>
                </Accordion>
            )
        } else if (this.props.bodyContent === "inputs only") {
            content = Object.keys(this.props.bodyJson).map((key, index) => {
                let disableField, maxLength;
                
                if (this.props.maxLengthOptions !== undefined) {
                    for (let i = 0; i < this.props.maxLengthOptions.length; i++) {
                        if(this.props.maxLengthOptions[i].name === key) {
                            maxLength = this.props.maxLengthOptions[i].length
                        }
                    }
                }

                if(this.props.disabledFields !== undefined && this.props.disabledFields.indexOf(key) !== -1) {
                    disableField = true;
                }
                return (
                    <EditableInput key={`${key}-${index}`} jsonKey={key} body={true} {...this.props} label={key} name={this.props.bodyJson[key]} required={this.props.requiredFields.includes(key)} disableEditing={disableField} maxLength={maxLength} />
                )
            })
        }

        return ( 
            <>
            <TransitionGroup>
                <TranslateOpacityAnimation transitionAnimate={this.props.transitionAnimate}>
                    <div>
                        {content}
                    </div>
                </TranslateOpacityAnimation>
            </TransitionGroup>
            </>
         );
    }
}
 
export default EditableBody;