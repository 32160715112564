import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as actionTypes from '../../Store/Actions/Billing';

import {Container, Row, Col, Spinner, Alert} from 'react-bootstrap';
import EditableCard from '../../Components/Cards/EditableCard/EditableCard';
import { withRouter } from 'react-router';


import './Billing.scss';

class Billing extends Component {
    constructor(props) {
        super(props);

        let getCreateJson, filters;

        if (this.props.page === "bin profile") {
            getCreateJson = this.props.binList
            getCreateJson.ticket = ''
        } else if (this.props.page === "bin bypass") {
            getCreateJson = {};
            getCreateJson.partnerId = ''
            getCreateJson.status = 'active'
            getCreateJson.brandId = -1
            getCreateJson.value = 0
            getCreateJson.adid = 'any'
            getCreateJson.superPartnerId = -1
            getCreateJson.binType = 'casP'
            getCreateJson.ticket = ''
            getCreateJson.comment = ''
            getCreateJson.cascadeType = "casP";
        }

        // If the binListFilters json does not contain options for the second item in the array, then set the filters variable to null. Otherwise, use the binListFilters property variable
        if (Object.keys(this.props.binListFilters[1].options).length === 0) {
            filters = null
        } else {
            filters = this.props.binListFilters
        }

        this.state = {
            getCreateJson: getCreateJson,
            binListFilters: filters
        };
    }

    componentDidMount() {
        // If the binListFilters property variable does not include options for the second item in the array, then make the API call so these options can be added
        if (!Object.keys(this.props.binListFilters[1].options).length) {
            this.props.onInitGetBinListJson();
        }
    }

    componentDidUpdate(prevProps) {
        // If the component updated and the billing property is different from the previous version of it, then update it with the newly updated binListFilters property
        if (this.props.billing !== prevProps.billing) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    binListFilters: this.props.binListFilters
                }
            });
        }
    }

    render() {
        let billingContent;

        if (this.state.binListFilters === null && !this.props.error) {
            // If the binListFilters state variable is null and there are no errors, show a spinner
            billingContent = (
                <Row className="mt-5 pt-5">
                    <Col className="text-center">
                        <Spinner size="lg" animation="border" variant="primary" className="m-auto" />
                    </Col>
                </Row>
            );
        } else if(this.props.error) {
            // If there are errors, then show the error alert message
            billingContent = (
                <Row className="mt-5 pt-5 justify-content-center">
                    <Col>
                        <Alert variant="danger" className="text-center">
                            There was an issue getting the content for this page! Please refresh the page to try again!
                        </Alert>
                    </Col>
                </Row>
            )
        } else {
            // If the "getCreateJson" state variable has content and is NOT null
            let updatedJson = {
                headerJson: {},
                realHeaderJson: {},
                bodyJson: {},
                setJson: {}
            }
    
            let dropdownOptions;
    
            if (this.props.page === "bin profile") {
                // If the page is "bin profile" execute this specific code that is used for dropdown fields
                updatedJson.setJson["bin description"] = [];
                updatedJson.setJson["ticket"] = [];
                updatedJson.setJson["bin type"] = [];
                updatedJson.setJson["brand"] = [
                    {id: "brand", realBinTypeId: "brand"},
                    {name: "", realBinTypeName: "brandId"},
                    {required: true}
                ];
                updatedJson.setJson["bin status"] = [];
    
                dropdownOptions = [{"type": ""}, {"type": ""}, {"type": ""}];
    
                dropdownOptions[0] = {"casB": "casB", "casD": "casD", "casP": "casP", "blacklist": "blacklist"};
                dropdownOptions[0]["type"] = "bin type";
                dropdownOptions[1] = {"true": "active", "false": "inactive"};
                dropdownOptions[1]["type"] = "bin status";
                dropdownOptions[2] = this.state.binListFilters[1].options.brandAcronyms ? this.state.binListFilters[1].options.brandAcronyms : this.state.binListFilters[1].options;
                delete dropdownOptions[2]["0"];
                dropdownOptions[2]["type"] = "brand";
    
                for (const item in this.state.getCreateJson) {
                    if (item.toLowerCase().indexOf('bintype') !== -1) {
                        updatedJson.bodyJson[item] = this.state.getCreateJson[item]
                        updatedJson.setJson["bin type"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                        updatedJson.setJson["bin type"].push({name: this.state.getCreateJson[item],realBinTypeName: item})
                        updatedJson.setJson["bin type"].push({required: true})
                        updatedJson.bodyJson[item] = this.state.getCreateJson[item];
                    } else if (item.toLowerCase().indexOf('active') !== -1) {
                        updatedJson.setJson["bin status"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                        updatedJson.setJson["bin status"].push({name: this.state.getCreateJson[item],realBinTypeName: item})
                        updatedJson.setJson["bin status"].push({required: true})
                        updatedJson.bodyJson[item] = this.state.getCreateJson[item];
                    } else if (item.toLowerCase().indexOf('reasonbincreated') !== -1) {
                        updatedJson.setJson["bin description"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                        updatedJson.setJson["bin description"].push({name: "bin description",realBinTypeName: item})
                        updatedJson.setJson["bin description"].push({field: "input", required: true})
                        updatedJson.bodyJson[item] = this.state.getCreateJson[item];
                    } else if (item.toLowerCase().indexOf('ticket') !== -1) {
                        updatedJson.setJson["ticket"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                        updatedJson.setJson["ticket"].push({name: "ticket",realBinTypeName: item})
                        updatedJson.setJson["ticket"].push({field: "input", required: true})
                        updatedJson.bodyJson[item] = this.state.getCreateJson[item];
                    } else if (item === "bin") {
                        updatedJson.realHeaderJson[item] = this.state.getCreateJson[item]
                        updatedJson.headerJson.name = this.state.getCreateJson[item]
                        updatedJson.headerJson.maxLength = 6;
                        updatedJson.headerJson.required = true;
                        updatedJson.headerJson.type = "number";
                    } else {
                        updatedJson.bodyJson[item] = this.state.getCreateJson[item];
                    }
                }
            } else if (this.props.page === "bin bypass") {
                const { state } = this.props.location;

                updatedJson.setJson["partner"] = [];
                updatedJson.setJson["value"] = [];
                updatedJson.setJson["adid"] = [];
                updatedJson.setJson["ticket"] = [];
                updatedJson.setJson["comment"] = [];
                // updatedJson.setJson["superPartnerId"] = [];
                // updatedJson.setJson["verticalType"] = [];
                updatedJson.setJson["brand"] = [
                    {id: "brand", realBinTypeId: "brand"},
                    {name: "", realBinTypeName: "brandId", default: state?.brandAcronym || -1},
                    {required: true}
                ];
                updatedJson.bodyJson.brandId = state?.brandId ?? -1;
                updatedJson.setJson["status"] = [];
                updatedJson.setJson["cascadeType"] = [];

                dropdownOptions = [
                    { type: "" },
                    { type: "" },
                    { type: "cascadeType", casP: "CasP", casB: "CasB" },
                ];

                dropdownOptions[0] = {"true": "active", "false": "inactive"};
                dropdownOptions[0]["type"] = "status";
                dropdownOptions[1] = this.state.binListFilters[1].options.brandAcronyms ? this.state.binListFilters[1].options.brandAcronyms : this.state.binListFilters[1].options;
                delete dropdownOptions[1]["0"];
                dropdownOptions[1]["type"] = "brand";

                for (const item in this.state.getCreateJson) {
                    if (item === "partnerId") {
                        updatedJson.setJson["partner"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                        updatedJson.setJson["partner"].push({name: "partner",realBinTypeName: item, placeholder: "enter cascade id", default: state?.partnerId || null})
                        updatedJson.setJson["partner"].push({field: "input"})
                        updatedJson.bodyJson.partnerId = state?.partnerId ?? this.state.getCreateJson[item];
                    // } else if (item === "superPartnerId") {
                    //     updatedJson.setJson["superPartnerId"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                    //     updatedJson.setJson["superPartnerId"].push({name: "superPartnerId",realBinTypeName: item, default: state?.superPartnerId || -1})
                    //     // updatedJson.setJson["superPartnerId"].push({field: "input"})
                    //     updatedJson.bodyJson.superPartnerId = state?.superPartnerId ?? this.state.getCreateJson[item];
                    // } else if (item === "verticalType") {
                    //     updatedJson.setJson["verticalType"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                    //     updatedJson.setJson["verticalType"].push({name: "verticalType",realBinTypeName: item, default: state?.verticalType || -1})
                    //     // updatedJson.setJson["superPartner"].push({field: "input"})
                    //     updatedJson.bodyJson.verticalType = state?.verticalType ?? this.state.getCreateJson[item];
                    } else if (item === "value") {
                        updatedJson.setJson["value"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                        updatedJson.setJson["value"].push({name: "value",realBinTypeName: item, default: state?.value || 0})
                        updatedJson.setJson["value"].push({field: "input"})
                        updatedJson.bodyJson.value = state?.value ?? this.state.getCreateJson[item]
                    } else if (item === "status") {
                        updatedJson.setJson["status"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                        updatedJson.setJson["status"].push({name: this.state.getCreateJson[item],realBinTypeName: item, default: state?.active === false ? 'inactive' : 'active'})
                        updatedJson.setJson["status"].push({required: true})
                        updatedJson.bodyJson.status = state?.active === false ? 'inactive' : 'active';
                    } else if (item === "cascadeType") {
                        updatedJson.setJson["cascadeType"].push({
                          id: this.state.getCreateJson[item],
                          realBinTypeId: item,
                        });
                        updatedJson.setJson["cascadeType"].push({
                          name: this.state.getCreateJson[item],
                          realBinTypeName: item,
                          default: "CasP",
                        });
                        updatedJson.setJson["cascadeType"].push({ required: true });
                        updatedJson.bodyJson.cascadeType = this.state.getCreateJson[item];
                    } else if (item === "adid") {
                        updatedJson.setJson["adid"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                        updatedJson.setJson["adid"].push({name: "adid",realBinTypeName: item, default: state?.adId || 'any'})
                        updatedJson.setJson["adid"].push({field: "input"})
                        updatedJson.bodyJson.adid = state?.adId ?? this.state.getCreateJson[item];
                    } else if (item === "ticket") {
                        updatedJson.setJson["ticket"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                        updatedJson.setJson["ticket"].push({name: "ticket",realBinTypeName: item, default: state?.ticket || ''})
                        updatedJson.setJson["ticket"].push({field: "input", required: true})
                        updatedJson.bodyJson.adid = state?.ticket ?? this.state.getCreateJson[item];
                    } else if (item === "comment") {
                        updatedJson.setJson["comment"].push({id: this.state.getCreateJson[item],realBinTypeId: item})
                        updatedJson.setJson["comment"].push({name: "comment",realBinTypeName: item, default: state?.comment || ''})
                        updatedJson.setJson["comment"].push({field: "input", required: true})
                        updatedJson.bodyJson.adid = state?.comment ?? this.state.getCreateJson[item];
                    } else {
                        updatedJson.bodyJson[item] = state?.[item] ?? this.state.getCreateJson[item];
                    }
                }
                updatedJson.bodyJson.pastRevisionComments = state?.revisionComment || null
                updatedJson.bodyJson.adid = state?.adId ?? this.state.getCreateJson["adid"];
            } else {
                for (const item in this.state.getCreateJson) {
                    if (item.toLowerCase().indexOf('id') !== -1) {
                        updatedJson.realHeaderJson[item] = this.state.getCreateJson[item]
                        updatedJson.headerJson.id = this.state.getCreateJson[item]
                    } else if (item.toLowerCase().indexOf('name') !== -1) {
                        updatedJson.realHeaderJson[item] = this.state.getCreateJson[item]
                        updatedJson.headerJson.name = this.state.getCreateJson[item]
                    } else {
                        updatedJson.bodyJson[item] = this.state.getCreateJson[item]
                    }
                }
            }
    
            billingContent = (
                <div>
                    <Row>
                        <Col xs={6}>
                            <EditableCard dropdownOptions={dropdownOptions} editMode={true} create={!this.props.location.state} realHeaderJson={updatedJson.realHeaderJson} {...this.props} headerJson={updatedJson.headerJson} bodyJson={updatedJson.bodyJson} setJson={updatedJson.setJson} activeJson={updatedJson.activeJson} cardLabel={this.props.page} category="billing" />
                        </Col>
                    </Row>
                </div>
            )
        }

        return ( 
            <Container fluid className="billing-content py-4 my-3">
                {billingContent}
            </Container>
        );
    }
}

const BillingWithLocation = withRouter(Billing)

const mapStateToProps = (state) => {
    return {
        billing: state.billing.billingJson,
        binList: state.billing.binListCreate,
        binListFilters: state.billing.binListFilters,
        updatingFilters: state.billing.binListFilters[1].options,
        error: state.billing.error,
        success: state.adidManager.successAlert
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitGetBinListJson: (query) => dispatch(actionTypes.initGetBinListJson(query))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(BillingWithLocation);