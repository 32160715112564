import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as actionTypes from '../../Store/Actions/ContentManager';

import {Container, Row, Col} from 'react-bootstrap';
import EditableCard from '../../Components/Cards/EditableCard/EditableCard';

class ContentManagerCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getCreateContentManagerJson: this.props.contentHeadingCreate
        };
    }

    componentDidMount() {
        // Do this check to help with validation for the name if the array of objects for the contentManager store variable is null
        if (this.props.contentManager === null) {
            this.props.onInitGetContentManagerJson(this.props.endpoint);
        }
    }

    render() { 
        let updatedJson = {
            headerJson: {},
            realHeaderJson: {},
            bodyJson: {},
            requiredFields: []
        }
        
        for (const item in this.state.getCreateContentManagerJson) {
            if (item.toLowerCase().indexOf('id') !== -1) {
                updatedJson.realHeaderJson[item] = this.state.getCreateContentManagerJson[item]
                updatedJson.headerJson.id = this.state.getCreateContentManagerJson[item]
            } else if (item.toLowerCase().indexOf('name') !== -1) {
                updatedJson.realHeaderJson[item] = this.state.getCreateContentManagerJson[item]
                updatedJson.headerJson.name = this.state.getCreateContentManagerJson[item]
                updatedJson.headerJson.required = true;
            } 
            // If the item is an object then it has a value and required key/value pair. This uses those values to set the value in the bodyJson and let the field component that uses this value that this field is required to be filled out
            else if (typeof this.state.getCreateContentManagerJson[item] === "object") {
                updatedJson.bodyJson[item] = this.state.getCreateContentManagerJson[item].value
                updatedJson.requiredFields.push(item)
            } else {
                updatedJson.bodyJson[item] = this.state.getCreateContentManagerJson[item]
            }
        }

        let contentManagerCreateContent = (
            <Col md={6}>
                <EditableCard create={true} realHeaderJson={updatedJson.realHeaderJson} {...this.props} headerJson={updatedJson.headerJson} bodyJson={updatedJson.bodyJson} cardLabel={this.props.page} category="contentManager" requiredFields={updatedJson.requiredFields} />
            </Col>
        )
        
        return ( 
            <Container fluid className="contentHeading-content py-4 my-3">
                <Row className="mb-4 pb-3">
                    {contentManagerCreateContent}
                </Row>
            </Container>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        contentManager: state.contentManager.contentManagerJson,
        contentHeadingCreate: state.contentManager.contentHeadingCreate,
        errorCreate: state.contentManager.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitGetContentManagerJson: (query) => dispatch(actionTypes.initGetContentManagerJson(query)),
        onInitUpdateCreateContentManagerJson: (query) => dispatch(actionTypes.initUpdateCreateContentManagerJson(query))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ContentManagerCreate);