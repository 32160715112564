import React from 'react';

import {Form} from 'react-bootstrap';

import SearchIcon from '../../../Images/img-search.svg';

import './BasicSearch.scss';

const BasicSearch = (props) => {
    return ( 
        <Form.Group className="w-100 mr-5 mb-0 search-group position-relative">
            <Form.Control className="border mr-auto search-input" placeholder={props.placeholder} onKeyUp={(event) => props.searchHandler(event)} />
            <span className="search-icon position-absolute">
                <a href="#" onClick={(event) => props.searchHandler(event)}>
                    <img src={SearchIcon} alt="search icon" />
                </a>
            </span>
        </Form.Group>
    );
}
 
export default BasicSearch;