import React, { useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import * as actionTypes from '../../Store/Actions/Billing';

import {Container, Form, Button, Dropdown, Card} from 'react-bootstrap';
import CreateButton from '../../Components/Buttons/CreateButton/CreateButton';

import './Billing.scss';

const BinListSearch = (props) => {
    const { btnText } = props;
    // state to validate input in search 
    const [validated, setValidated] = useState(true);
    // state that saves the search input value
    const [value, setValue] = useState(null);
    // state that saves the type selected
    const [type, setType] = useState(null);
    //state that saves the brand selected
    const [brand, setBrand] = useState(null);
    const onInput = ({target:{value}}) => setValue(value);

    // redux variable to access all brand Ids
    const allBrands = useSelector(state => state.billing.brandIds);

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (allBrands === null) {
            dispatch(actionTypes.initGetBrandIds());
        }
    }, [allBrands, dispatch])

    const validateBinNumber = (bin) => {
        return bin && bin.length === 6 && bin%1 === 0;
    }

    function getKeyByValue(object, value) {
        const key = Object.keys(object).find(key => object[key] === value).toString();
        if (key.length < 2) return `0${key}`;
        return key;
      }

    const searchByIdHandler = (event) => {
        event.preventDefault();
        if (validateBinNumber(value)) {
            setValidated(true)
            dispatch(actionTypes.getBinListJsonByBinNumber(value));
            history.push('/bin-list-manager-results');
        } else {
            setValidated(false)
        }
    }
    const searchByFiltersHandler = (event) => {
        event.preventDefault();
        if (type !== null && brand !== null) {
            dispatch(actionTypes.getBinListJsonByBrandIdandBinType(getKeyByValue(allBrands, brand), type))
            history.push('/bin-list-manager-results');
        }
    }

    const types = ["casB", "casP", "casD", "blacklist"];

    return (
        <Container className="mt-4">
            <CreateButton {...props} buttonText={btnText} />
            <Card className="mt-4 w-100 mx-auto shadow rounded border-0 ">
                <div className="mx-5 my-5">
                    <p className="h6 text-center mb-3">Search by Bin Number</p>
                    <Form className="w-100 search-input d-flex">
                        <div className="mx-auto d-flex">
                            <div className="bin-search">
                                <Form.Control isInvalid={!validated} required onChange={onInput} className="border mr-3" type="text" placeholder="Search for bin..." />
                                <Form.Control.Feedback type="invalid">
                                    Bin Number must be a 6 digit number.
                                </Form.Control.Feedback>
                            </div>
                            <Button className="ml-3" type="submit" onClick={searchByIdHandler}>Search</Button>
                        </div>
                    </Form>
                </div>
                <p className="h6 mx-5 text-center my-0">OR</p>
                <div className="mx-5 my-5">
                <p className="h6 text-center mb-3">Search by Bin Type and Brand</p>
                    <div className="d-flex">
                        <div className="mx-auto d-flex">
                            <Dropdown  className="mr-3 filter-style-dropdown">
                                <Dropdown.Toggle className={`bg-white border text-reset text-uppercase position-relative font-weight-bold rounded search-page`}>
                                <span className="mr-4 selected-text">{type !== null ? type : "Type"}</span><i className="fas fa-chevron-down position-absolute text-reset"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={`border-0`}>
                                    {types.map((type) => <Dropdown.Item key={type} onSelect={() => setType(`${type}`)}>{type}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown  className="mr-3 filter-style-dropdown">
                                <Dropdown.Toggle className={`bg-white border text-reset text-uppercase position-relative font-weight-bold rounded search-page`}>
                                <span className="mr-4 selected-text">{brand !== null ? brand : "Brand"}</span><i className="fas fa-chevron-down position-absolute text-reset"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={`border-0`}>
                                    {allBrands && Object.keys(allBrands).map((key) => <Dropdown.Item key={key} onSelect={() => setBrand(`${allBrands[key]}`)}>{allBrands[key]}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Button onClick={searchByFiltersHandler}>Search</Button>
                        </div>
                    </div>
                </div>
            </Card>
        </Container>
    )
}

export default BinListSearch;