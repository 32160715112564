import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import moment from 'moment';

import * as actionTypes from '../../Store/Actions/Billing';

import {Container, Row, Col, Spinner, Alert, Form, Button} from 'react-bootstrap';
import EditableCard from '../../Components/Cards/EditableCard/EditableCard';
import CreateButton from '../../Components/Buttons/CreateButton/CreateButton';
import Filters from '../../Components/Filters/Filters';
import SortBy from '../../Components/SortBy/SortyBy';
import MovePageButtons from '../../Components/Buttons/MovePageButtons/MovePageButtons'
import CascadeByPassByID from './CascadeByPassByID';

import './Billing.scss';

class Billing extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef()
        
        this.state = {
            getAllJson: null,
            filters: null,
            cascadeHistory: null,
            sortState: null,
            pageNum: parseInt(this.props?.location?.search?.split('=')[1]) || 1
        };
    }


    componentDidMount() {
        this.handleScroll()

        // Trigger the get function that makes the API call to get the json needed for this page
        if (this.props.page === "cascade bypass" && !this.state.getAllJson) {
            this.props.onInitGetCascadeBypassJson();
        }
    }

    componentDidUpdate(prevProps) {
        this.handleScroll()
        // If the billing property has updated set the "getAllJson" state variable to the updated billing property variable
        if (this.props.billing !== prevProps.billing) {
            if (this.props.page === "bin profile") {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        getAllJson: this.props.billing.slice(this.state.pageNum*50-50, this.state.pageNum*50),
                        filters: this.props.binListFilters,
                        cascadeHistory: this.props.cascadeBypassHistory
                    }
                });
            } else if (this.props.page === "cascade bypass") {
                let json;
                if (this.props.location?.state?.defaultSearch) {
                    json = this.defaultSearchHandler()
                } else {
                    json = this.props.billing.slice(this.state.pageNum*50-50, this.state.pageNum*50)
                }
                this.setState((prevState) => {
                            return {
                                ...prevState,
                                getAllJson: json,
                                filters: this.props.cascadeBypassFilters,
                                cascadeHistory: this.props.cascadeBypassHistory
                            }
                        });
                    }
                }
    }

    handleScroll = () => {
        this.scrollRef.current.scrollIntoView()
      }

    // This function searches to see if the Editable Cards contain text matching the value being entered into the search input field
    searchHandler = (event) => {
        let tempArray = []
        this.props.billing.forEach((el => {
            let searchKey = event.target.value.toLowerCase()
            if (el?.partnerId?.toString().toLowerCase().includes(searchKey)
                || el?.superPartnerName?.toString().toLowerCase().includes(searchKey)
                || el?.verticalType?.toString().toLowerCase().includes(searchKey)){
                    tempArray.push(el)
            }
        })) 
        this.setState((prevState) => {
            return {
                ...prevState,
                getAllJson: tempArray.slice(0, 100),
            }
        });   
    }

    defaultSearchHandler = () => {
        if (this.props.location?.state?.defaultSearch) {
            let tempArray = []
            this.props.billing?.forEach((el => {
                let searchKey = this.props.location?.state?.defaultSearch
                if (el?.partnerId?.toString().toLowerCase().includes(searchKey)) tempArray.push(el);
            })) 
            return tempArray 
        }
    }

    timeHandler(itemTime) {
        // Variables to output specific hours, minutes, and seconds for time conversion
        let hours = Number(itemTime[0]);
        let minutes = Number(itemTime[1]);
        let seconds = Number(itemTime[2]);

        let convertedTime;

        // If coniditions to get the correct hours value when converting from military time
        if (hours > 0 && hours <= 12) {
            convertedTime = "" + hours
        } else if (hours > 12) {
            convertedTime = "" + (hours - 12)
        } else if (hours === 0) {
            convertedTime = "12"
        }

        // Set the main convertedTime variable to use the converted standard time
        convertedTime += (minutes < 10) ? ":0" + minutes : ":" + minutes;  // get minutes
        convertedTime += (seconds < 10) ? ":0" + seconds : ":" + seconds;  // get seconds
        convertedTime += (hours >= 12) ? " P.M." : " A.M.";  // get AM/PM

        return convertedTime;
    }

    render() { 
        let billingContent;

        const movePage = (action) => {
            let newPage;
            if (action === 'next') {
                newPage = this.state.pageNum + 1;
            } else {
                newPage = this.state.pageNum - 1;
            }
            this.setState((prevState) => {
                return {
                    ...prevState,
                    getAllJson: this.props.billing?.slice(newPage*50-50, newPage*50),
                    pageNum: newPage
                }
            });
        }

        if (this.state.getAllJson === null && !this.props.error) {
            // If the "getAllJson" state variable is null and there are no errors, then show a spinner
            billingContent = (
                <Row className="mt-5 pt-5">
                    <Col className="text-center">
                        <Spinner size="lg" animation="border" variant="primary" className="m-auto" />
                    </Col>
                </Row>
            );
        } else if(this.props.error) {
            // If there are errors, then show the error alert message
            billingContent = (
                <Row className="mt-5 pt-5 justify-content-center">
                    <Col>
                        <Alert variant="danger" className="text-center">
                            There was an issue getting the content for this page! Please refresh the page to try again!
                        </Alert>
                    </Col>
                </Row>
            )
        } else {
            // If the "getAllJson" state variable has content and is NOT null
            let mainJson = this.state.getAllJson;

            let content = mainJson.map((key,index) => {
                let updatedJson = {
                    headerJson: {},
                    realHeaderJson: {},
                    bodyJson: {},
                    setJson: {},
                    activeJson: {}
                }
                let dropdownOptions;

                if (this.props.page === "bin profile") {
                    // If the page is "bin profile" execute this specific code that is used for the item set component
                    updatedJson.setJson["bin"] = [];
                    updatedJson.setJson["bin type"] = [];
                    updatedJson.setJson["brand"] = [];
                    updatedJson.setJson["bin description"] = [];

                    for (const item in key) {
                        if (item.toLowerCase().indexOf('bintype') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["bin type"].push({id: key[item],realBinTypeId: item})
                            updatedJson.setJson["bin type"].push({name: key[item],realBinTypeName: item})
                        } else if (item.toLowerCase().indexOf('reasonbin') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["bin description"].push({id: key[item],realBinDescriptionId: item})
                            updatedJson.setJson["bin description"].push({name: key[item],realBinDescriptionName: item})
                        } else if (item.toLowerCase().indexOf('brandacronym') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["brand"].push({id: key[item],realBrandId: item})
                            updatedJson.setJson["brand"].push({name: key[item],realBrandIDName: item})
                        } else if (item.toLowerCase().indexOf('bin') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["bin"].push({id: key[item],realBinId: item})
                            updatedJson.setJson["bin"].push({name: key[item],realBinName: item})
                        } else if (item.toLowerCase().indexOf('active') !== -1) {
                            updatedJson.bodyJson[item] = key[item];
                            updatedJson.activeJson.name = "bin status";
                            updatedJson.activeJson.status = key[item]
                            updatedJson.activeJson.objKey = item
                        } else {
                            updatedJson.bodyJson[item] = key[item];
                        }
                    }
                } else if (this.props.page === "cascade bypass") {
                    updatedJson.setJson["bin type"] = [];
                    updatedJson.setJson["status"] = [];
                    updatedJson.setJson["adid"] = [];
                    updatedJson.setJson["vertical type"] = [];
                    updatedJson.setJson["partner id"] = [];
                    updatedJson.setJson["brand"] = [];
                    updatedJson.setJson["super partner"] = [];
                    updatedJson.setJson["value"] = [];
                    updatedJson.setJson["last modified"] = [];
                    updatedJson.setJson["last modifying user"] = [];

                    for (const item in key) {
                        if (item.toLowerCase() === 'name') {
                            let newName = key[item].split('.')[2];
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["bin type"].push({id: newName,realBinTypeId: newName})
                            updatedJson.setJson["bin type"].push({name: newName,realBinTypeName: newName})
                        } else if (item.toLowerCase().indexOf('active') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            let itemValue = key[item] === true ? "active" : "inactive"
                            updatedJson.setJson["status"].push({id: itemValue,realBinTypeId: item})
                            updatedJson.setJson["status"].push({name: itemValue,realBinTypeName: item})
                        } else if (item.toLowerCase().indexOf('adid') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["adid"].push({id: key[item],realBinTypeId: item})
                            updatedJson.setJson["adid"].push({name: key[item],realBinTypeName: item})
                        } else if (item.toLowerCase().indexOf('verticaltype') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["vertical type"].push({id: key[item],realBinTypeId: item})
                            updatedJson.setJson["vertical type"].push({name: key[item],realBinTypeName: item})
                        } else if (item.toLowerCase() === 'partnerid') {
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["partner id"].push({id: key[item],realBinTypeId: item})
                            updatedJson.setJson["partner id"].push({name: key[item],realBinTypeName: item})
                        } else if (item.toLowerCase().indexOf('brandacronym') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["brand"].push({id: key[item],realBinTypeId: item})
                            updatedJson.setJson["brand"].push({name: key[item],realBinTypeName: item})
                        } else if (item.toLowerCase().indexOf('superpartnername') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["super partner"].push({id: key[item],realBinTypeId: item})
                            updatedJson.setJson["super partner"].push({name: key[item],realBinTypeName: item})
                        } else if (item.toLowerCase().indexOf('value') !== -1) {
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["value"].push({id: key[item],realBinTypeId: item})
                            updatedJson.setJson["value"].push({name: key[item],realBinTypeName: item})
                        } else if (item.toLowerCase().indexOf('lastmodified') !== -1) {
                            // Variables to only get the time and date
                            let itemTime = key[item].split('T')[1].split('.')[0].split(':');
                            let itemDate = key[item].split('T')[0];

                            let convertedTime = this.timeHandler(itemTime);

                            // Sets newLastModified to the converted standard time and date
                            let newLastModified = `${moment(itemDate).format("MMM D, YYYY")} ${convertedTime}`;

                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["last modified"].push({id: newLastModified,realBinTypeId: newLastModified})
                            updatedJson.setJson["last modified"].push({name: newLastModified,realBinTypeName: newLastModified})
                        } else if (item.toLowerCase().indexOf('lastmodifyinguser') !== -1) {
                            let newLastModifyingUser = key[item].split('@')[0];
                            updatedJson.bodyJson[item] = key[item]
                            updatedJson.setJson["last modifying user"].push({id: newLastModifyingUser,realBinTypeId: newLastModifyingUser})
                            updatedJson.setJson["last modifying user"].push({name: newLastModifyingUser,realBinTypeName: newLastModifyingUser})
                        } else if (item.toLowerCase() === "id") {
                            updatedJson.bodyJson[item] = key[item];

                            let casHistory = this.state.cascadeHistory[key[item]];

                            // Map to update history array to add key/value pairs that match headers used in the table component
                            casHistory.map((key, index) => {
                                key.status = key.active === true ? "active" : "inactive";
                                key.brand = key.brandAcronym;
                                key.binType = key.name.split('.')[2];
                                key.adid = key.adId;

                                // Variables to only get the time and date
                                let itTime = key.lastModified.split('T')[1].split('.')[0].split(':');
                                let itDate = key.lastModified.split('T')[0];

                                let convertTime = this.timeHandler(itTime);
                                let newTime = moment(itDate).format("MMM D, YYYY");

                                // Sets newLastModified to the converted standard time and date
                                let newModified = `${newTime} ${convertTime}`;

                                key["last modified"] = newModified;
                                key.lastModifyingUser = key.lastModifyingUser.split('@')[0];
                                key.superPartner = key.superPartnerName;

                                return key;
                            })

                            updatedJson.cascadeHistory = casHistory;
                        } else {
                            updatedJson.bodyJson[item] = key[item];
                        }
                    }
                } else {
                    for (const item in key) {
                        if (item.toLowerCase().indexOf('id') !== -1) {
                            updatedJson.realHeaderJson[item] = key[item]
                            updatedJson.headerJson.id = key[item]
                        } else if (item.toLowerCase().indexOf('name') !== -1) {
                            updatedJson.realHeaderJson[item] = key[item]
                            updatedJson.headerJson.name = key[item]
                        } else {
                            updatedJson.bodyJson[item] = key[item]
                        }
                    }
                }

                return (
                    <Col xs={12} xl={this.props.page === "bin profile" ? 10 : ""} key={index}>
                        <EditableCard id={updatedJson.bodyJson.id} dropdownOptions={dropdownOptions} realHeaderJson={updatedJson.realHeaderJson} {...this.props} headerJson={updatedJson.headerJson} bodyJson={updatedJson.bodyJson} setJson={updatedJson.setJson} activeJson={updatedJson.activeJson} cardLabel={this.props.page} category="billing" collapseIndex={index} collapseBtn={`collapse-button-${index}`} tableHeaders={this.props.cascadeBypassTableHeaders} collapseContent={updatedJson.cascadeHistory} />
                    </Col>
                )
            })

            if (this.props.page === "bin profile") {
                // Sorts the Editable Cards in order of the bin number
                content.sort((a, b) => {
                    let textA = a.props.children.props.bodyJson.bin;
                    let textB = b.props.children.props.bodyJson.bin;
    
                    return (textA - textB);
                })
            } else if (this.props.page === "cascade bypass") {
                // Sorts the Editable Cards in order of the last modified date and time
                if (this.state.sortState === null || this.state.sortState === "last modified") {
                    content.sort((a, b) => {
                        let aDateSplit = a.props.children.props.setJson["last modified"][0].id.replace(",", "").replace(/\./g, "");
                        let bDateSplit = b.props.children.props.setJson["last modified"][0].id.replace(",", "").replace(/\./g, "");
    
                        return new Date(bDateSplit) - new Date(aDateSplit);
                    })
                } else if (this.state.sortState === "bin type") {
                    // Sorts the Editable Cards in order of the bin type
                    content.sort((a, b) => {
                        let binTypeA = a.props.children.props.setJson["bin type"][0].id;
                        let binTypeB = b.props.children.props.setJson["bin type"][0].id;

                        if (binTypeA > binTypeB) {
                            return -1
                        } else {
                            return 1
                        }
                    })
                } else if (this.state.sortState === "status") {
                    // Sorts the Editable Cards in order of the status
                    content.sort((a, b) => {
                        let statusA = a.props.children.props.setJson["status"][0].id;
                        let statusB = b.props.children.props.setJson["status"][0].id;

                        if (statusB > statusA) {
                            return -1
                        } else {
                            return 1
                        }
                    })
                } else if (this.state.sortState === "partner ID") {
                    // Sorts the Editable Cards in order of the partner id
                    content.sort((a, b) => {
                        let partnerA = a.props.children.props.setJson["partner id"][0].id;
                        let partnerB = b.props.children.props.setJson["partner id"][0].id;

                        return partnerA - partnerB;
                    })
                }
            }

            // Sets the sortState to inform the page what order to sort the Editable Cards in
            let sortByHandler = (selected) => {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        sortState: selected
                    }
                })
            }

            billingContent = (
                <div>
                    {this.props.page === "bin profile" || this.props.page === "cascade bypass" ? 
                    <Row className="mb-3 pb-3">
                        <Col className={`d-flex flex-row ${this.props.page === "cascade bypass" ? "flex-wrap align-items-end" : ""} top-content`}>
                            {this.props.page === "cascade bypass" ? <CreateButton {...this.props} content={this.state.getAllJson} buttonText={"Bin Bypass"} /> 
                            : <CreateButton {...this.props} content={this.state.getAllJson} buttonText={this.props.btnText} />}
                            {/* {this.props.page === "cascade bypass" ? <SortBy sortOptions={this.props.cascadeBypassSortBy} sortHandler={sortByHandler} /> : ""} */}
                            <Form className={`${this.props.page === "cascade bypass" ? "mx-4" : "mx-5"} px-1 w-auto flex-fill search-input`}>
                                <Form.Control className="border" type="text" defaultValue={this.props.location?.state?.defaultSearch ? this.props.location?.state?.defaultSearch : ''} placeholder={this.props.page === "cascade bypass" ? "Search by partner id, super partner, or vertical" : "Search for bin..."} onKeyUp={this.searchHandler} />
                            </Form>
                            {this.props.page === "bin profile" && <Filters filters={this.state.filters} filterName={this.props.filterName} filterHandler={this.filterHandler} />}
                        </Col>
                    </Row> : ""}
                    <MovePageButtons pageNum={this.state.pageNum} billing={this.props.billing} movePage={movePage} />
                    <Row className="justify-content-center">
                        {content}
                    </Row>
                    <MovePageButtons pageNum={this.state.pageNum} billing={this.props.billing} movePage={movePage} />
                </div>
            )
        }

        return ( 
            <Container ref={this.scrollRef} fluid className={`billing-content py-4 my-3 ${this.props.page.replace(/\s+/g, '-')}`}>
                {this.props.page === "cascade bypass" ? <div className='mb-5'><CascadeByPassByID /></div> : null}
                {billingContent}
            </Container>
        );
    }
}

const BillingWithLocation = withRouter(Billing)


const mapStateToProps = (state) => {
    return {
        billing: state.billing.billingJson,
        binListFilters: state.billing.binListFilters,
        cascadeBypassTableHeaders: state.billing.cascadeBypassTableHeaders,
        cascadeBypassHistory: state.billing.cascadeBypassHistory,
        cascadeBypassFilters: state.billing.cascadeBypassFilters,
        cascadeBypassSortBy: state.billing.cascadeBypassSortBy,
        error: state.billing.error
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onInitGetCascadeBypassJson: () => dispatch(actionTypes.initGetCascadeBypassJson())
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(BillingWithLocation);