import React from 'react';

import {Dropdown} from 'react-bootstrap';

const AdidManagerDropdownFilter = (props) => {
    let dropdownText = typeof props.normalKey === "object" ? `${props.keyTwo[Object.keys(props.keyTwo)[0]]}: ${props.keyTwo[Object.keys(props.keyTwo)[1]]}` : props.normalKey;
    var filterMatch = false;

    if (props.selectedFilter !== undefined && props.selectedFilter.length) {
        for (let i = 0; i < props.selectedFilter.length; i++) {
            if (props.selectedFilter[i] === "frto") {
                if (props.keyTwo.adidManagemnt !== undefined && props.keyTwo.adidManagemnt === 1 && (dropdownText.toLowerCase().indexOf("fl") !== -1 || dropdownText.toLowerCase().indexOf("rto") !== -1 || dropdownText.toLowerCase().indexOf("foreclosure") !== -1)) {
                    filterMatch = true;
                }
            } else if (props.selectedFilter[i] === "fl") {
                if (props.keyTwo.adidManagemnt !== undefined && props.keyTwo.adidManagemnt === 1 && (dropdownText.toLowerCase().indexOf(props.selectedFilter[i]) !== -1 || dropdownText.toLowerCase().indexOf("foreclosure") !== -1) && dropdownText.toLowerCase().indexOf("rto") === -1) {
                    filterMatch = true;
                }
            } else {
                if ((props.keyTwo.adidManagemnt !== undefined && (props.keyTwo.adidManagemnt === 1 && dropdownText.toLowerCase().indexOf(props.selectedFilter[i]) !== -1)) || (props.keyTwo.adidManagemnt !== undefined && props.keyTwo.adidManagemnt == 0 && props.selectedFilter[i] === "not recommended")) {
                    filterMatch = true;
                } 
            }
        }
    }
    
    let item = <Dropdown.Item itemText={dropdownText} className={`${props.keyTwo.adidManagemnt !== undefined && !filterMatch ? 'd-none' : 'd-flex'}`} onSelect={props.selectHandler}><span className="option-text text-truncate d-inline-block">{dropdownText}</span><span className="d-none option-id">{props.keyTwo.name}</span></Dropdown.Item>
    return ( 
        <>
            {item}
        </>
    );
}
 
export default AdidManagerDropdownFilter;