import * as actionTypes from "./ActionTypes";
import axios from "axios";

axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${process.env.REACT_APP_ADID_MANAGER_BEARER_TOKEN}`;

// Get the Top AdIDs Json
export const initGetTopAdsJson = (numberDisplay, loading) => {
  return (dispatch) => {
    if (loading) {
      dispatch(setLoading(true));
    }
    axios
      .get(
        `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/ads/topadids?maxAdidCount=${numberDisplay}`
      )
      .then((resp) => {
        dispatch(setAdidJson(resp.data));
        dispatch(setTopAdidAmount(numberDisplay));
      })
      .catch((err) => {
        dispatch(getAdsError());
      });
  };
};

// Set the Adids to be displayed whether its Top AdIDs or search result AdIDs
export const setAdidJson = (json) => {
  return {
    type: actionTypes.SET_ADIDS_JSON,
    adidsResults: json,
  };
};

// Sets the amount of Top AdIDs to display
export const setTopAdidAmount = (num) => {
  return {
    type: actionTypes.SET_TOP_ADS_AMOUNT,
    topAdsAmount: num,
  };
};

// If there is an error set the error state variable to true
export const getAdsError = (message) => {
  return {
    type: actionTypes.GET_ADS_ERROR,
    error: true,
    errorMessage: message,
  };
};

// If there is an error set the error state variable to true
export const getAdsErrorReset = () => {
  return {
    type: actionTypes.GET_ADS_ERROR_RESET,
    error: false,
    errorMessage: null,
  };
};

// Set whether the UI/UX should be in a loading state
export const setLoading = (loadingValue) => {
  return {
    type: actionTypes.SET_ADS_LOADING,
    loading: loadingValue,
  };
};

// Get AdID results based on what the user searched
export const initGetSearchAdsJson = (type, searchText) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setSearchText(searchText));
    if (type === "AdID") {
      axios
        .get(
          `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/ads/search/${searchText}`
        )
        .then((resp) => {
          dispatch(setAdidJson(resp.data));
        })
        .catch((err) => {
          dispatch(getAdsError());
        });
    } else if (type === "AdID Description") {
      axios
        .get(
          `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/ads/search?adDescription=${searchText}`
        )
        .then((resp) => {
          dispatch(setAdidJson(resp.data));
        })
        .catch((err) => {
          dispatch(getAdsError());
        });
    } else if (type === "Super Partner") {
      axios
        .get(
          `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/superPartner/${searchText}`
        )
        .then((resp) => {
          dispatch(setAdidJson(resp.data));
        })
        .catch((err) => {
          dispatch(getAdsError());
        });
    } else if (type === "Partner") {
      axios
        .get(
          `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/partner/${searchText}`
        )
        .then((resp) => {
          dispatch(setAdidJson(resp.data));
        })
        .catch((err) => {
          dispatch(getAdsError());
        });
    }
  };
};

// Save the state of the text the user entered into the search box
export const setSearchText = (searchText) => {
  return {
    type: actionTypes.SET_SEARCH_TEXT,
    searchText: searchText,
  };
};

// Get all of the details needed for the dropdowns in the view adid page
export const initGetFullAdDetails = () => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/ads/alladsinfo`)
      .then((resp) => {
        dispatch(setFullAdDetails(resp.data));
      })
      .catch((err) => {
        dispatch(getAdsError());
      });
  };
};

// Set all of the details needed for the dropdowns in the view adid page
export const setFullAdDetails = (json) => {
  return {
    type: actionTypes.GET_FULL_AD_DETAILS_JSON,
    options: json,
  };
};

// Set what state the create container should be in
export const setCreateState = (type, category) => {
  return {
    type: actionTypes.SET_AD_MANAGER_CREATE_STATE,
    createType: type,
    createCategory: category,
  };
};

// Set what AdID is selected when the user clons/views one
export const setSelectedAdid = (adidSelected) => {
  return {
    type: actionTypes.SET_SELECTED_ADID,
    selectedAdid: adidSelected,
  };
};

// Set what state the create/clone container is in and pass in the necessary data for the endpoint
export const setCreateCloneEndpointState = (type, category, json) => {
  return (dispatch) => {
    if (type === "clone" && category === "adid") {
      dispatch(
        initCloneAdid(
          `${json.jiraReference.trim()} ${json.adDescription.trim()}`,
          json.adid,
          json.newAdid,
          json.newLastModifyingUser
        )
      );
    } else if (type === "create" && category === "super partner") {
      dispatch(createSuperPartner(json));
    } else if (type === "clone" && category === "partner") {
      dispatch(initClonePartner(json));
    }
  };
};

// Endpoint for cloning an AdID
export const initCloneAdid = (
  adDescription,
  adidToClone,
  adid,
  newLastModifyingUser
) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/ads/clone?adDescription=${adDescription}&adIdToClone=${adidToClone}&adid=${adid}&newLastModifyingUser=${newLastModifyingUser}`
      )
      .then((resp) => {
        setTimeout(() => {
          dispatch(setSuccessAlert("Successfully cloned AdID"));
        }, 300);
        setTimeout(() => {
          dispatch(setSuccessAlert(null));
        }, 3000);
        dispatch(setEditState("adid"));
        dispatch(setEndpointResponse("success"));
        dispatch(setAdidDetails(resp.data));
        dispatch(initViewAdidUCTDetails(adid));
      })
      .catch((err) => {
        dispatch(setEndpointResponse("fail"));
        dispatch(
          getAdsError(
            "The new AdID you are trying to create already exists. Please trying again with new AdID."
          )
        );
      });
  };
};

// If a create endpoint returns an error response
export const setEndpointResponse = (resp) => {
  return {
    type: actionTypes.SET_ENDPOINT_RESPONSE,
    endpointResponse: resp,
  };
};

// Endpoint to get individual AdID details
export const initViewAdidDetails = (adid, type) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/ads/${adid}`)
      .then((resp) => {
        dispatch(setEditState(type));
        dispatch(setAdidDetails(resp.data));
      })
      .catch((err) => {
        dispatch(getAdsError());
      });
  };
};

// Sets the individual AdID details
export const setAdidDetails = (json) => {
  return {
    type: actionTypes.SET_AD_DETAILS_JSON,
    adidDetails: json,
  };
};

// Sets the current adid's brand url
export const setBrandUrl = (json) => {
  return {
    type: actionTypes.SET_BRAND_URL,
    brandUrl: json,
  };
};

// Sets the success message for editing/cloning/creating within the adid manager
export const setSuccessAlert = (json) => {
  return {
    type: actionTypes.SET_SUCCESS_ALERT,
    successAlert: json,
  };
};

// Sets what state the edit container should be in
export const setEditState = (type) => {
  return {
    type: actionTypes.SET_AD_MANAGER_EDIT_STATE,
    editType: type,
  };
};

// Endpoint to get the UCT entries used for the AdID the user selected to view the details for
export const initViewAdidUCTDetails = (adid) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/uct/${adid}`)
      .then((resp) => {
        dispatch(setUctAdidDetails(resp.data));
      })
      .catch((err) => {
        dispatch(getAdsError());
      });
  };
};

// Set the UCT details state
export const setUctAdidDetails = (json) => {
  return {
    type: actionTypes.SET_UCT_AD_DETAILS_JSON,
    viewAdidUCTDetails: json,
  };
};

// Set the vertical type to know which filter should be on
export const setVerticalType = (type) => {
  return {
    type: actionTypes.SET_VERTICAL_TYPE,
    verticalType: type,
  };
};

// Save the changes to the AdID Details
export const saveAdidDetails = (json, urlParams) => {
  /**
   * http://adid-management-service-dev.us-east-1.elasticbeanstalk.com/partner/clone?partnerIdToClone=****&partnerId=****&partnerName=****&newLastModifyingUser=****&jira=****&description=****&user=****
   */
  return (dispatch) => {
    dispatch(setLoading(true));
    axios
      .post(`${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/ads?user=${urlParams.user}&jira=${urlParams.jira}&description=${urlParams.description}`, json)
      .then((resp) => {
        dispatch(setSuccessAlert("Successfully saved AdID"));
        setTimeout(() => {
          dispatch(setSuccessAlert(null));
        }, 3000);
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 200);
      })
      .catch((err) => {
        dispatch(getAdsError());
      });
  };
};

// Save the updated UCT value
export const saveUCTDetails = (uctObj) => {
  return (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/uct?id=${uctObj.id}&adId=${uctObj.adId}&newRevisionComment=${uctObj.newRevisionComment}&newLastModifyingUser=${uctObj.newLastModifyingUser}`,
        uctObj.newValue,
        {
          headers: {
            "Content-Type": "application/html",
          },
        }
      )
      .then((resp) => {
        console.log(resp);
      })
      .catch((err) => {
        dispatch(getAdsError());
      });
  };
};

// Create or Edit Super Partner
export const createSuperPartner = (spObj, mode) => {
  return (dispatch) => {
    let newDate = new Date();
    let isoDate = newDate.toISOString();
    let newObj = {};

    newObj.lastModified = isoDate;
    newObj.lastModifyingUser = spObj.newLastModifyingUser;
    newObj.superPartnerId = parseInt(spObj.id);
    newObj.superPartnerName = spObj.name;

    axios
      .put(
        `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/superPartner/create`,
        newObj
      )
      .then((resp) => {
        if (resp.data === "") {
          dispatch(setEndpointResponse("fail"));
          dispatch(
            getAdsError(
              "The new Super Partner ID you are trying to create already exists. Please trying again with new ID."
            )
          );
        } else {
          dispatch(setSuccessAlert("Successfully created Super Partner"));
          setTimeout(() => {
            dispatch(setSuccessAlert(null));
          }, 3000);
          dispatch(setRecentlyCreated("super partner"));
          dispatch(setEndpointResponse("success"));
        }
      })
      .catch((err) => {
        dispatch(setEndpointResponse("fail"));
        dispatch(
          getAdsError(
            "There was an issue creating your Super Partner. Please try again."
          )
        );
      });
  };
};

// Save changes to the Super Partner
export const saveSuperPartner = (spObj, urlParams) => {
  if (!urlParams?.description || !urlParams?.jira) {
    return dispatch => {
        return false
    }
}  
  return (dispatch) => {
    let newDate = new Date();
    let isoDate = newDate.toISOString();
    let newObj = {};

    newObj.lastModified = isoDate;
    newObj = { ...newObj, ...spObj };

    axios
      .post(
        `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/superPartner/update?user=${urlParams.user}&jira=${urlParams.jira}&description=${urlParams.description}`,
        newObj
      )
      .then((resp) => {
        dispatch(setSuccessAlert("Successfully saved Super Partner"));
        setTimeout(() => {
          dispatch(setSuccessAlert(null));
        }, 3000);
        dispatch(setEndpointResponse("success"));
      })
      .catch((err) => {
        dispatch(setEndpointResponse("fail"));
        dispatch(
          getAdsError(
            "There was an issue updating your changes. Please try again."
          )
        );
      });
  };
};

// Get Details of Super Partner
export const initViewSuperPartnerDetails = (id) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/superPartner/fullsuperpartner/${id}`
      )
      .then((resp) => {
        dispatch(setEditState("superpartner"));
        dispatch(setSuperPartnerDetails(resp.data));
      })
      .catch((err) => {
        dispatch(getAdsError());
      });
  };
};

// Set Super Partner Details
export const setSuperPartnerDetails = (json) => {
  return {
    type: actionTypes.SET_SUPER_PARTNER_DETAILS,
    viewSuperPartnerDetails: json,
  };
};

// Clone Partner Endpoint
export const initClonePartner = (json) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/partner/clone?partnerIdToClone=${json.partnerIdToClone}&partnerId=${json.partnerId}&partnerName=${json.partnerName}&newLastModifyingUser=${json.newLastModifyingUser}`
      )
      .then((resp) => {
        setTimeout(() => {
          dispatch(setSuccessAlert("Successfully cloned Partner"));
        }, 300);
        setTimeout(() => {
          dispatch(setSuccessAlert(null));
        }, 3000);
        dispatch(setEndpointResponse("success"));
        dispatch(setEditState("partner"));
        dispatch(setPartnerDetails(resp.data));
      })
      .catch((err) => {
        dispatch(setEndpointResponse("fail"));
        dispatch(
          getAdsError(
            "The new Partner you are trying to create already exists. Please trying again with new Partner ID."
          )
        );
      });
  };
};

// Get Partner Dropdown Details
export const initPartnerDropdownDetails = () => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/partner/fullPartner/1`
      )
      .then((resp) => {
        let responseData = resp.data;
        let options = {};
        let brandArray = [];
        let superPartnerArray = [];

        options["brand"] = responseData.brandList;
        options["vertical"] = responseData.verticalTypeList;
        options["channel"] = responseData.channelTypeList;
        options["partnerType"] = responseData.partnerTypeList;
        options["saleType"] = responseData.saleTypeList;
        options["headerType"] = ["0", "1", "2", "3", "4", "5"];
        options["superPartner"] = responseData.superPartnerList;

        options.brand.map((key, index) => {
          return brandArray.push(`${key.brandId}: ${key.name}`);
        });
        options.brand = brandArray;

        options.superPartner.map((key, index) => {
          return superPartnerArray.push(
            `${key.superPartnerId}: ${key.superPartnerName}`
          );
        });
        options.superPartner = superPartnerArray;

        dispatch(setPartnerDropdownOptions(options));
      });
  };
};

// Set Partner Dropdown Options
export const setPartnerDropdownOptions = (options) => {
  return {
    type: actionTypes.SET__PARTNER_DROPDOWN_OPTIONS,
    partnerDropdownOptions: options,
  };
};

// Create a new partner endpoint
export const initCreatePartner = (json) => {
  return (dispatch) => {
    axios
      .put(
        `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/partner/create`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((resp) => {
        if (resp.data === "") {
          dispatch(
            getAdsError(
              "The new Partner ID you are trying to create already exists. Please trying again with new ID."
            )
          );
        } else {
          dispatch(setSuccessAlert("Successfully created Partner"));
          setTimeout(() => {
            dispatch(setSuccessAlert(null));
          }, 3000);
          dispatch(setEndpointResponse("success"));
          dispatch(setEditState("partner"));
          dispatch(setRecentlyCreated("partner"));
          dispatch(setPartnerDetails(resp.data));
        }
      })
      .catch((err) => {
        dispatch(getAdsError());
      });
  };
};

// Get partner details
export const initViewPartnerDetails = (id, type) => {
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/partner/fullPartner/${id}`
      )
      .then((resp) => {
        dispatch(setEditState(type));
        dispatch(setPartnerDetails(resp.data));
      })
      .catch((err) => {
        dispatch(getAdsError());
      });
  };
};

// Set view partner details
export const setPartnerDetails = (json) => {
  return {
    type: actionTypes.SET_PARTNER_DETAILS,
    viewPartnerDetails: json,
  };
};

// Save changes to the Partner
export const savePartner = (json, urlParams) => {
  if (!urlParams?.description || !urlParams?.jira) {
    return dispatch => {
        return false
    }
}  
return (dispatch) => {
    dispatch(setLoading(true));
    axios
      .post(
        `${process.env.REACT_APP_SALES_SITE_SERVICE_URL}/partner/update?user=${urlParams.user}&jira=${urlParams.jira}&description=${urlParams.description}`,
        json
      )
      .then((resp) => {
        dispatch(setSuccessAlert("Successfully saved Partner"));
        setTimeout(() => {
          dispatch(setSuccessAlert(null));
        }, 3000);
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 200);
      })
      .catch((err) => {
        dispatch(getAdsError());
      });
  };
};

// Set whether a new Partner or Super Partner was created so the endpoint to get the list of partners/super partner can be updated
export const setRecentlyCreated = (val) => {
  return {
    type: actionTypes.SET_RECENTLY_CREATED,
    recentlyCreated: val,
  };
};
