import * as actionTypes from '../Actions/ActionTypes';

// Initial state for logging in
const initialState = {
    loggedIn: false,
    userFirstName: null,
    userLastName: null
}

const reducer = (state = initialState, action) => {
    switch(action.type) {
        // Sets the login state to true and defines the users first and last name
        case actionTypes.USER_LOGIN:
            return {
                ...state,
                loggedIn: true,
                userFirstName: action.userFirstName,
                userLastName: action.userLastName
            }
        // Sets the login state to "show button" if the user logs out
        case actionTypes.USER_LOGOUT:
            return {
                ...state,
                loggedIn: 'show button',
                userFirstName: null,
                userLastName: null
            }
        // Sets the login state to "show button" if the user needs to login
        case actionTypes.USER_NEEDS_TO_LOGIN:
            return {
                ...state,
                loggedIn: 'show button'
            }
        // Sets the login state to true if the user has logged in
        case actionTypes.USER_LOGGED_IN:
            return {
                ...state,
                loggedIn: true
            }
        default: return state;
    }
}

export default reducer;