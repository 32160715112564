import React from 'react';

import './CardConnector.scss';

const CardConnector = () => {
    return ( 
        <div className="card-connector position-absolute">
            <div className="circle bg-primary position-relative"></div>
            <div className="line bg-primary"></div>
            <div className="circle bg-primary position-relative"></div>
        </div>
     );
}
 
export default CardConnector;